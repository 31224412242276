import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'
import { FormText, Title } from 'ui/styled'
import { CompanyButtonContainer } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';
import BodyFeedback from 'components/Mobile/Feedback';
import CustomButton from 'components/Form/CustomButton';

export default function ModalResponse() {

  const { modal, setModal } = useContext(CoreContext)

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    close()
  }

  console.log("CURERNT MODAL", modal)

  return (
    <>
      <Wrapper mobile>
        { !modal?.currentLesson?.response?.answers ? null : <BodyFeedback response={modal?.currentLesson?.response} answers={modal?.currentLesson?.response?.answers} game={modal?.currentLesson?.response?.game} time={modal?.currentLesson?.response?.time} forfun={modal?.currentLesson?.response?.forfun} gameLesson={modal?.currentLesson?.response?.gameLesson} student={modal?.student} /> }
        <CustomButton onClick={handleSave} between>Fechar</CustomButton>
      </Wrapper>
    </>
  )
}
