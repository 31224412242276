import React, { useCallback, useContext, useEffect, useState } from "react";  

import {   
    DashboardTitle, 
    UploadedImage,
 
    ContentStudents,
    RemoveIcon,
    StudentRow,
    StudentStats,
    StudentRate,
    StudentImage,
    DashboardOptionsRow,
    RowStudents,
    CardStudentItem,
    CardQuestionTitle, 
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useController from "./controller";
import Input from "components/Form/Input";
import DashboardCardQuestion from "components/Cards/CardQuestion";
import { CoinImage, DasboardSpacer, RowBetween } from "ui/styled";
import { useDrop } from "react-dnd";
import { CoreContext } from "context/CoreContext";
import { DashboardText } from "../styled";
import UploadFile from "components/Form/UploadFile";
import Button from "components/Form/Button";

import CustomButton from "components/Form/CustomButton";
import { toast } from "react-toastify";
import { Create, Delete, ReadOne, Update } from "services/classes";

import { exposeStrapiError, parseStrapiImage, normalizeStrapiRegister, normalizeStrapiList, performanize, numerize, pickRandom } from "utils";

import { useMemo } from "react";
import RowLesson from "components/Rows/Lesson";
import RowStudent from "components/Rows/Student";
import PolarChart from "components/Dashboard/Charts/Polar";
import { useRef } from "react";

export default function DashboardCreatorClasses(){  

    const { 
        history,

        changeForm,
        formValue, 
        
        params,
        navigate,
        form,
        setForm,

        selectedUser, setSelectedUser,
        selectedQuestion, setSelectedQuestion,

        addStudents,
        addQuestions,
        superSave
    } = useController( () => init() ) 

    const { covers, user, setModal } = useContext(CoreContext)
    
    const [saving, setSaving] = useState(false)
    const [showing, setShowing] = useState(false)
    const [loading, setLoading] = useState(false) 
    const [cover, setCover] = useState(null)
    const [editing, setEditing] = useState(null)
     
    const triggerRef = useRef()
    const [trigger, setTrigger] = useState(0)


    const goodTry = () => {
        if(triggerRef.current) clearTimeout(triggerRef.current);

        if( trigger >= 21 ){
            changeCover(true)
            setTrigger(0)
            return;
        }
        setTrigger(trigger+1)
        triggerRef.current = setTimeout(() => { setTrigger(0) ;}, 1000)
    }
  
    const valid = (verbose) => {
        // console.log("valid", form)
        if(!form?.name?.length){
            if(verbose){ toast.error("Informe o nome da aula") ;}
            return false;
        }  
        
        if(editing){
            if(!selectedQuestion?.length){
                if(verbose){ toast.error("Adicione aulas") ;}
                return false;
            }  
            if(!selectedUser?.length){
                if(verbose){ toast.error("Adicione alunos") ;}
                return false;
            }  
            if(!cover?.id){
                if(verbose){ toast.error("Selecione a imagem de capa da aula") ;}
                return false;
            } 
        }
        return true;
    }

    const save = async () => {
        if(!valid(true)){ return ;}

        const sortedCover = pickRandom(covers?.filter(f => f.type === "classe"))

        const payload = {
            ...form,
            title: form?.name,
            image: cover?.id ?? sortedCover?.image?.id,
            owner: user?.id,
            lessons: selectedQuestion?.map(m => m?.id ),
            students: selectedUser?.map(m => m?.id )
        }

        setSaving(true)
        const result = params?.id ? await Update({ data:payload }, params?.id) : await Create({ data:payload })
        setSaving(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Turma salva")
            setShowing(false)
            navigate(`dashboard/creator/classes/${ result?.data?.id }`); 
        }
    }

    const init = async () => {
        setLoading(true)
            const result = await ReadOne(params?.id)
            const normalResult = normalizeStrapiRegister(result)
            const nextForm = {
                ...normalResult,
                name: normalResult?.title
            }
            
            delete nextForm.id

            setCover(normalResult?.image?.id ? normalResult?.image : null)
            setSelectedQuestion( (normalResult?.lessons||[])?.map(m => ({ ...m, image:!m?.image ? null : normalizeStrapiRegister(m?.image), questions:normalizeStrapiList(m?.questions) })) )
            setSelectedUser( (normalResult?.students)?.map(m => ({ ...m, avatar: normalizeStrapiRegister(m?.avatar) })) )
            console.log("nextForm", normalResult )

            setEditing(normalResult)
            setForm(nextForm)
        setLoading(false)
    }

    const classMedia = useMemo(() => {
        return parseFloat(parseFloat((selectedUser?.reduce( (p,c) => p+(c?.media||0), 0) / selectedUser?.length)||0)?.toFixed(1))
    }, [selectedUser])

    const removeUser = useCallback(async item => {
        const next = [ ...selectedUser?.filter(f => f?.id !== item?.id) ]
        setSelectedUser(next)
        await superSave({ students: next?.map(m => m?.id ) })
        init()
    }, [selectedUser])

    const removeQuestion = useCallback(async item => {
        const next = [ ...selectedQuestion?.filter(f => f?.id !== item?.id) ]
        setSelectedQuestion(next)
        await superSave({ lessons: next?.map(m => m?.id ) })
        init()
    }, [selectedQuestion])

    const changeCover = (secret = false) => {
        setModal({ type:"select-image", selected: cover?.id, mode: secret ? "secret" : "classe", save: async v => {
            await superSave({ image: v?.id })
            init()
        } })
    }

    const handleRemove = () => {
        setModal({ 
            type:"confirm",
            title:"Remover turma",
            text:"Deseja realmente remover a turma? essa ação não pode ser desfeita",
            cancel:"Não remover",
            confirm:"Sim, remover turma",
            onConfirm: () => remove()
        })
    }

    const remove = async () => {
        setLoading(true)
        const result = await Delete(editing?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            history.goBack()
        }
        setLoading(false)
    } 

    useEffect(() => {
        if(params?.id){ init() ;}
    }, [params])

    return ( 
        <>
            <ContainerAuthenticated> 
                <Row>
                    { !editing ? <Col sm={12} md={3} /> : null }
                    <Col sm={12} md={!editing ? 6 : 5}> 
                        {
                            editing ? <>
                                <DashboardTitle>{ editing?.title }</DashboardTitle>
                                <StudentRow>
                                    { cover?.url ? <StudentImage onClick={goodTry} src={parseStrapiImage(cover?.url)} /> : null }
                                    <StudentStats>
                                        Média
                                        <StudentRate>{ classMedia }</StudentRate>
                                    </StudentStats>
                                    <StudentStats>
                                        Performance
                                        <StudentRate>{ performanize(classMedia) }</StudentRate>
                                    </StudentStats>
                                </StudentRow>
        
                                <StudentStats>Turma com { selectedUser?.length } alunos e { selectedQuestion?.length } exercícios</StudentStats>
                                <StudentRow>
                                    <StudentStats>
                                        ⭐️
                                        <RowStudents>
                                            {
                                                selectedUser?.slice(0,7)?.map((item, key) => 
                                                    <CardStudentItem key={key} image={item?.avatar?.url ? parseStrapiImage(item?.avatar?.url) : "/images/student.png"} />
                                                )
                                            }
                                            { selectedUser?.length > 7 ? <CardQuestionTitle> +{selectedUser?.length - 7} </CardQuestionTitle> : null }
                                        </RowStudents>
                                    </StudentStats>
                                    
                                    <StudentStats>
                                        🔖
                                        <RowStudents>
                                            {
                                                selectedQuestion?.slice(0,4)?.map((item, key) => 
                                                    <CardStudentItem key={key} image={item?.image?.url ? parseStrapiImage(item?.image?.url) : ""} />
                                                )
                                            }
                                            { selectedQuestion?.length > 4 ? <CardQuestionTitle> +{selectedQuestion?.length - 4} </CardQuestionTitle> : null }
                                        </RowStudents>    
                                    </StudentStats>
                                </StudentRow>
                                <DashboardOptionsRow>
                                    { showing ? null : <CustomButton onClick={() => setShowing(true)}>Editar</CustomButton> }
                                    <CustomButton loading={loading} onClick={() => changeCover(false)}>Imagem de capa</CustomButton>
                                    <CustomButton onClick={handleRemove}>Remover Turma</CustomButton>
                                </DashboardOptionsRow>
                            </> :
                            <DashboardTitle>Criar Turma</DashboardTitle>
                        }

                        {
                            editing && !showing ? <>
                                <PolarChart students={selectedUser} />
                            </> : <>
                                <DasboardSpacer />
                                <Input placeholder="Nome da turma" value={formValue("name")} onChange={e => changeForm(e.target.value, "name")} />
                                {
                                    !editing ? null : <>
                                        <DasboardSpacer />
                                        <Input type={"textarea"} placeholder="Descrição (Essa informação é privada, não aparece para os alunos)" value={formValue("description")} onChange={e => changeForm(e.target.value, "description")} />
                                    </>
                                }
                                <DasboardSpacer />
                                <CustomButton primary loading={saving} onClick={save}> Salvar </CustomButton>
                            </>
                        }
                    </Col>
                    {
                        !editing ? null : <>
                            <Col sm={12} md={3}>
                                <DashboardTitle>Alunos</DashboardTitle>

                                <CustomButton onClick={addStudents}>Adicionar alunos à turma</CustomButton>
                                <ContentStudents>
                                    {
                                        selectedUser?.map((item, key) => 
                                            <RowStudent key={key} item={item} remove={() => removeUser(item)} />
                                        )
                                    }
                                </ContentStudents>
                            </Col>

                            <Col sm={12} md={4}>
                                <DashboardTitle>Exercícios</DashboardTitle>
                                <CustomButton onClick={addQuestions}>Adicionar exercícios à turma</CustomButton>
                                <ContentStudents>
                                    {
                                        selectedQuestion?.map((item, key) => 
                                            <RowLesson key={key} item={item} remove={() => removeQuestion(item)} />
                                        )
                                    }
                                </ContentStudents>
                            </Col>
                        </>
                    }

                </Row>
            </ContainerAuthenticated> 
        </>
    );
}