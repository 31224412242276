import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeContainer,
    CardHomeDecoration,
    CardHomeText,
    CardHomeTitle,
    
    DashboardTitle,
    IAContainter,
    IAContent,
    IAContentText,
    IAIcon
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Delete, ReadByUser } from "services/questions";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { mockedQuestions } from "utils/options";
import Button from "components/Form/Button";
import { BetweenRow, Load, LoadCenter, MaxButton } from "ui/styled";
import { toast } from "react-toastify";
import CustomButton from "components/Form/CustomButton";

export default function DashboardCreatorTasks(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [questions, setQuestions] = useState([]) 
    const [loading, setLoading] = useState(false) 


    const options = [ 
        { type:"content", title:"Conteúdo", action: () => navigate("dashboard/creator/game/content"), image:"/images/games/g8.jpg", text:"Essa modalidade permite você criar conteudo de texto ou imagem para instruir seus alunos durante os exercícios", powered:true },
        
        { type:"rightwrong", title:"Certo . Errado", action: () => navigate("dashboard/creator/game/rightwrong"), image:"/images/games/g2.jpg", text:"Essa modalidade permite você criar questões de certo ou errado com texto ou imagem para avaliar os alunos com opções booleanas ", powered:true },
        { type:"multichoice", title:"Multipla . Escolha", action: () => navigate("dashboard/creator/game/multichoice"), image:"/images/games/g3.jpg", text:"Essa modalidade permite você criar questões de multipla escolha com texto ou imagem na pergunta e nas respostas para avaliar os alunos", powered:true },
        { type:"association", title:"Associação", action: () => navigate("dashboard/creator/game/association"), image:"/images/games/g4.jpg", text:"Essa modalidade permite você criar questões de associação com texto ou imagem para avaliar a capacidade de associação dos alunos" },

        { type:"memory", title:"Jogo da memória", action: () => navigate("dashboard/creator/game/memory"), image:"/images/games/g5.jpg", text:"Essa modalidade permite você criar jogos da memórias que nos auxiliam a identificar Soft-skills como agilidade e concentração" },
        { type:"crossword", title:"Palavras cruzadas", action: () => navigate("dashboard/creator/game/crossword"), image:"/images/games/g7.jpg", text:"Essa modalidade permite você criar jogos de palavras cruzadas que nos auxiliam a identificar Soft-skills como agilidade e concentração" },
    ]

    const init = async () => {
        setLoading(true)
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)
            setQuestions(normalResult?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() ))
        }
        setLoading(false)
    }

    const remove = async (item) => {
        setLoading(true)
        const result = await Delete(item?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            init()
        }
        setLoading(false)
    }

    const parseTitle = (type) => {
        return mockedQuestions?.find(f => f.type === type)?.title
    }

    useEffect(() => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated> 


                <BetweenRow>
                    <DashboardTitle>Criador de questões</DashboardTitle>
                    <MaxButton>
                        <CustomButton onClick={() => navigate("dashboard/contentor/tasks")}>Voltar para Acervo</CustomButton>
                    </MaxButton>
                </BetweenRow>
 
                <Row>
                    {
                        options?.map((item, key) => 
                            <Col key={key} sm={12} md={6} lg={3}> 
                                <CardHome onClick={ item?.action }>
                                    <CardHomeDecoration image={item.image} >
                                            { 
                                                !item?.powered ? null : <>
                                                    <IAContainter>
                                                        <IAContentText> IA disponível </IAContentText>
                                                        <IAContent> <IAIcon /> </IAContent> 
                                                    </IAContainter>
                                                </>
                                            }
                                    </CardHomeDecoration>
                                    <CardHomeContainer>
                                        <CardHomeTitle>{ item?.title }</CardHomeTitle>
                                        <CardHomeText>{ item?.text }</CardHomeText>
                                    </CardHomeContainer>
                                </CardHome>
                            </Col> 
                        )
                    }
                </Row> 

            </ContainerAuthenticated> 
        </>
    );
}