import React, { useEffect, useState } from 'react' 

import { 
    FeedbackContainer,
    FeedbackTitle,
    FeedbackText,
    FeedbackRow
} from './styled'

import Button from 'components/Form/CustomButton'  

// import { soundCorrect, soundWrong } from '@utils/audio';

export default function Feedback(props) { 

    const [feedback, setFeedback] = useState(false);

    const readFeedback = () => {  
        setFeedback({
          feedback: props.feedback?.question?.feedback,
          correct: props.feedback?.answer?.correct
        })
        if(props.feedback?.answer?.correct){
            // soundCorrect()
        }else{
            // soundWrong()
        }
    } 

    useEffect(() => { readFeedback() ;}, []) 

    return (
        <>     
            <FeedbackContainer>
                {
                    feedback.correct ? 
                    <FeedbackTitle correct>Certo!</FeedbackTitle>
                        :
                    <FeedbackTitle>Errado,</FeedbackTitle>
                }
                <FeedbackText>{ feedback.feedback }</FeedbackText>
                <FeedbackRow>
                    <Button onClick={props.next} >Continuar</Button>
                </FeedbackRow>
            </FeedbackContainer> 
        </>
    )
}