import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeTitle,
    DashboardTitle,


    CardFormBetweeRow,
    CardFormResponse,
    CardFormResponseFlex,
    CardFormResponseActions,
    IconRemove
} from "./styled";

import { FormAction, FormContainer, MaxButton, FormWrapper } from "ui/styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import ImagedInput from "components/Form/ImagedInput";
import CustomButton from "components/Form/CustomButton";

import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify/dist";
import { Create, ReadOne, Update } from "services/questions";
import { exposeStrapiError, normalizeStrapiRegister } from "utils";
import { CoreContext } from "context/CoreContext";

export default function DashboardCreatorTasksMemory(){  


    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const params = useParams()
    
    const [ loading, setLoading ] = useState(false)
    const [ saving, setSaving ] = useState(false)
    const [options, setOptions] = useState([])

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 


    const fillOptions = (opts, ff) => {
        return opts?.map((m, k) => ({
            // answer: ff?.[`answer${k}`]?.url ? null: ff?.[`answer${k}`],
            image: ff?.[`answer${k}`]?.url ? ff?.[`answer${k}`]?.id : null,
            // correct: ff?.[`correct${k}`]
        }))
    }
    
    const save = async () => {
        if(!form?.question){ return ;}
        

        const opts = fillOptions(options , form)
        
        console.log("OPTS", opts, form) 
        
        setSaving(true);

        const payload = {
            type:"memory",
            time: 60,
            image: form?.question?.url ? form?.question?.id : null,
            question: form?.question?.url ? null : form?.question,
            feedback: form?.feedback,
            options:[ ...opts ],
            order: 1,
            owner: user?.id,
            seed: 'Manual'
        }

        const result = params?.id ? await Update({ data: payload }, params?.id) : await Create({ data: payload })

        if(result && !exposeStrapiError(result)){
            toast.success("Questão salva com sucesso")
            navigate("dashboard/contentor/tasks") ;
        }
        setSaving(false) ;
    }

    const init = async () => {
        setLoading(true)
            const result = await ReadOne(params?.id)
            const normalResult = normalizeStrapiRegister(result)

            const payload = {}

            normalResult?.options?.forEach((f,k) => {
                const normalImage = normalizeStrapiRegister(f?.image)
                payload[`answer${k}`] = normalImage?.url ? normalImage : f?.answer
                payload[`correct${k}`] = f?.correct
            })

            const nextForm = {
                ...normalResult,
                ...payload,
                question: normalResult?.image?.url ?  normalizeStrapiRegister(normalResult?.image) : normalResult?.question
            }

            setOptions(normalResult?.options)

            setForm(nextForm) 
        setLoading(false)
    }

    useEffect(() => {
        if(params?.id){ init() ;}
    }, [ params ])


    const optionAdd = () => {
        setOptions([
            ...options,
            { id:`${ new Date().getTime() }`, answer:"", correct:false }
        ])
    }

    const optionRemove = (item) => {
        setOptions([ ...options.filter(f => f.id !== item?.id ) ])
    }

    return ( 
        <>
            <ContainerAuthenticated> 

                <DashboardTitle>Jogo da memória</DashboardTitle>

                <FormContainer>
                    <ImagedInput placeholder="Título" value={formValue("question")} onChange={e => changeForm(e.target.value, "question")} />
                    <Input type="textarea" placeholder="Feedback" value={formValue("feedback")} onChange={e => changeForm(e.target.value, "feedback")} />      

                    <FormWrapper>
                        {
                            options.map((item, key) =>
                                <CardFormResponse key={key}>
                                    <CardFormResponseFlex>
                                        <ImagedInput onlyImage placeholder="Resposta" value={formValue(`answer${key}`)} onChange={e => changeForm(e.target.value, `answer${key}`)} />
                                    </CardFormResponseFlex>
                                    <CardFormResponseActions>
                                            <MaxButton small>
                                                <Button outline primary nospace onClick={() => optionRemove(item)} >
                                                    <IconRemove />
                                                </Button>
                                            </MaxButton>
                                    </CardFormResponseActions>
                                </CardFormResponse>
                            )
                        }  
                    </FormWrapper>

                    <FormAction big>
                        <CustomButton onClick={optionAdd}>Adicionar opção de resposta</CustomButton> 
                    </FormAction>

                    
                    <CardFormBetweeRow>
                        <FormAction>
                            <CustomButton loading={saving} onClick={save}>Salvar</CustomButton> 
                        </FormAction>
                    </CardFormBetweeRow>
                </FormContainer>

                
    
            </ContainerAuthenticated> 
        </>
    );
}