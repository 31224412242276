import React from "react"; 

import {
    CardCommomContainer,
    CardCommomImage,
    CardCommomContent,
    CardCommomContentTitle,
    CardCommomContentText
} from './styled'

export default function CardCommom({ reverse, blacked, card, square }){  
    return ( 
        <>  
            <CardCommomContainer reverse={reverse}>
                <CardCommomImage blacked={blacked} image={card?.image} square={square} />
                <CardCommomContent>
                    <CardCommomContentTitle blacked={blacked}>{ card?.title }</CardCommomContentTitle>
                    <CardCommomContentText blacked={blacked}>{ card?.text }</CardCommomContentText>
                    <CardCommomContentTitle blacked={blacked}>{ card?.supertitle }</CardCommomContentTitle>
                    <CardCommomContentTitle blacked={blacked}>{ card?.subtitle }</CardCommomContentTitle>
                    { card?.subtext ? <CardCommomContentText blacked={blacked}>{ card?.subtext }</CardCommomContentText> : null }
                </CardCommomContent>
            </CardCommomContainer> 
        </>
    );
}