import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'

import Wrapper from '../Wrapper';
import CustomButton from 'components/Form/CustomButton';
import BodyFeedbackDetails from 'components/Mobile/FeedbackDetails';

export default function ModalResponseDetails() {

  const { modal, setModal, superModal, setSuperModal } = useContext(CoreContext)

  const close = () => {
    setSuperModal(null)
  }

  const handleSave = () => {
    // do something
    close()
  }

  return (
    <>
      <Wrapper mobile>
        { superModal?.feedback ? <BodyFeedbackDetails feedback={superModal?.feedback} /> : null }
        <CustomButton onClick={handleSave} between>Fechar</CustomButton>
      </Wrapper>
    </>
  )
}
