import React, { useContext, useState } from "react"; 
import { useHistory } from 'react-router-dom'; 
import { toast } from 'react-toastify';


import { 
    FormTitle,
    FormText,
    RegisterButton,
    FormSteperCheck,
    FormSteperDecoration,
    FormSteperDecorationLine,
    FormSteperDecorationContent,
    AnimateChilds,
    FormSteperSuccess,
    FormSteperSuccessContainer
} from './styled'

import ContainerUnauthenticated from "containers/Unauthenticated";

import CustomButton from "components/Form/CustomButton";
import { useEffect } from "react";
import { Spacer } from "ui/styled";
import { ReadActivePlan } from "services/plans";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import { CreateSubscribe } from "services/stripe";

export default function StripeCancel(){ 

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 

    const [ loading, setLoading ] = useState(false)   

    const next = () => {
        navigate("login") 
    }

    const mailSupport = () => {
        window.open("mailto:suporte@royalacademy.com.br")
    }

    const retryPayment = async () => {
        setLoading(true)
        const plans = await ReadActivePlan()
        const activePlan = (normalizeStrapiList(plans))?.[0]

        if(!activePlan?.id){
            toast.error("Plano não encontrado, tente novamente mais tarde")
        } else {
            const striped = await CreateSubscribe({
                plan: activePlan?.id,
                price_id: activePlan?.stripe_price_id
            })
            if(!exposeStrapiError(striped) && striped?.checkoutUrl){
                window.open(striped?.checkoutUrl, "royal-charge")
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        toast.error("Pagamento não processado")
    }, [])
 
    return ( 
        <>  
            <ContainerUnauthenticated keep> 

                <AnimateChilds> 
                        
                        <FormText> </FormText> 
                        
                        <FormSteperSuccessContainer>
                            <FormSteperSuccess error>
                                <FormSteperCheck error big /> 
                            </FormSteperSuccess>
                        </FormSteperSuccessContainer>

                        <FormSteperDecoration spaced>
                            <FormSteperDecorationContent>
                                <FormTitle>Precisamos da sua atenção</FormTitle>
                                <FormSteperDecorationLine />
                            </FormSteperDecorationContent>
                            <FormTitle centred spaced>Algo não está certo com sua assinatura, isso pode ocorrer por diversos motivos como por exemplo vencimento ou bloqueio de cartão entre outros, para corrigir o problema acesso o link abaixo</FormTitle>
                        </FormSteperDecoration> 

                        <RegisterButton centred >
                            <CustomButton loading={loading} onClick={retryPayment} color={"red"}>
                                Corrigir pagamento
                            </CustomButton>
                            <Spacer />
                            <CustomButton onClick={mailSupport} color={"red"} outline spaced >
                                Preciso de ajuda
                            </CustomButton>
                        </RegisterButton>

                </AnimateChilds>
                
            </ContainerUnauthenticated> 
        </>
    );
}