import React, { useCallback, useContext, useEffect, useRef, useState } from "react";  

import {  
    CardHome,
    CardHomeTitle,
    ContentStudents,
    DashboardOptionsRow,
    DashboardTitle,
    DragContent,
    DropContent,
    DropContentText,
    DropableContent,
    PlayIcon,
    StudentImage,
    StudentRate,
    StudentRow,
    StudentStats,
    UploadedImage
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useController from "./controller";
import Input from "components/Form/Input";
import DashboardCardQuestion from "components/Cards/CardQuestion";
import { CoinImage, DasboardSpacer } from "ui/styled";
import { useDrop } from "react-dnd";
import { CoreContext } from "context/CoreContext";
import CardFilterModality from "components/Cards/CardFilterModality";
import { DashboardText } from "../styled";
import Select from "components/Form/Select";
import { optionsLevel } from "utils/options";
import UploadFile from "components/Form/UploadFile";
import Button from "components/Form/Button";

import CustomButton from "components/Form/CustomButton";
import { toast } from "react-toastify";
import { Create, Delete, ReadOne, Update } from "services/lessons";

import { exposeStrapiError, parseStrapiImage, normalizeStrapiRegister, normalizeStrapiList, pickRandom } from "utils";
import RowLesson from "components/Rows/Lesson";
import RowTask from "components/Rows/Task";
import RadarChart from "components/Dashboard/Charts/Radar";
import { parseCurrencyMoney, parseGameHome } from "utils/parsers";

export default function DashboardCreatorLessons(){  

    const { 
        changeForm,
        formValue, 
        questions,
        params,
        navigate,
        form,
        setForm,
        history
    } = useController() 

    const { covers, user, setModal } = useContext(CoreContext)
    
    const [saving, setSaving] = useState(false)
    const [showing, setShowing] = useState(false)
    const [loading, setLoading] = useState(false) 
    const [selectedQuestion, setSelectedQuestion] = useState([]) 
    const [cover, setCover] = useState(null)
    const [editing, setEditing] = useState(null)
 
    const triggerRef = useRef()
    const [trigger, setTrigger] = useState(0)


    const goodTry = () => {
        if(triggerRef.current) clearTimeout(triggerRef.current);

        if( trigger >= 21 ){
            changeCover(true)
            setTrigger(0)
            return;
        }
        setTrigger(trigger+1)
        triggerRef.current = setTimeout(() => { setTrigger(0) ;}, 1000)
    }
 
    const removeQuestion = useCallback(async item => {
        const next = [ ...selectedQuestion?.filter(f => f?.id !== item?.id) ]
        setSelectedQuestion(next)
        await superSave({ questions: next?.map(m => m?.id ) })
        await init()
    }, [selectedQuestion])

    
    const addQuestions = () => {
        setModal({ type:"add-tasks", questions:questions, selectedQuestion: selectedQuestion, save: v => saveQuestion(v), navigate })
    } 

    const saveQuestion = async (val) => {
        setSelectedQuestion(val)
        await superSave({ questions: val?.map(m => m?.id ) })
        await init()
    }

    const valid = (verbose) => {
        // console.log("valid", form)
        if(!form?.name?.length){
            if(verbose){ toast.error("Informe o nome da aula") ;}
            return false;
        }
        if(!form?.subject?.length){
            if(verbose){ toast.error("Informe o assunto da aula") ;}
            return false;
        }
        if(!form?.description?.length){
            if(verbose){ toast.error("Informe a descrição da aula") ;}
            return false;
        }
        if(!form?.level){
            if(verbose){ toast.error("Informe o nível da aula") ;}
            return false;
        }
        if(editing){
            if(!cover?.id){
                if(verbose){ toast.error("Selecione a imagem de capa da aula") ;}
                return false;
            }
            if(!selectedQuestion?.length){
                if(verbose){ toast.error("Adicione questões") ;}
                return false;
            }  
        }
        return true;
    }

    const superSave = async (payload) => {
        setLoading(true)
        const result = params?.id ? await Update({ data:payload }, params?.id) : await Create({ data:payload })
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Salvo")
        }
    }

    const save = async () => {
        if(!valid(true)){ return ;}

        const sortedCover = pickRandom(covers?.filter(f => f.type === "lesson"))

        const payload = {
            ...form,
            title: form?.name,
            image: cover?.id ?? sortedCover?.image?.id,
            owner: user?.id,
            questions: selectedQuestion?.map(m => m?.id )
        }

        console.log("payload", payload)
        setSaving(true)
        const result = params?.id ? await Update({ data:payload }, params?.id) : await Create({ data:payload })
        setSaving(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Exercício salvo")
            navigate(`dashboard/creator/lessons/${ result?.data?.id }`); 
            setShowing(false)
        }
    }

    const init = async () => {
        setLoading(true)
            const result = await ReadOne(params?.id)
            const normalResult = normalizeStrapiRegister(result)
            const nextForm = {
                ...normalResult,
                name: normalResult?.title
            }
            delete nextForm.id

            setCover(normalResult?.image?.id ? normalResult?.image : null)

            const nextQuestions =  (normalResult?.questions)?.map(m => ({ ...m, options: m?.options?.map(mm => ({ ...mm, image:normalizeStrapiRegister(mm?.image)})), image:normalizeStrapiRegister(m?.image)  }))  

            setSelectedQuestion( nextQuestions )
            console.log("nextForm", nextForm, nextQuestions )

            setEditing({ ...normalResult, questions:nextQuestions })
            setForm(nextForm)
        setLoading(false)
    }

    const changeCover = (secret = false) => {
        setModal({ type:"select-image", selected: cover?.id, mode: secret ? "secret" : "lesson", save: async v => {
            await superSave({ image: v?.id })
            init()
        } })
    }

    const handleRemove = () => {
        setModal({ 
            type:"confirm",
            title:"Remover exercício",
            text:"Deseja realmente remover o exercício? essa ação não pode ser desfeita",
            cancel:"Não remover",
            confirm:"Sim, remover exercício",
            onConfirm: () => remove()
        })
    }

    const remove = async () => {
        setLoading(true)
        const result = await Delete(editing?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            history.goBack()
        }
        setLoading(false)
    } 

    const openModalLessonResponse = () => {
        if(editing?.response){
            setModal({
                type:"response",
                currentLesson: editing
            })
        } else {
            setModal({ type:"game", currentLesson: parseGameHome(editing), forfun: true })
        }
    }

    useEffect(() => {
        if(params?.id){ init() ;}
    }, [params])


    return ( 
        <>
            <ContainerAuthenticated> 
                <Row>

                    { !editing ? <Col sm={12} md={3} /> : null }
                    <Col sm={12} md={!editing ? 6 : 7}> 
                        {
                            editing ? <>
                                <DashboardTitle>{ editing?.title }</DashboardTitle>
                                <StudentRow>
                                    { cover?.url ? <StudentImage onClick={goodTry} src={parseStrapiImage(cover?.url)} /> : null } 
                                    <StudentStats>
                                        Recompensa
                                        <StudentRate> { parseCurrencyMoney(editing?.coins||0) } <CoinImage huge /> </StudentRate>
                                    </StudentStats>
                                </StudentRow>
                                
                                <StudentStats>{ editing?.subject }</StudentStats>
                                <DashboardTitle>#{ optionsLevel?.find(f => f.id === editing?.level)?.title }</DashboardTitle>

                                <DashboardOptionsRow>
                                    { showing ? null : <CustomButton onClick={() => setShowing(true)}>Editar</CustomButton> }
                                    <CustomButton loading={loading} onClick={() => changeCover(false)}>Imagem de capa</CustomButton>
                                    <CustomButton onClick={handleRemove}>Remover Exercício</CustomButton>
                                    <CustomButton onClick={openModalLessonResponse}> <PlayIcon /> </CustomButton>
                                </DashboardOptionsRow>


                            </> :
                            <DashboardTitle>Criar Exercício</DashboardTitle>
                        }

                        {
                            editing && !showing ? <>
                                <RadarChart questions={selectedQuestion} allQuestions={questions} />
                            </> : <>
                                
                                <DasboardSpacer />
                                <Input placeholder="Nome do exercício" value={formValue("name")} onChange={e => changeForm(e.target.value, "name")} />
                                <DasboardSpacer />
                                <Input placeholder="Assunto do exercício (Os assuntos ajudam a filtrar os exercícios)" value={formValue("subject")} onChange={e => changeForm(e.target.value, "subject")} />
                                <DasboardSpacer />
                                <Select placeholder="Dificuldade (essa dado impacta nas recompensas recebidas pelos alunos)" options={optionsLevel} value={formValue("level")} onChange={ev => changeForm(ev, "level")} />
                                <DasboardSpacer />
                                <Input type="textarea" placeholder="Descrição" value={formValue("description")} onChange={e => changeForm(e.target.value, "description")} />

                                {
                                    !editing ? null : <>

                                    </>
                                }
                                <DasboardSpacer />
                                <CustomButton primary loading={saving} onClick={save}> Salvar </CustomButton>
                            </>
                        }
                    </Col>




                    {
                        !editing ? null : <>
                            <Col sm={12} md={5}>
                                <DashboardTitle>Questões</DashboardTitle>

                                <CustomButton onClick={addQuestions}>Adicionar questões ao exercício</CustomButton>
                                <ContentStudents>
                                    {
                                        selectedQuestion?.map((item, key) => 
                                            <RowTask key={key} item={item} remove={() => removeQuestion(item)} />
                                        )
                                    }
                                </ContentStudents>
                            </Col>
                        </>
                    }
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}