import styled from 'styled-components' 

export const BannerImage = styled.div.attrs({ 
})`           
    margin-top: -93px;
    position: relative;
    min-height: 160vh;

    width: 100%;

    background: white url(/images/banner.png) no-repeat center center / cover;
 
    overflow: hidden;
`; 

export const BannerOut = styled.div.attrs({ 
})`            
    min-height: 160vh;
    width: 100%;


    background: -moz-linear-gradient(0deg, ${ props => props.theme.palette.colors.black } 0%, ${ props => props.theme.palette.colors.shadow } 60%);
    background: -webkit-linear-gradient(0deg, ${ props => props.theme.palette.colors.black } 0%, ${ props => props.theme.palette.colors.shadow } 60%);
    background: linear-gradient(0deg, ${ props => props.theme.palette.colors.black } 0%, ${ props => props.theme.palette.colors.shadow } 60%);

    // mix-blend-mode: multiply;
    
`; 

export const BannerContent = styled.div.attrs({ 
})`            
    min-height: 160vh;
    width: 100%; 
    position: absolute;
    top: 0;
    left: 0;
`; 

export const BannerCard = styled.div.attrs({ 
})`           
`; 

export const BannerTitle = styled.div.attrs({ 
})`           
    margin: 25vh 0 50px;
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
    }
`; 

export const BannerText = styled.div.attrs({ 
})`           
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 32px;
    font-weight: 700;
`; 


export const BannerButton = styled.div.attrs({ 
})`           
    max-width: 220px;
    margin: 32px auto 0;
`; 

export const RowSignNow = styled.div.attrs({ 
})`           
    margin-top: 72vh;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
    flex-direction: column;
`; 
 

export const ButtonSignNow = styled.div.attrs({ 
})`           
    max-width: 200px;
    width: 100%;
`; 