import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'
import { FormText, Title } from 'ui/styled'
import { CompanyButtonContainer, AppLogo, LogoContainer } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';
import CustomButton from 'components/Form/CustomButton';

import QRCode from "react-qr-code";
import { useMemo } from 'react';
import { API_ENDPOINT } from 'services/api';
import { toast } from 'react-toastify';

export default function ModalQrCode() {

  const { modal, setModal } = useContext(CoreContext)

  const mobileUrl = useMemo(() => {
    return `${API_ENDPOINT}/deeplink/login/${modal?.code}`
  }, [modal])
  
  const webUrl = useMemo(() => {
    return `${window.location.origin}/student/lessons/${modal?.code}`
  }, [modal])

  const close = () => {
    setModal(null)
  }

  const copy = () => {


    navigator.clipboard.writeText(webUrl)
      .then(() => { toast.success("Copiado") ;})
      .catch((err) => { toast.error("Erro ao copiar") ;});

    close()
  }

  const sent = () => {

    const title = "Royal Academy";
    const introParagraph = `Olá ${ modal?.student?.name }, clique no link para estudar jogando:`;
    const message = `${title}\n\n${introParagraph}\n${webUrl}`;
    const encodedMessage = encodeURIComponent(message);

    const phoneNumber = "1234567890";

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, "whatsapp")

    toast.success("Enviado") ;

    close()
  }

  const print = () => {
    // do something
    printDiv("print-qrcode")
    close()
  }

  const printDiv = (divId) => {
    // Get the content of the div
    var content = document.getElementById(divId).innerHTML;

    // Create a new window or iframe to print the content
    var printWindow = window.open('', '', 'height=600,width=420');
    
    printWindow.document.write(`<html><head><title>Acesso aluno - ${ modal?.student?.name }</title>`);
    printWindow.document.write('<style>body{font-family: Arial, sans-serif;}</style>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    
    setTimeout(() => {
      // Close the document to trigger rendering
      printWindow.document.close();

      // Wait for the document to be fully loaded and then trigger print
      printWindow.print();
    }, 2000)
}

  return (
    <>
      <Wrapper>

      <div id="print-qrcode">
          <LogoContainer>
            <img src={`/logo1024.png`} height={120} style={{ marginRight: "auto", marginLeft:"auto" }} />
            <Title centred style={{textAlign:"center"}}>{ modal?.student?.name }</Title>
            {
              !modal?.code ? null : 
              <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
                <QRCode
                  size={1024}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={ mobileUrl }
                  viewBox={`0 0 1024 1024`}
                />
              </div>
            }

          <Title centred style={{textAlign:"center"}}>{ modal?.code }</Title>
          </LogoContainer>
      </div>
        <CompanyButtonContainer>
          <CustomButton onClick={copy}>Copiar</CustomButton>
          <CustomButton onClick={sent}>Enviar por whatsapp</CustomButton>
          <CustomButton onClick={print}>Imprimir</CustomButton>
        </CompanyButtonContainer>
      </Wrapper>
    </>
  )
}
