import styled from 'styled-components'   
 
export const DashboardHeaderContainer = styled.div.attrs({ 
})`           
    min-height: 60px;
    width: 100%;

    border-bottom: 1px solid ${ props => props.theme.palette.colors.lightshadow };
    background: linear-gradient(45deg, rgba(${props => props.theme.palette.primary.main}, .45) 0%, rgba(${props => props.theme.palette.primary.main}, .6) 25%, rgba(${props => props.theme.palette.primary.main}, .75) 50%, rgba(${props => props.theme.palette.primary.main}, .9) 75%, rgba(${props => props.theme.palette.primary.main}, 1) 100%);
    padding: 0 18px;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DashboardHeaderAction = styled.div.attrs({ 
})`           
    position: relative;
    color: ${ props => props.theme.palette.colors.white };
    font-size: 18px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    gap: 12px;

    transition: all .3s ease;
    &:hover{
        transform: scale(1.01);
    }

`;

export const DashboardHeaderInfo = styled.div.attrs({ 
})`           
`;
export const DashboardHeaderInfoImage = styled.div.attrs({ 
})`         
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: ${ props => props.theme.palette.colors.white } url(${ p=>p.image}) no-repeat center center / cover ;
`;
export const DashboardHeaderInfoText = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.white };
    font-size: 12px;
    ${
        p => p.big ? `
            font-size: 18px;
        ` : ``
    }
`;

export const DashboardHeaderTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    color: ${ props => props.theme.palette.colors.black }; 
    display: flex;
    gap: 6px;
    align-items: center;
    font-weight: 600;

    @media(max-width: 991px){
        font-size: 16px;
    }
`;

export const DashboardHeaderLogo = styled.div.attrs({ 
})` 
    width: 48px;
    aspect-ratio: 1 / 1 ;          
    background: url(/images/mascot.png) no-repeat center center / cover;
    ${
        p => p.rounded ? `
            border-radius: 24px;
            background: url(/images/mascot.png) no-repeat center center / 320%;
        ` : ``
    }
`;

export const DashboardHeaderActionMenuItem = styled.div.attrs({ 
})`           
    padding: 12px 24px;
    border-bottom: .5px solid ${ props => props.theme.palette.colors.shadow };
    border-bottom-left-radius: 3px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
        color: ${ p => p.theme.palette.colors.backgrounddark };  
    }
`;

export const DashboardHeaderActionMenu = styled.div.attrs({ 
})`           
    top: 60px;
    right: 0px;

    width: 100%;
    max-width: 0px;
    height: 0px;

    overflow: hidden;

    position: absolute;
    background: ${ props => props.theme.palette.colors.white };
    z-index:4;

    border-bottom-left-radius: 8px;
    
    transition: all .3s cubic-bezier(.75,.31,.76,.31);

    ${
        p => p.opened ? `
            max-width: 220px;
            height: 220px;

            border: .5px solid ${ p.theme.palette.colors.backgrounddark }; 
            border-top: 10px solid ${ p.theme.palette.colors.backgroundcontrast };
            box-shadow: -1px 3px 6px ${ p.theme.palette.colors.shadow};

            overflow: visible;
        ` : ``
    }
`;

export const AppLogo = styled.img.attrs({ 
    src:`/logo1024.png`,
    alt:"logo-icon",
    height:50
})`            
`;

export const DashboardHeaderActionIcon = styled.img.attrs({ 
    width: 32
})`           
`;
export const DashboardHeaderActionIconChevron = styled.img.attrs({ 
    width: 32,
    src: `/icons/caret.svg`
})`           
    transform: rotate( 0deg );
`;

export const DashboardMenuContainer = styled.div.attrs({ 
})`           
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background: ${ props => props.theme.palette.colors.shadow };
`;

export const DashboardMenu = styled.div.attrs({ 
    className:'menu-contant'
})`           
    max-width: 389px;
    background: ${ props => props.theme.palette.colors.white };
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const DashboardMenuHeader = styled.div.attrs({ 
})`           
    height: 60px;
    width: 100%; 
    display: flex;
    align-items: center;
    padding: 0 20px;

    text-transform: uppercase;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.white };
    cursor: pointer;

    background: ${ props => props.theme.palette.primary.main };
    background: linear-gradient(48deg, rgba(${props => props.theme.palette.primary.main},1) 0%, rgba(${props => props.theme.palette.primary.main},.9) 21%, rgba(${props => props.theme.palette.primary.main},.75) 49%, rgba(${props => props.theme.palette.primary.main},.6) 87%, rgba(${props => props.theme.palette.primary.main},.45) 100%);
`;

export const DashboardMenuHeaderIcon = styled.img.attrs({ 
})`           
    margin-right: 20px;
    cursor: pointer;
`;

export const DashboardMenuHeaderUserContent = styled.div.attrs({ 
})`           
    padding: 27px 25px;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.lightgrey };
    margin-bottom: 28px;
`;

export const DashboardMenuHeaderUserImage = styled.div.attrs({ 
})`           
    width: 160px;
    height: 160px; 
    background: ${ props => props.theme.palette.colors.grey } url(/logo1024.png) no-repeat center center / cover;
    margin: 0 auto 12px;
    overflow: hidden;
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({ 
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({ 
})`           
    padding: 20px 30px;
    font-size: 15px;
    color: ${ props => props.theme.palette.colors.grey };
    cursor: pointer; 

    &:hover{
        text-decoration: underline;
    }
    
    ${
        props => props.active ? `
            background: ${ props.theme.palette.primary.main };
            font-size: 15px;
            font-weight: bold;
            color: ${ props.theme.palette.colors.white };
        ` : ``
    }
`;

export const DashboardMenuContent = styled.div.attrs({ 
})` 
    flex:1;
`;

export const DashboardMenuFooter = styled.div.attrs({ 
})`
    padding: 20px;
`;

export const DashboardVersionContent = styled.div.attrs({ 
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({ 
})`
    font-size: 15px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.grey };
    text-align: center; 
`;



export const DashboardMenuHeaderUserImageContainer = styled.div.attrs({ 
})`           
    background: url(/images/backgrounds/island.png) no-repeat top center / 100%;
`;

export const DashboardMenuHeaderUserImageContainerFade = styled.div.attrs({ 
})`           
    background: linear-gradient(to right, white 70%, transparent);
    @media(max-width: 991px){
        background: linear-gradient(to right, white 30%, transparent);
    }
`;
