import React, { useEffect, useMemo, useState } from 'react'

import { CheckedLetter, CrosswordLetter, TableCol, TableOut, TableRow } from './styled'

import { 
    Touch,
    Question,  
    QuestionImage 
} from 'ui/styled'

export default function Crosswords(props){  

    const [card, setCard] = useState(null)   
  
    const [crosswords, setCrosswords] = useState([ ])
    const [responses, setResponses] = useState([ ]) 

    const alphabeth = useMemo(() => [ 
        'a','b','c','d','e','f','g','h',
        'i','j','k','l','m','n','o','p','q',
        'r','s','t','u','v','w','x','y','z'
    ], []) 

    const beginsCrossword = _card => {
        if (_card.answers) {
            let _cross = [];

            const begin_lines = _card?.answers?.reduce((p, c) => c?.length > p ? c?.length : p , 0);
            const begin_columns = begin_lines+1;

            let { _answers, lines, columns } = raffleAnswer(_card, begin_lines, begin_columns)
            for (let x = 0; x < lines; x++) {
                let line = []
                for (let y = 0; y < columns; y++) {
                    if (_answers.filter(item => item.x === x && item.y === y).length > 1) {
                    let letter = {}
                        _answers.filter(item => item.x === x && item.y === y).map(item => letter = { ...letter, ...item })
                        line.push(letter)
                    } else {
                    let letter = _answers.filter(item => item.x === x && item.y === y).length > 0 ?
                        _answers.filter(item => item.x === x && item.y === y).shift() :
                        { letter: raffleLetter() }
                        line.push(letter)
                    }
                }
                _cross.push(line)
            }
            setCrosswords(_cross)
        }
    }
    
    const raffleAnswer = (_card, lines, columns, times = 0) => {
        let _answers = []
        _card.answers.map((answer, _kan) => {
            // let raffle = Math.floor(Math.random() * 101);
            
            let vertical = (_kan % 2 === 0)

            let maxY = vertical ? lines - answer.length : lines - 1;
            let maxX = !vertical ? columns - answer.length : columns - 1;
            let beginPositionX = Math.floor(Math.random() * maxX)
            let beginPositionY = Math.floor(Math.random() * maxY)
            Array.from(answer).map((letter, kletter) => {
            let x = !vertical ? beginPositionX + (kletter) : beginPositionX,
                y = vertical ? beginPositionY + (kletter) : beginPositionY;
            let _pl = {
                horizontal: !vertical,
                x, y,
                letter,
                checked: false,
                checkable: true,
                key: answer
            }
            if (kletter === 0) { _pl['first'] = !vertical ? 'x' : 'y' }
            if ((kletter + 1) === answer.length) { _pl['last'] = !vertical ? 'x' : 'y' }
                _answers.push(_pl)
            })
        })
        let can = true
        _answers.map((_a, _ka) => {
            _answers.map((_b, _kb) => {
                if (_ka != _kb) { 
                    
                    if (_a.x === _b.x && _a.y === _b.y && _a.letter !== _b.letter) { can = false; return } // letra diferente na mesma posicao
                    
                    if (_a.horizontal && _a.horizontal === _b.horizontal && _a.x === _b.x && _a.y === _b.y) { can = false; return }  // palavras na horizontal estão na mesma linha
                    if (!_a.horizontal && _a.horizontal === _b.horizontal && _a.y === _b.y && _a.x === _b.x) { can = false; return }  // palavras na vertical estão na mesma linha
                    
                    if (_a.x === _b.x && _a.y === _b.y && _a.letter === _b.letter) {
                        _a['cross'] = _a.cross ? [..._a.cross, _a.key, _b.key] : [_a.key, _b.key];
                        _b['cross'] = _b.cross ? [..._b.cross, _a.key, _b.key] : [_a.key, _b.key];
                    }

                }
            })
        })

        if(!_answers?.filter(f => !!f.cross)?.length){ can = false ;}

        if (can) {
            return { _answers, lines, columns };
        } else {
            const striked = (times >= 300)
            return raffleAnswer(_card, (striked ? (lines+1) : lines), (striked ? (columns+1) : columns), (striked ? 0 : (times + 1)));
        }
    }

    const raffleLetter = () => {
        return alphabeth[Math.floor(Math.random() * alphabeth.length)]
    }


    const parseCrossword = _card => {
        let _crosswords = []

        try{
            _crosswords = JSON.parse(_card.crosswords)
        }catch(error){
            try{
                _crosswords = JSON.parse(JSON.stringify(_card.crosswords))
            }catch(silent_error){ }
        }
        
        if(typeof _crosswords === 'string'){ _crosswords = JSON.parse(_crosswords) }

        setCrosswords(_crosswords) 
    }

    const checkLetter = (letter, kcol, krow) => {
        if(!props.answer){ 
        if(letter.checkable){ crosswords[krow][kcol].checked = true ;}
        if(letter.first || letter.last){
            let autocomplete = false
            crosswords.map((_a, _ka ) => { 
                if(letter.last){
                    if( _a.filter((_b, _kb ) => { 
                        return ( _b.key === letter.key 
                            || _b.cross && _b.cross.indexOf(letter.key) !== -1  )
                            && (( _b.first && !(_b.cross) ) || ( _b.first && _b.cross && _b.first != letter.first )) 
                            && _b.checked }).length > 0 ){ autocomplete = true ;}
                }
                if(letter.first){
                    if( _a.filter((_b, _kb ) => { 
                    return ( _b.key === letter.key
                            || _b.cross && _b.cross.indexOf(letter.key) !== -1  ) 
                            && (( _b.last && !(_b.cross) ) || ( _b.last && _b.cross && _b.last != letter.last )) 
                            && _b.checked }).length > 0 ){ autocomplete = true ;}
                }
            }) 
            if(autocomplete){
                crosswords.map((_a, _ka ) => {
                    _a.map((_b, _kb ) => { if(_b.key === letter.key || _b.cross && _b.cross.indexOf(letter.key) !== -1){ crosswords[_ka][_kb].checked = true ;} ;})
                })
            }  
        }

        // verify if complete some word
        // console.log("crosswords", crosswords)
        const nextResponses = []
            card.answers.map((_an, _kan) => {
                let checkeds = 0;

                crosswords.map((_a, _ka ) => {
                    _a.map((_b, _kb ) => { 
                        if((_b.key === _an || _b.cross && _b.cross.indexOf(_an) !== -1 ) && _b.checked){ checkeds++ ;} ;
                    })
                })

                // console.log("checkds", checkeds, _an.length)
                if(checkeds === _an.length && nextResponses.indexOf(_an) === -1){ 
                if([...nextResponses, _an].length === card.answers.length){ endGame(true, crosswords) ;}
                    nextResponses.push(_an);
                }
            })
            setResponses(nextResponses)
            setCrosswords([ ...crosswords ])
        }
    } 

    const endGame = (correct, crosswords) => {
        if(props.response){
            props.response({
                question: props.question,
                answer:{ correct, crosswords }
            })
        }
    }
    
    useEffect(() => { 
        console.log("responsesx", responses, card)
        if( props?.question && !card ){ 
            // setCard({ answers: [] })
            setCard({ answers: props?.question?.options?.map(m => m?.answer) })
            beginsCrossword({ answers: props?.question?.options?.map(m => m?.answer) })
        }
    }, [props?.question]); 
    
    useEffect(() => {
        if(props?.answer){ setCrosswords(props?.answer) ;}
    }, [props?.answer])

    return (
        <> 
            
            { props?.question?.image ? <QuestionImage source={ props.question.image } /> : null }
            { props?.question?.question ? <Question>{ props.question.question }</Question> : null }
            
            <TableOut>
                {
                    crosswords.map((row, krow) => <TableRow animation="flipInX" key={krow} >
                    {
                        row.map((col, kcol) => <div key={`${krow}-${kcol}`} onClick={() => checkLetter(col, kcol, krow)}>
                            <TableCol columns={row?.length}>
         
                                <CheckedLetter 
                                    checked={col.checked} 
                                    first={!col.cross && col.first && !(col.first !== 'y')} 
                                    firstY={!col.cross && col.first && (col.first !== 'y')} 
                                    last={!col.cross && col.last && !(col.last !== 'y')} 
                                    lastY={!col.cross && col.last && (col.last !== 'y')}
                                    animation={ col?.checked ? "rubberBand" : "tada" } delay={ props.answer ? 0 : 300 + parseInt(`${krow}00`) * kcol} > 
                                    <CrosswordLetter checked={col.checked} >{col.letter}</CrosswordLetter>
                                </CheckedLetter> 
        
                            </TableCol>
                        </div>)
                    }
                    </TableRow>)
                } 
            </TableOut>

        </>
    )
}