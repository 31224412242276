import React, { useContext, useEffect, useMemo, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    DashboardInput,
    DashboardButton,
    DarboardUserImage,
    DarboardUserImageAction,
    DarboardUserImageActionIcon,
    DasboardSpacer,
    DasboardActions,
    TabsActions,
    PaymentStatus,
    PaymentStatusContainer,
    CardPlan,
    PlanIcon,
    PlanContent,
    PlanTitle,
    PlanText,
    PlanVerify,
    DownloadIcon
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import { CoreContext } from "context/CoreContext";

import moment from 'moment';
import 'moment/locale/pt-br';

import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { capitalize, exposeStrapiError, parseStrapiImage } from "utils";
import UploadFile from "components/Form/UploadFile";
import { ReadMe, RemoveMe, UpdateMe } from "services/me";
import { toast } from 'react-toastify';
import { DoLogout } from "services/authentication";
import { useHistory } from "react-router-dom";
import { Load } from "ui/styled";
import CustomButton from "components/Form/CustomButton";
import BasicTable from "components/Form/Table";
import { parseCurrency } from "utils/parsers";
import { optionsStatusPay } from "utils/options";
import { CancelSubscription } from "services/stripe";

export default function DashboardMe(){  
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 

    const { user, setModal, reloadMe } = useContext(CoreContext)
   
    const [tab, setTab] = useState(0)

    const [preview, setPreview] = useState(null)
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)

    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const columns = [
        { ref:"invoice", title:"Fatura" },
        { ref:"value", title:"Valor" },
        { 
            ref:"status", 
            title:"Status", 
            renderCell: ({ row }) => <>
                <PaymentStatusContainer>
                    <PaymentStatus status={row?.status}>{ row?.status }</PaymentStatus>
                </PaymentStatusContainer>
            </>
        },
        { 
            title:" ", 
            renderCell: ({ row }) => !row?.invoice_pdf ? null : <>
                <DownloadIcon onClick={() => window.open(row?.invoice_pdf)} />
            </>
        },
    ]

    const rows = useMemo(() => [
        ...(user?.signature?.payments||[])?.map( m => ({ 
                invoice: capitalize(moment(new Date().setTime(m?.created*1000))?.format("MMM/YYYY")), 
                value: parseCurrency(m?.total/100),
                status: optionsStatusPay?.find(f => f?.id === m?.status)?.title,
                invoice_pdf: m?.invoice_pdf
            }
        )),
    ], [user])

    const exit = async () => {  
        await DoLogout() 
        navigate('login');
    }  

    const takePic = async (result) => { 
        setFetching(true)   
        if(result?.id){
            await UpdateMe({ image: result.id })
            await reloadMe() 
        } 
        setFetching(false) 
    }

    const init = async () => {
        setLoading(true) 
        await reloadMe() 
        setLoading(false)
    }

    const removeAccount = async () => {
        setModal({ 
            type:"confirm",
            title:"Cancelar Assinatura",
            text:"Deseja realmente cancelar sua assinatura? ao cancelar a assinatura seus alunos ficaram sem acesso aos exercícios e seus dados serão removidos em 90 dias",
            cancel:"Não cancelar",
            confirm:"Sim, cancelar assinatura",
            onConfirm: () => confirmRemoveAccount()
        })
    }

    const confirmRemoveAccount = async () => {
        setLoading(true)
        const result = await CancelSubscription()
        if(result && !exposeStrapiError(result)){
            toast.success('Assinatura cancelada com sucesso')
            exit();
        }
        setLoading(false)
    }

    const save = async () => {
        setLoading(true)
        const result = await UpdateMe({ ...form })
        if(result && !exposeStrapiError(result)){
            await init()
            navigate("dashboard")
        }
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])
    
    useEffect(() => {
        setPreview(user?.image?.url ? parseStrapiImage(user?.image?.url) : preview)
        setForm({ ...form, ...user })
    }, [user])

    return ( 
        <>
            <ContainerAuthenticated> 
                <Row>
                    <Col>
                        <DasboardActions>
                            <CustomButton outline={tab !== 0} onClick={() => setTab(0)}>Meus Dados</CustomButton>
                            <CustomButton outline={tab !== 1} onClick={() => setTab(1)}>Meu Plano</CustomButton>
                        </DasboardActions>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={4}>
                        <DarboardUserImage image={preview ? preview : '/images/no-user.png'} />
                        <DashboardText centred>
                            Usuário desde { moment(user.created_at).format('L') }
                        </DashboardText> 
                        {
                            tab === 1 ? 
                                <CustomButton loading={loading} onClick={removeAccount}>Cancelar Assinatura</CustomButton>
                            : 
                                <CustomButton outline onClick={exit}>Sair</CustomButton>
                        }
                    </Col>
                    {
                        !(tab === 0) ? null :
                        <Col sm={12} md={8}>
                            <DasboardSpacer />
                            <Input placeholder="Nome" value={formValue("name")} onChange={e => changeForm(e.target.value, "name")} />
                            <DasboardSpacer />
                            <Input placeholder="Matéria" value={formValue("matter")} onChange={e => changeForm(e.target.value, "matter")} />
                            <DasboardSpacer />
                            <Input type="textarea" placeholder="Biografia" value={formValue("bio")} onChange={e => changeForm(e.target.value, "bio")} />
                            <DasboardSpacer />
                            <DasboardActions>
                                <CustomButton loading={loading} onClick={save}>Salvar</CustomButton>
                            </DasboardActions>
                        </Col>
                    }
                    
                    {
                        !(tab === 1) ? null :
                        <Col sm={12} md={8}>
                            <DashboardTitle>Faturas</DashboardTitle>

                            <CardPlan>
                                <PlanIcon />
                                <PlanContent>
                                    <PlanTitle>Plano { user?.signature?.subscription?.order?.plan?.name }</PlanTitle>
                                    <PlanText>{ user?.signature?.subscription?.order?.plan?.short }</PlanText>
                                    { user?.signature?.subscription?.status === 'active' && <PlanText bold>Assinatura Ativa</PlanText> }
                                </PlanContent>
                                <PlanVerify />
                            </CardPlan>

                            <DasboardSpacer />
                            <BasicTable columns={columns} rows={rows} />
                        </Col>
                    }
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}