import React, { useContext, useState } from "react"; 

import { 
    FormSteperButton
} from './styled'

import { Load } from "components/Form/Button/styled";


export default function CustomButton({ onClick, children, outline, loading, small, color }){  
    return ( 
        <>   
            <FormSteperButton small={small} outline={outline} onClick={onClick} color={color}> 
                { loading ? <Load backed /> : children }
            </FormSteperButton>  
        </>
    );
}