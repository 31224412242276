import { CoreContext } from "context/CoreContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";  

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify"; 
import { Create, Update } from "services/classes";
import { ReadByUser } from "services/lessons";
import { ReadByUser as ReadStudentisByUser } from "services/students";

import { exposeStrapiError, goSection, normalizeStrapiList, normalizeStrapiRegister } from "utils";

export default function useController(fnReload){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const params = useParams()

    const { user, setModal } = useContext(CoreContext)

    const [loading, setLoading] = useState(false) 
    const [questions, setQuestions] = useState([])    
    const [users, setUsers] = useState([])    

    const [selectedUser, setSelectedUser] = useState([])
    const [selectedQuestion, setSelectedQuestion] = useState([])
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const init = async () => {
        setLoading(true)
        await fetchLessons()
        await fetchStudents()
        setLoading(false)
    }

    const fetchLessons = async () => { 
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)
            const nextQuestions = normalResult
                ?.map( m => {
                    const nimage = normalizeStrapiRegister(m?.image)
                    return ({
                        ...m,
                        image: nimage?.url ? nimage : null,
                        questions: normalizeStrapiList(m?.questions)
                    })
                })?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() )

            setQuestions(nextQuestions)
        } 
    }
    
    const fetchStudents = async () => { 
        const result = await ReadStudentisByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)
            const nextQuestions = normalResult
                ?.map( m => {
                    // const nimage = normalizeStrapiRegister(m?.image)
                    return ({
                        ...m,
                        avatar: normalizeStrapiRegister(m?.avatar)
                    })
                })?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() )

            setUsers(nextQuestions)
        } 
    }

    const addStudents = () => {
        setModal({ type:"add-students", students:users, selectedStudents: selectedUser, save: v => saveStudent(v), navigate })
    }

    const saveStudent = async val => {
        setSelectedUser(val)
        await save({ students: val?.map(m => m?.id ) })
        fnReload()
    }

    const addQuestions = () => {
        setModal({ type:"add-lessons", questions:questions, selectedQuestion: selectedQuestion, save: v => saveQuestion(v), navigate })
    }

    const saveQuestion = async val => {
        setSelectedQuestion(val)
        await save({ lessons: val?.map(m => m?.id ) })
        fnReload()
    }

    const save = async (payload) => {
        setLoading(true)
        const result = params?.id ? await Update({ data:payload }, params?.id) : await Create({ data:payload })
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Salvo")
        }
    }

    useEffect(() => { init() ;}, [])

    return { 
        loading,  

        formValue,
        changeForm,
  
        questions,
        users,

        params,
        navigate,
        form,
        setForm,

        selectedUser, setSelectedUser,
        selectedQuestion, setSelectedQuestion,
        
        addStudents,
        addQuestions,
        superSave: save,

        history

    }
}