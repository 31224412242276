import { useContext, useState, useEffect } from "react";

import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister } from "utils";
import { Read } from "services/core";

export default function useBackgroundCover(){

    const { covers, setCovers } = useContext(CoreContext)
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await Read("backgrounds?populate=*")
        if(result && !exposeStrapiError(result)){
            // console.log("Backgrounds", normalizeStrapiList(result))
            setCovers(
                normalizeStrapiList(result)
                    ?.map(m => ({ ...m, image:!m?.image ? null : normalizeStrapiRegister(m?.image) }))
            )
        }
        setLoading(false)
    }

    useEffect(() => { init() }, [])

    return {
        loading, 
        covers
    }
}