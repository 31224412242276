import React, { useContext, useEffect } from "react";  
 
import Header from 'components/Dashboard/Header'  
  
import { 
    DashboardPage,
    DashboardBody,
    DashboardBodyContent,
    Content
} from "./styled";
import { ReadObject } from "services/storage";
import { useHistory } from "react-router-dom";
import { ThemedComponent } from "ui/theme";
import DashboardSide from "components/Dashboard/Side";
import { Container } from "reactstrap";
import { CoreContext } from "context/CoreContext";

export default function ContainerAuthenticated({ children, flat }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user, reloadMe } = useContext(CoreContext)

    const init = async () => {
        const authentication = ReadObject('authentication')
        if (!authentication?.jwt) {
            return completeNext()
        }
        if(!(user?.signature?.subscription?.status === 'active')){
            const nuser = await reloadMe()
            if(!(nuser?.signature?.subscription?.status === 'active')){
                navigate("stripe/cancel")
            }
        }
    }
 
    const completeNext = () => {
        navigate('login')
    }

    useEffect(() => {  
        init()
        window.scrollTo(0,0)
    }, [])

    return ( 
        <>
            <ThemedComponent>
                {
                    flat ? children :
                    <Content>
                        <DashboardPage>
                            <Header /> 
                            <DashboardBody> 
                                <DashboardSide /> 
                                <DashboardBodyContent>             
                                    <Container>
                                        { children }
                                    </Container>        
                                </DashboardBodyContent>
                            </DashboardBody>  
                        </DashboardPage> 
                    </Content>
                }
            </ThemedComponent>
        </>
    );
}