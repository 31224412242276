import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeDecoration,
    CardHomeTitle,
    CardQuestion,
    CardQuestionAvatar,
    CardQuestionButton,
    CardQuestionButtonContainer,
    CardQuestionContainer,
    CardQuestionContent,
    CardQuestionDecoration,
    CardQuestionIcon,
    CardQuestionIconDecoration,
    CardQuestionImage,
    CardQuestionRow,
    CardQuestionSeed,
    CardQuestionTitle,
    ContentFilter,
    ContentFilterContainer,
    ContentFilterContent,
    ContentFilterSeed,
    ContentFilterTitle,
    DashboardTitle,
    FullLineFilter,
    FullLineFilterInput,
    ImageSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom";
import { Delete, ReadByUser } from "services/lessons";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils"; 
import Button from "components/Form/Button";
import { BetweenRow, CoinImage, Load, LoadCenter, MaxButton, RowBetween } from "ui/styled";
import { toast } from "react-toastify"; 
import { optionsLevel } from "utils/options";
import { useDrag } from 'react-dnd'
import { parseCurrencyMoney } from "utils/parsers";

export default function CardLesson({ item, editable, onRemove, big, doubleClick }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 

    const {setDragging} = useContext(CoreContext)

    const [{ opacity, isDragging }, dragRef] = useDrag(() => ({
        type: 'card-lesson', item,
        collect: (monitor) => ({ 
            opacity: monitor.isDragging() ? 0.5 : 1,
            isDragging: monitor.isDragging(),
        })
    }), [item]) 

    const remove = (item) => {
        if(onRemove && typeof onRemove === 'function'){ onRemove(item) ;}
    }

    useEffect(() => { setDragging(isDragging) }, [isDragging])

    return ( 
        <>
            <Col  style={{ opacity }} sm={12} md={big ? 12 : 6} lg={big ? 6 : 3}> 
                <CardQuestion ref={!!onRemove ? null : dragRef} draggable={!onRemove} >
                    <CardQuestionContainer onDoubleClick={doubleClick || onRemove} onClick={!!onRemove || doubleClick ? null  : () => navigate(`dashboard/creator/lessons/${item?.id}`)} >
                        <CardQuestionAvatar>
                            <CardQuestionTitle>{ parseCurrencyMoney(item?.coins) }<CoinImage negative /></CardQuestionTitle>
                        </CardQuestionAvatar>
                        <CardQuestionImage image={item?.image?.url ? parseStrapiImage(item?.image?.url) : null} />
                        <CardQuestionContent>
                            <CardQuestionTitle> { item?.title } </CardQuestionTitle>
                            <RowBetween>
                                <CardQuestionTitle smaller> Assunto: { item?.subject } </CardQuestionTitle>
                            </RowBetween>
                            <CardQuestionRow>
                                <CardQuestionSeed> { optionsLevel?.find(f => f?.id === item?.level)?.title } </CardQuestionSeed>
                                <CardQuestionSeed> { item?.questions?.length } questões </CardQuestionSeed>
                            </CardQuestionRow>
                            <CardQuestionTitle small> { item?.description } </CardQuestionTitle>
                        </CardQuestionContent>
                    </CardQuestionContainer>
                    {/* {
                        !onRemove && !editable ? null :
                        <CardQuestionButtonContainer className="remove-button">
                            {
                                !editable ? null : 
                                    <CardQuestionButton>
                                        <Button nospace small outline primary onClick={() => navigate(`dashboard/creator/lessons/${item?.id}`)}>Editar</Button>
                                    </CardQuestionButton>
                            }
                            {
                                !onRemove ? null : 
                                    <CardQuestionButton>
                                        <Button nospace small outline primary onClick={() => remove(item)}>Remover</Button>
                                    </CardQuestionButton>
                            }
                        </CardQuestionButtonContainer>
                    }  */}
                </CardQuestion>
            </Col>
        </>
    );
}