import React, { useContext, useMemo } from "react";  

import {  
    ButtonQr,
    CardFormActions,
    CardFormButton,
    CardHome,
    CardHomeTitle,
    CardTextRight,
    CheckedImage,
    ContentQr,
    ContentRowInfos,
    ContentRowInfosItem,
    ContentStudentClass,
    ContentStudentClassContent,
    ContentStudentClassImage,
    ContentStudentClassText,
    ContentStudentClasses,
    DashboardOptionsRow,
    DashboardTitle,
    StudentImage,
    StudentRate,
    StudentRow,
    StudentStats
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom";
import Input from "components/Form/Input";
import useController from "./controller";
import CustomButton from "components/Form/CustomButton";
import { CoinImage, DasboardSpacer, RowBetween, Touch } from "ui/styled";
import { coinize, parseGameHome } from "utils/parsers";
import BodyFeedback from "components/Mobile/Feedback";
import { API_ENDPOINT } from "services/api";

import QRCode from "react-qr-code";
import { numerize, parseStrapiImage } from "utils";
import moment from "moment/moment";
import { useState } from "react";
import { CoreContext } from "context/CoreContext";
import CardClassLesson from "../CardClassLesson";

export default function CardClass({ item, studyMode, student, rated }){    

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    
    const { setModal } = useContext(CoreContext)
    const [opened, setOpened] = useState(false)
    
    const openModalLessonResponse = (currentLesson) => {
        if(!studyMode && !currentLesson?.response){

            navigate(`dashboard/creator/lessons/${ currentLesson?.id }`)

            return;
        }
        // console.log("currentLesson", currentLesson) 
        if(currentLesson?.response){
            setModal({
                type:"response",
                currentLesson
            })
        } else {
            setModal({ type:"game", currentLesson: parseGameHome(currentLesson), forfun: !studyMode , student })
        }
    }


    const classMedia = useMemo(() => {
        return parseFloat(parseFloat(((item?.lessons?.reduce((p, c) => p + (c?.response?.media||0), 0)||0) / (item?.lessons?.length))||0).toFixed(1))
    }, [item])

    return ( 
        <>

            <ContentStudentClass onClick={() => setOpened(!opened)}>
                <Touch onClick={!!studyMode ? null : () => navigate(`dashboard/creator/classes/${ item?.id }`)}>
                    <ContentStudentClassImage image={item?.image?.url ? parseStrapiImage(item?.image?.url) : null} />
                </Touch>
                <ContentStudentClassContent flexgrow>
                    <ContentStudentClassText small>Turma</ContentStudentClassText>
                    <ContentStudentClassText><b>{ item?.title }</b></ContentStudentClassText> 
                    <ContentStudentClassText>{ (item?.lessons?.length) } Exercícios</ContentStudentClassText>  
                </ContentStudentClassContent>
                {
                    !rated ? null :
                        <ContentStudentClassContent>
                            <ContentStudentClassText toright small>Nota</ContentStudentClassText>
                            <ContentStudentClassText toright big>{ classMedia } </ContentStudentClassText> 
                            <ContentStudentClassText toright small>{item?.lessons?.filter(f => f?.response)?.length}/{ (item?.lessons?.length) }</ContentStudentClassText>  
                        </ContentStudentClassContent>
                }

            </ContentStudentClass>
            {
                !opened ? null : <>
                    {/* <ContentRowInfos>
                        <ContentRowInfosItem>Exercícios ({item?.lessons?.length})</ContentRowInfosItem> 
                    </ContentRowInfos> */}
                    <CardClassLesson item={item} studyMode={studyMode} student={student} />
                </>
            }                   

        </>
    );
}