import React, { useContext, useEffect, useState } from "react";  

import {   
    CardQuestion,
    CardQuestionButton,
    CardQuestionButtonContainer,
    CardQuestionContent, 
    CardQuestionImage, 
    CardQuestionInfo, 
    CardQuestionTitle, 
} from "./styled";

import { Col } from "reactstrap";

import { useHistory } from "react-router-dom";

import { CoreContext } from "context/CoreContext";

import Button from "components/Form/Button";

import { useDrag } from 'react-dnd'
import { parseStrapiImage } from "utils";
import { RowBetween } from "ui/styled";
import moment from "moment/moment";

export default function CardStudent({ item, editable, onRemove, big, doubleClick }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 

    const {setDragging} = useContext(CoreContext)

    const [{ opacity, isDragging }, dragRef] = useDrag(() => ({
        type: 'card-student', item,
        collect: (monitor) => ({ 
            opacity: monitor.isDragging() ? 0.5 : 1,
            isDragging: monitor.isDragging(),
        })
    }), [item]) 

    const remove = (item) => {
        if(onRemove && typeof onRemove === 'function'){ onRemove(item) ;}
    }

    useEffect(() => { 
        setDragging(isDragging)
    }, [isDragging]) 

    useEffect(() => {
        return () => {
            console.log("close effect")
        }
    }, [])

    return ( 
        <>
            <Col  style={{ opacity }} sm={12} md={big ? 12 : 6} lg={big ? 6 : 3}> 
                <CardQuestion ref={!!onRemove ? null : dragRef}  draggable={!onRemove}>
                    
                    
                    <CardQuestionContent onDoubleClick={doubleClick || onRemove} onClick={!!onRemove || doubleClick ? null : () => navigate(`dashboard/creator/students/${item?.id}`)}>
                        <CardQuestionImage image={ item?.avatar?.url ? parseStrapiImage(item?.avatar?.url) : "/images/student.png" }/>
                        <CardQuestionInfo>
                            <CardQuestionTitle> { item?.name } </CardQuestionTitle>
                            <RowBetween>
                                <div>
                                    <CardQuestionTitle smaller> Média </CardQuestionTitle>
                                    <CardQuestionTitle small> { item?.media || "0" } </CardQuestionTitle>
                                </div>
                                <div>
                                    <CardQuestionTitle smaller> Performance </CardQuestionTitle>
                                    <CardQuestionTitle small> { item?.classification || "F" } </CardQuestionTitle>
                                </div>
                            </RowBetween>
                            {
                                item?.lastaccess ? 
                                <CardQuestionTitle smaller> Último acesso:<br />{ moment(item?.lastaccess)?.format("LLL") } </CardQuestionTitle>
                                    :
                                null
                            }
                        </CardQuestionInfo>
                    </CardQuestionContent>
                   
                   
                   
                   
                    {/* {
                        !onRemove && !editable ? null :
                        <CardQuestionButtonContainer className="remove-button">
                            {
                                !editable ? null : 
                                    <CardQuestionButton>
                                        <Button nospace small outline primary onClick={() => navigate(`dashboard/creator/students/${item?.id}`)}>Editar</Button>
                                    </CardQuestionButton>
                            }
                            {
                                !onRemove ? null : 
                                    <CardQuestionButton>
                                        <Button nospace small outline primary onClick={() => remove(item)}>Remover</Button>
                                    </CardQuestionButton>
                            }
                        </CardQuestionButtonContainer>
                    }  */}
                </CardQuestion>
            </Col>
        </>
    );
}