import React, { useContext, useMemo } from "react";  

import {  
    ButtonQr,
    CardFormActions,
    CardFormButton,
    CardHome,
    CardHomeTitle,
    CardTextRight,
    CheckedImage,
    ContentQr,
    ContentRowInfos,
    ContentRowInfosItem,
    ContentStudentClass,
    ContentStudentClassContent,
    ContentStudentClassImage,
    ContentStudentClassText,
    ContentStudentClasses,
    DashboardOptionsRow,
    DashboardTitle,
    StudentImage,
    StudentRate,
    StudentRow,
    StudentStats
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom";
import Input from "components/Form/Input";
import useController from "./controller";
import CustomButton from "components/Form/CustomButton";
import { CoinImage, DasboardSpacer, RowBetween, Touch } from "ui/styled";
import { coinize, parseCurrencyMoney, parseGameHome } from "utils/parsers";
import BodyFeedback from "components/Mobile/Feedback";
import { API_ENDPOINT } from "services/api";

import QRCode from "react-qr-code";
import { numerize, parseStrapiImage } from "utils";
import moment from "moment/moment";
import { useState } from "react";
import { CoreContext } from "context/CoreContext";

export default function CardClassLesson({ item, studyMode, student, home }){    

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
    
    const { setModal } = useContext(CoreContext)
    const [opened, setOpened] = useState(false)
    
    const openModalLessonResponse = (currentLesson) => {
        if(!studyMode && !currentLesson?.response && !home){
            navigate(`dashboard/creator/lessons/${ currentLesson?.id }`)
            return;
        }
        // console.log("currentLesson", currentLesson) 
        if(currentLesson?.response){
            setModal({
                type:"response",
                currentLesson
            })
        } else {
            setModal({ type:"game", currentLesson: parseGameHome(currentLesson), forfun: !studyMode , student })
        }
    }

    return ( 
        <> 
            <ContentStudentClasses wrapper home={home}>
                {
                    item?.lessons?.map((m, k)=> 
                        <ContentStudentClass  home={home} key={k} card onClick={() => openModalLessonResponse(m)}>
                            <ContentStudentClassImage home={home} full image={m?.image?.url ? parseStrapiImage(m?.image?.url) : null} >
                                <ContentStudentClassText label> 
                                    {
                                        m?.response ? <>

                                            <RowBetween>
                                                {/* <ContentStudentClassText small><b>{ m?.response?.answers?.length } </b> Respostas</ContentStudentClassText> */}
                                                <CheckedImage />
                                                <ContentStudentClassText>Nota <b>{ m?.response?.media }</b></ContentStudentClassText>
                                            </RowBetween>
                                            <ContentStudentClassText small>{ moment(m?.response?.createdAt)?.format("L") } às { moment(m?.response?.createdAt)?.format("HH:mm") }</ContentStudentClassText>
                                            <ContentStudentClassText small>{ m?.response?.answers?.length } respostas</ContentStudentClassText>
                                        </> : <>
                                            <CoinImage /> <big>{ parseCurrencyMoney(m?.coins) }</big> 
                                        </>
                                    }
                                </ContentStudentClassText>
                                {/* {
                                    m?.response ? <>
                                    </> : null
                                } */}
                            </ContentStudentClassImage>
                            <ContentStudentClassContent card>
                                <ContentStudentClassText><b>{ m?.title }</b></ContentStudentClassText>
                                <ContentStudentClassText>{ m?.description }</ContentStudentClassText>
                            </ContentStudentClassContent>
                        </ContentStudentClass>
                    )
                } 
            </ContentStudentClasses>
        </>
    );
}