import styled from 'styled-components' 
import { hexToRgb } from 'ui/styled';

export const MoreInfosItemContent = styled.div.attrs({ 
})`    
    margin-top: 27px; 
    background: ${ props => props.theme.palette.colors.black };
    ${
        props => props.secondary ? `
            background: ${ props.theme.palette.colors.backgrounddark };
        ` : ``
    }
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`; 
 
export const MoreInfosImage = styled.div.attrs({ 
})`    
    width: 100%;
    aspect-ratio: 2 / 3;
    background: rgba(255,255,255,.1) url(${props => props.image}) no-repeat center center / cover;
    padding: 48px 24px 0 0;
`; 
 
 
export const MoreInfosItemContainer = styled.div.attrs({ 
})` 
    padding: 18px 16px;
    background: rgba(${ props => hexToRgb(props.theme.palette.colors.backgrounddark) }, .8);
    display: flex;
    flex-direction: column;
    gap: 18px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
`; 
 

export const MoreInfosItemTitle = styled.div.attrs({ 
})`    
    font-size: 36px;
    line-height: 40px;
    font-weight: 900;
    color: ${ props => props.theme.palette.colors.white };
`; 
 
export const MoreInfosItemText = styled.div.attrs({ 
})`    
    font-size: 18px;
    font-weight: 400;
    color: ${ props => props.theme.palette.colors.white };
`; 
 