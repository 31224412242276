import React, { useEffect, useState } from "react";

import UploadFile from "../UploadFile";
import Toggle from "../Toggle";
import Input from "../Input";
import Button from "../Button";
import { ContentInput, ContentUpload, ContentUploadPlaceholder, ContentUploadText } from "./styled";
import { parseStrapiImage } from "utils";

export default function ImagedInput({ placeholder, value, onChange, onlyImage }){

    const [beginned, setBeginned] = useState(false)
    const [isImage, setIsImage] = useState(!!value?.url || onlyImage )

    useEffect(() => {
        // console.log("value", value)
        if(!beginned && !!value?.url){
            setBeginned(true)
            setIsImage(true)
        }
    }, [value])

    return (
        <>
            <ContentInput>
                { onlyImage ? null : <Toggle checked={isImage} label={`Imagem`} onChange={(v) => setIsImage(v)} /> }
                {
                    isImage ? <>
                        <ContentUploadPlaceholder>{ placeholder }</ContentUploadPlaceholder>
                        <UploadFile onChange={value => onChange({ target: { value } })}>
                            <ContentUpload image={value?.url ? parseStrapiImage(value?.url) : null}>
                                {
                                    value?.url ? null :
                                    <ContentUploadText>Clique ou arraste uma imagem aqui</ContentUploadText>
                                }
                            </ContentUpload>
                        </UploadFile>
                    </> : 
                    <Input type="textarea" placeholder={placeholder} value={value} onChange={onChange} />
                }
            </ContentInput>
        </>
    )
}