import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeDecoration,
    CardHomeTitle,
    CardQuestion,
    CardQuestionButton,
    CardQuestionButtonContainer,
    CardQuestionDecoration,
    CardQuestionIcon,
    CardQuestionIconDecoration,
    CardQuestionSeed,
    CardQuestionTitle,
    ContentFilter,
    ContentFilterContainer,
    ContentFilterContent,
    ContentFilterSeed,
    ContentFilterTitle,
    DashboardTitle,
    FullLineFilter,
    FullLineFilterInput,
    ImageSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Delete, ReadByUser } from "services/questions";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils";
import { mockedQuestions } from "utils/options";
import Button from "components/Form/Button";
import { BetweenRow, LoadingCookies, MaxButton } from "ui/styled";
import { toast } from "react-toastify";
import { modalityColors } from "ui/theme-color";
import Input from "components/Form/Input";
import DashboardCardQuestion from "components/Cards/CardQuestion";
import CardFilterModality from "components/Cards/CardFilterModality";
import CustomButton from "components/Form/CustomButton";

export default function DashboardContentorTasks(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [questions, setQuestions] = useState([]) 
    const [loading, setLoading] = useState(false)  

    const [filterModality, setFilterModality] = useState(null)
    const [filterSeed, setFilterSeed] = useState(null)
    
    const [searchSeed, setSearchSeed] = useState("")

    const init = async () => {
        setLoading(true)
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)

            const nextQuestions = normalResult
                ?.map( m => {
                    const nimage = normalizeStrapiRegister(m?.image)
                    return ({
                        ...m,
                        question: nimage?.url ? nimage : m?.question
                    })
                })?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() )

            setQuestions(nextQuestions)
        }
        setLoading(false)
    }

    const remove = async (item) => {
        setLoading(true)
        const result = await Delete(item?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            init()
        }
        setLoading(false)
    }

    const parseTitle = (type) => {
        return mockedQuestions?.find(f => f.type === type)?.title
    }

    const filterQuestions = f => {
        return (
            ( !filterModality || f?.type === filterModality ) &&
            ( !filterSeed || f?.seed === filterSeed?.seed ) 
        )
    }

    const filterSeeds = f => {
        return (
            ( !filterModality || f?.type === filterModality ) &&
            ( !searchSeed || f?.seed?.toLowerCase()?.indexOf(searchSeed?.toLowerCase()) !== -1) 
        )
    }

    useEffect(() => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated>  

                {
                    loading ? <LoadingCookies /> : <>
                        <BetweenRow>
                            <DashboardTitle>Minhas questões</DashboardTitle>
                            <MaxButton>
                                <CustomButton onClick={() => navigate("dashboard/creator/tasks")}>Criar Tarefa</CustomButton>
                            </MaxButton>
                        </BetweenRow>

                            

                        <Row>
                            <Col sm={12} md={12}>
                                <Row>
                                        {
                                            questions?.filter(filterQuestions)?.map((item, key) => 
                                                <DashboardCardQuestion key={key} item={item} editable />  
                                            )
                                        }
                                </Row>
                            </Col>
                            {/* <Col sm={12} md={3}>
                                {
                                    !questions?.length ? null : 
                                        <ContentFilterContainer>
                                            <CardFilterModality filter={filterModality} setFilter={setFilterModality} />
                                            <ContentFilter seeded>
                                                <FullLineFilter>
                                                    <ContentFilterTitle>
                                                        Filtre pelas sementes
                                                    </ContentFilterTitle>
                                                    <FullLineFilterInput>
                                                        <MaxButton white>
                                                            <Input small placeholder="Filtre as sementes" value={searchSeed} onChange={e => setSearchSeed(e.target.value)} />
                                                        </MaxButton>
                                                    </FullLineFilterInput>
                                                </FullLineFilter>
                                                <ContentFilterContent seeded>
                                                    {
                                                        [...questions]?.filter(f => !!f.seed)
                                                            ?.reduce((p, c) => p?.map(m => m.seed)?.includes(c?.seed) ? [ ...p ] : [ ...p, c ] , [])
                                                            ?.filter(filterSeeds)?.map(item => 
                                                            <ContentFilterSeed  onClick={() => setFilterSeed( filterSeed?.seed === item?.seed ? null : item)} active={filterSeed?.seed === item?.seed}  >
                                                                { item?.seed }
                                                            </ContentFilterSeed>    
                                                        )
                                                    }
                                                </ContentFilterContent>
                                            </ContentFilter>
                                        </ContentFilterContainer>
                                }
                            </Col> */}
                        </Row>
                    </>
                }
    
            </ContainerAuthenticated> 
        </>
    );
}