import React, { useEffect, useState } from 'react' 

import { 
    MemoryImage,
    ImageIn,
    CoverIn,
    CoverOut,
    MemoryCover
} from './styled'

import { 
    Touch,
    Icon
} from 'ui/styled'

export default props => {   
    
    const [first, setFirst] = useState(true)
    const [see, setSee] = useState(false)

    const reveal = () => {
        if(!see){
            setSee(true)
            if(props.onClick){ props.onClick() ;}
        }
    } 
    
    useEffect(() => { if(props.wrong){ setSee(false) ;} }, [props.wrong])
    useEffect(() => { if(props.source){ setSee(false) ;} }, [props.source])
    useEffect(() => { setFirst(false) ;}, [])

    return (
        <>     
            <Touch onClick={() => reveal()}> 
                <ImageIn delay={props.delay}>
                    <MemoryImage source={ props.source } small={props.small}>
                        {
                            see ? 
                                <CoverOut>
                                    <MemoryCover>
                                        <Icon icon={"casino"} width={32} height={46} />
                                    </MemoryCover>
                                </CoverOut>
                                    : 
                                <CoverIn delay={ first ? 4000 : 300 }>
                                {/* <CoverIn delay={ 4000 + props.delay }> */}
                                    <MemoryCover>
                                        <Icon icon={"casino"} width={32} height={46} />
                                    </MemoryCover>
                                </CoverIn>
                        }
                    </MemoryImage>
                </ImageIn>
            </Touch> 
        </>
    )
}