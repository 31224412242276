import React, { useContext, useState } from "react"; 
import { useHistory } from 'react-router-dom'; 
import { toast } from 'react-toastify';


import { 
    FormTitle,
    FormText,
    RegisterButton,
    FormSteperCheck,
    FormSteperDecoration,
    FormSteperDecorationLine,
    FormSteperDecorationContent,
    AnimateChilds,
    FormSteperSuccess,
    FormSteperSuccessContainer
} from './styled'

import ContainerUnauthenticated from "containers/Unauthenticated";

import CustomButton from "components/Form/CustomButton";
import { useEffect } from "react";

export default function StripeSuccess(){ 

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 

    const [ loading, setLoading ] = useState(false)   

    const next = () => {
        navigate("login") 
    }

    useEffect(() => {
        toast.success("Sucesso")
    }, [])
 
    return ( 
        <>  
            <ContainerUnauthenticated keep> 

                <AnimateChilds> 
                        
                        <FormText> </FormText> 
                        
                        <FormSteperSuccessContainer>
                            <FormSteperSuccess>
                                <FormSteperCheck big /> 
                            </FormSteperSuccess>
                        </FormSteperSuccessContainer>

                        <FormSteperDecoration spaced>
                            <FormSteperDecorationContent>
                                <FormTitle>Tudo certo</FormTitle>
                                <FormSteperDecorationLine />
                            </FormSteperDecorationContent>
                            <FormTitle centred spaced>sua assinatura foi realizada com sucesso</FormTitle>
                        </FormSteperDecoration> 

                        <RegisterButton centred >
                            <CustomButton loading={loading} onClick={next} color={"green"}>
                                Entrar
                            </CustomButton>
                        </RegisterButton>

                </AnimateChilds>
                
            </ContainerUnauthenticated> 
        </>
    );
}