import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeDecoration,
    CardHomeTitle,
    CardQuestion,
    CardQuestionButton,
    CardQuestionButtonContainer,
    CardQuestionContent,
    CardQuestionDecoration,
    CardQuestionIcon,
    CardQuestionIconDecoration,
    CardQuestionRow,
    CardQuestionSeed,
    CardQuestionTitle,
    ContentFilter,
    ContentFilterContainer,
    ContentFilterContent,
    ContentFilterSeed,
    ContentFilterTitle,
    DashboardTitle,
    FullLineFilter,
    FullLineFilterInput,
    ImageSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom";
import { Delete, ReadByUser } from "services/lessons";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils"; 
import Button from "components/Form/Button";
import { BetweenRow, Load, LoadCenter, LoadingCookies, MaxButton } from "ui/styled";
import { toast } from "react-toastify"; 
import { optionsLevel } from "utils/options";
import CardLesson from "components/Cards/CardLesson";
import CustomButton from "components/Form/CustomButton";

export default function DashboardContentLessons(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [questions, setQuestions] = useState([]) 
    const [loading, setLoading] = useState(false)  
    const [filterSeed, setFilterSeed] = useState(null)
 

    const init = async () => {
        setLoading(true)
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)

            const nextQuestions = normalResult
                ?.map( m => {
                    return ({
                        ...m,
                        questions: normalizeStrapiList(m?.questions),
                        image: normalizeStrapiRegister(m?.image)
                    })
                })?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() )
            
            // console.log("nextQuestions", nextQuestions)
            
            setQuestions(nextQuestions)
        }
        setLoading(false)
    }

    const filterLessons = f => {
        return (
            ( !filterSeed || f?.subject === filterSeed?.subject ) 
        )
    }

    const remove = async (item) => {
        setLoading(true)
        const result = await Delete(item?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            init()
        }
        setLoading(false)
    } 

    useEffect(() => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated>  

                {
                    loading ? <LoadingCookies /> :  <>
                        <BetweenRow>
                            <DashboardTitle>Meus exercícios</DashboardTitle>
                            <MaxButton>
                                <CustomButton onClick={() => navigate("dashboard/creator/lessons")}>Criar Exercício</CustomButton>
                            </MaxButton>
                        </BetweenRow>    

                        <Row>
                            <Col sm={12} md={12}>
                                <Row>
                                    {
                                        questions?.filter(filterLessons)?.map((item, key) => 
                                            <CardLesson key={key} editable item={item} />  
                                        )
                                    }
                                </Row>
                            </Col>
                            {/* <Col sm={12} md={3}>
                                {
                                    !questions?.length ? null : 
                                        <ContentFilter seeded> 
                                                <FullLineFilter>
                                                    <ContentFilterTitle>
                                                        Filtre pelos assuntos
                                                    </ContentFilterTitle> 
                                                </FullLineFilter>
                                            <ContentFilterContent seeded>
                                                {
                                                    [...questions]?.filter(f => !!f.subject)
                                                        ?.reduce((p, c) => p?.map(m => m.subject)?.includes(c?.subject) ? [ ...p ] : [ ...p, c ] , [])?.map(item => 
                                                        <ContentFilterSeed  onClick={() => setFilterSeed( filterSeed?.subject === item?.subject ? null : item)} active={filterSeed?.subject === item?.subject}  >
                                                            { item?.subject }
                                                        </ContentFilterSeed>    
                                                    )
                                                }
                                            </ContentFilterContent>
                                        </ContentFilter>
                                }
                            </Col> */}
                        </Row>
                    </>
                }
    
            </ContainerAuthenticated> 
        </>
    );
}