import styled from "styled-components";

export const CompanyButtonContainer = styled.div.attrs({
})`
    width: 100%;
    display: flex;
    gap: 16px;
    border-top: 1px solid ${props => props.theme.palette.colors.border};
    padding-top: 24px;
    justify-content: flex-end;
`;

export const CloseContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;



export const WrapperCovers = styled.div.attrs({
})`
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0 12px;
    gap: 24px;
    justify-content: center;
`;

export const ImageCoverImage = styled.div.attrs({
})`
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background: ${props => props.theme.palette.colors.lightshadow} url(${ p => p.image }) no-repeat center center / cover; 

    cursor:pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }

    ${
        p => p.active ? `
            border: 4px solid ${p.theme.palette.colors.backgrounddark};
        ` : ``
    }
`;