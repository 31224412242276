import React, { useContext, useState } from "react"; 
import { useHistory } from 'react-router-dom'; 
import { toast } from 'react-toastify';


import { 
    FormTitle,
    FormText,
    FormSpacer,
    RegisterCall,
    RegisterButton,
    FormSubtitle,
    FormSteper,
    FormSteperItem,
    FormSteperButton,
    RowForm,
    FormSupertext,
    FormSteperCheck,
    FormSteperDecoration,
    FormSteperDecorationLine,
    FormSteperDecorationContent,
    AnimateChilds,
    FormSteperSuccess,
    FormSteperSuccessContainer
} from './styled'

import Button from "components/Form/Button";
import Input, { MaskedInput } from 'components/Form/Input';

import ContainerUnauthenticated from "containers/Unauthenticated";
import { DoLogin, DoRegister } from "services/authentication";
import { exposeStrapiError, goSection, normalizeStrapiList, pickRandom } from "utils"; 
import PasswordValidation from "components/Form/PasswordValidation";

import { isEmail, isPassword } from "utils/validators";
import { CoreContext } from "context/CoreContext";

import { ReadActivePlan } from "services/plans";

import CustomButton from "components/Form/CustomButton";
import { CreateSubscribe } from "services/stripe";

export default function Register({ flat }){ 

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { covers, reloadMe } = useContext(CoreContext)

    const [ loading, setLoading ] = useState(false) 
    
    const steps = [
        { title:"Dados pessoais", label:"Continuar" },
        { title:"Dados de acesso", label:"Continuar e Assinar" },
        { title:" ", label:"Acessar plataforma" },
    ]

    const [ step, setStep ] = useState(0) 
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const valid = (verbose = false) => {  

        if(step === 0){
            if(!formValue('name') || !formValue('name').length){ 
                if(verbose){ toast.error('Informe seu nome') ;}
                return false; 
            }  
    
            if(!formValue('email') || !formValue('email').length){ 
                if(verbose){ toast.error('Informe seu e-mail') ;}
                return false; 
            }  
    
            if(! isEmail(formValue('email')) ){ 
                if(verbose){ toast.error('O e-mail informado é inválido') ;}
                return false; 
            } 

            if(!formValue('matter') || !formValue('matter').length){ 
                if(verbose){ toast.error('Informe o que você ensina') ;}
                return false; 
            } 
        }

        if(step === 1){
            if(!formValue('password') || !formValue('password').length){ 
                if(verbose){ toast.error('Informe sua senha') ;}
                return false; 
            }
            if(! isPassword(formValue('password')) ){ 
                if(verbose){ toast.error('A senha informada não atende os requisitos') ;}
                return false; 
            }
        }  

        return true
    } 

    const action = async () => {
        setLoading(true)
        
        const sortedCover = pickRandom(covers?.filter(f => f.type === "teacher"))

        const result = await DoRegister({
            ...form, 
            username: form.email?.replace(/ /g,''),
            email: form.email?.replace(/ /g,''),
            confirmed:true,
            blocked:false,
            image: sortedCover?.image?.id,
            role:1
        })  
        
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            completeLogin()
        } 
    }

    const completeLogin = async () => {
        setLoading(true)
        toast.success('Conta criada com sucesso'); 
        const result = await DoLogin({ password: form?.password, identifier: form.email?.replace(/ /g,'') })  
        if(result && !exposeStrapiError(result)){
            const plans = await ReadActivePlan()
            const activePlan = (normalizeStrapiList(plans))?.[0]
    
            if(!activePlan?.id){
                toast.error("Plano não encontrado, tente novamente mais tarde")
            } else {
                const striped = await CreateSubscribe({
                    plan: activePlan?.id,
                    price_id: activePlan?.stripe_price_id
                })
                if(!exposeStrapiError(striped) && striped?.checkoutUrl){
                    window.open(striped?.checkoutUrl, "royal-charge")
                }
            }

            recompleteLogin()
        } 
        setLoading(false)
    } 

    const recompleteLogin = async () => {
        await reloadMe()
        setStep( step + 1)
    }  

    const next = () => {
        if(!valid(true)){ return ;}
        goSection("form-sign")
        setTimeout(() => {    
            if(step === 0) { 
                setStep( step + 1 )
                return;
            }
            if(step === 1) { 
                action()
                return;
            }
            if(step === 2) { 
                navigate("login")
                return;
            }
        }, 150)
    }
 
    return ( 
        <>  
            <ContainerUnauthenticated flat={flat}> 

                <AnimateChilds>
                    
                        {
                            step === 2 ? null : <>
                                <FormTitle>Cadastre-se agora</FormTitle>
                                <FormText> e aproveite todas as vantagens da nossa plataforma </FormText> 
                                <FormSubtitle>Transforme suas aulas com a Royal Academy!</FormSubtitle>
                                <div id="form-sign" />
                                <FormSteper>
                                    {
                                        steps.map((item, key) => 
                                            <FormSteperItem key={key} done={step > key} active={step === key} onClick={step > key ? () => setStep(key) : null} />
                                        )
                                    }
                                </FormSteper>
                            </>
                        }
                        
                        <FormText>{ steps?.[step]?.title }</FormText>

                        {
                            step === 0 ? <>
                                <Input placeholder="Nome" id={'name'} value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')} white /> 
                                <FormSpacer />
                                <Input placeholder="E-mail" id={'email'} value={formValue('email')} onChange={e => changeForm(e.target.value, 'email')} white /> 
                                <FormSpacer />
                                <Input placeholder="O que você ensina?" id={'matter'} value={formValue('matter')} onChange={e => changeForm(e.target.value, 'matter')} white /> 
                                <FormSpacer />
                            </> : null
                        }

                        {
                            step === 1 ? <>
                                <Input white placeholder="Senha" id={'password'} type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')}  />    
                                <FormSpacer /> 
                                <PasswordValidation password={form?.password} />
                            </> : null
                        }


                        {
                            step === 2 ? <> 
                                <FormSteperSuccessContainer>
                                    <FormSteperSuccess>
                                        <FormSteperCheck big /> 
                                    </FormSteperSuccess>
                                </FormSteperSuccessContainer>
                                <FormSteperDecoration spaced>
                                    <FormSteperDecorationContent>
                                        <FormTitle>Parabéns</FormTitle>
                                        <FormSteperDecorationLine />
                                    </FormSteperDecorationContent>
                                    <FormTitle spaced>sua conta foi criada</FormTitle>
                                    <FormText big>Bem vindo ao futuro! Agora você é um membro dessa revolução e faz parte do maior inovação tecnológica na história da educação</FormText>
                                </FormSteperDecoration>
                            </> : null
                        }

                        <RegisterButton centred={step === 2}>
                            <CustomButton loading={loading} onClick={next} color={step === 2 ? "green" : "backgroundblack"}>
                                { steps?.[step]?.label }
                            </CustomButton>
                        </RegisterButton>

                        {/* 
                            <RegisterCall> Já possui uma conta? </RegisterCall>
                            <Button primary outline onClick={() => navigate('login')}>Faça o login</Button> 
                        */}
                </AnimateChilds>
                
            </ContainerUnauthenticated> 
        </>
    );
}