import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
import { useHistory } from 'react-router-dom';

import {
    FooterContainer,
    FooterTitle,
    FooterLink,
    FooterSection,
    FooterCopyright,
    ContainerAppLogo,
    AppLogo,
    ImageButton,
    SubFooter,
    SubFooterIcon,
    ImageButtonContainer
} from './styled' 
import { scrollTop } from "utils";
import { toast } from "react-toastify";

export default function Footer({ land }){ 
 
    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);


    return ( 
        <> 
            <FooterContainer>
                <Container>
                    <Row>
                        <Col md={{size:5}}>
                            <ImageButtonContainer>
                                <ImageButton onClick={() => toast.info("Em breve!")} src={"/images/apple-store.png"} />
                                <ImageButton onClick={() => window.open("/royal-academy.apk", "royal-download")} src={"/images/play-store.png"} />
                            </ImageButtonContainer>
                        </Col>
                        <Col md={{size:2}}>
                            <ContainerAppLogo onClick={scrollTop}>
                                <AppLogo />
                            </ContainerAppLogo>
                        </Col>
                        <Col md={{size:5}}>
                            <Row>
                                <Col md={{size:6}}></Col>
                                <Col md={{size:6}}> 
                                    <FooterSection>
                                        <FooterTitle>&nbsp;</FooterTitle>
                                        <FooterLink onClick={ land ? scrollTop : () => navigate('') }>Inicio</FooterLink>
                                        <FooterLink onClick={() => navigate('about')}>Sobre</FooterLink>
                                        <FooterLink onClick={() => navigate('terms')}>Termos de uso</FooterLink>
                                        <FooterLink onClick={() => navigate('privacity')}>Políticas de privacidade</FooterLink>
                                        {/* <FooterLink onClick={() => navigate('faq')}>Perguntas frequentes</FooterLink> */}
                                        {/* <FooterLink onClick={() => navigate('contact')}>Contato</FooterLink> */}
                                        <FooterLink onClick={() => navigate('help')}>Ajuda</FooterLink>
                                    </FooterSection>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FooterCopyright>RoyalAcademy @ 2025</FooterCopyright> 
                        </Col>
                    </Row>
                </Container>
            </FooterContainer>
            <SubFooter>
                <SubFooterIcon onClick={() => window.open("https://unicorntechhouse.com/", "new")}  />
            </SubFooter>
        </>
    );
}