import styled from 'styled-components'

import { WindowScreen } from 'ui/styled'

export const ImageAnimation = styled.div.attrs({
    animation:'bounceIn'
})`  
`; 

export const ButtonImageContent = styled.div.attrs({
    resizeMode: 'cover'
})`
    background: url(${ p => p.source?.uri }) no-repeat center center / cover;
    margin-top: 20px;
    width: ${ WindowScreen.width * .4 }px;
    height: ${ WindowScreen.width * .4 }px;
    border-radius: 5px;
    overflow: hidden;
`;

