import React, { useContext } from 'react'

import { CloseContainer, CompanyDataContainer } from './styled'
import { Icon, ModalContainer, ModalContent, ModalContentParent, Overlay, SmartphoneFrame } from 'ui/styled'

import { CoreContext } from 'context/CoreContext'

export default function Wrapper({ children, big, mobile }) {

  const { setModal, superModal, setSuperModal } = useContext(CoreContext)

  const close = () => { 
    if(superModal){
      setSuperModal(null) ;
      return;
    }
    setModal(null) ;
  } 

  const handleClose = (e) => {
    const mc = document.getElementById('modal-content');
    // console.log("close", mc.contains(e?.target), e?.target)
    if (!mc?.contains(e?.target) && !(e?.target.tagName === 'LI')) {
      close()
    }
  }

  const MaybeMobile = !!mobile ? SmartphoneFrame : ModalContentParent
  
  return (
    <>
      <Overlay onClick={handleClose} >
        <ModalContainer>
          <MaybeMobile big={big}>

            <ModalContent id="modal-content" big={big}>
              <CompanyDataContainer>
                <CloseContainer>
                  <Icon icon={'close'} pointer={true} onClick={close} />
                </CloseContainer>
                {children}
              </CompanyDataContainer>
            </ModalContent>

          </MaybeMobile>
        </ModalContainer>
      </Overlay>
    </>
  )
}
