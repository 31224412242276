import { createTheme } from '@mui/material/styles'; 
import { ReadObject } from 'services/storage';

const user = ReadObject("user")

export const royalThemes = [
  { id:"black", background: "#262626", contrast:"#f68807" },
  { id:"blue", background: "#027cba", contrast:"#fff201" },
  { id:"green", background: "#287b3e", contrast:"#faf9e7" },
  { id:"red", background: "#ed3237", contrast:"#262626" },
  { id:"purple", background: "#6b3185", contrast:"#d51c58" },
  { id:"pink", background: "#d51c58", contrast:"#e6476d" },
  { id:"pinkness", background: "#e6476d", contrast:"#6b3185" },
  { id:"greeness", background: "#95be28", contrast:"#059377" },
  { id:"orange", background: "#e9654c", contrast:"#f68807" },
]

const sortedThemes = [
  ...royalThemes
].sort(() => Math.random() - 0.5)?.[0]

const randomTheme = user?.theme ? (royalThemes.find(f => f.id === user?.theme ) || sortedThemes) : sortedThemes

export const modalityColors = {
  "content": "#287b3e",
  "multichoice": "#f68807",
  "rightwrong": "#ed3237",
  "association": "#fff201",
  "memory": "#95be28",
  "crossword": "#d51c58",
  
  // "gibbet": "#6b3185",
  // "contentvideo": "#027cba",
  // "default": "#262626"
}
 
export let theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#262626', 
      contrastText: '#fff',
    },
    secondary: {
      main: '#f8ca0c',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      light: '#ffffff',
      dark: '#ffffff',
      contrastText: '#f68807',
    },
    whited: {
      main: randomTheme?.background,
      contrastText: '#fff',
    },
    error: {
      main: '#ed3237',
    },
    warning: {
      main: '#117FA2',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    success: {
      main: '#2CB26A',
    },
    colors:{
        primary: '#f68807',
        white: '#ffffff',
        black: '#000000',
        grey: '#4C4C4C',
        text: '#4C4C4C',
        green: '#287b3e',
        yellow: '#f8ca0c',
        blue: "#027cba",
        red: '#ed3237',
        backgroundcontrast: randomTheme?.contrast,
        backgroundblack: '#262626',
        backgrounddark: randomTheme?.background,
        backgroundgrey: '#FAFAFA',
        lightgrey: '#ccc',
        shadow: 'rgba(0,0,0,.16)',
        darkshadow: 'rgba(0,0,0,.32)',
        obscure: 'rgba(0,0,0,.64)',
        whiteshadow: 'rgba(255,255,255,.06)',
        whiteshadowness: 'rgba(255,255,255,.12)',
        border: 'rgba(255,255,255,.12)',
        lightshadow: 'rgba(112,112,112,.06)'
    }
  },
});  