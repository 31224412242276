import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function PolarChart({ students }){
    
    const [visible, setVisible] = useState(false)

    var options = {
        series: students?.map(m => m.media || 0 ),
        chart: { type: 'polarArea' },
        stroke: { colors: ['#fff'] },
        fill: { opacity: 0.8 },
        labels: students?.map(m => m.name || ""),

        title: { text: 'Médias da turma' },
        legend: {
            position: 'bottom'
          },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: { width: 200 }
            }
        }]
    };

    useEffect(() => {
        const suspense = setTimeout(() => { setVisible(true) ;}, 400)
        return () => { clearTimeout(suspense) ; }
    })
 
    return !visible ? null : ( 
        <>
            <ReactApexChart options={options} series={options.series} type="polarArea" height={350} />
        </>
    );
  }