import React, { useEffect, useState } from 'react'

import {     
    SimpleRow,
    AnimationCenter
} from 'ui/styled'

import { 
    Container,
    Title,
    Text,
    RankingItemInfo,
    CircleImage,
    RowVCenter,
    FeedbackIcon,
    ContainerAsk,
    ContainerAnswer,
    ContainerAnswerWrapper,
    DescriptionAnimation,
    LabelText
} from './styled'

import Association from 'components/Game/Modes/Association' 
import Crosswords from 'components/Game/Modes/Crosswords';
import Memory from 'components/Game/Modes/Memory' 

// import { Icon } from 'ui/styled'

import {
    booleanize
} from 'utils'

// import { theme } from 'ui/theme-color';

export default function BodyFeedbackDetails(props){ 
    
    return (
        <> 
            <Container>      
                <RowVCenter>
                    <RankingItemInfo /> 
                    <LabelText>
                        <Text nobottom bold>{ props?.feedback?.name }</Text>
                    </LabelText>
                </RowVCenter>
                <RowVCenter>
                    <RankingItemInfo>
                        <SimpleRow> 


                            { props?.feedback?.game_tag ? <Title big centred> { props?.feedback?.game_tag } </Title> : null }
                            { props?.feedback?.game_image ? <CircleImage big source={props?.feedback?.game_image} /> : null }

                        
                        </SimpleRow>
                    </RankingItemInfo>  
                </RowVCenter>

                <ContainerAsk />
                {
                    (props?.feedback?.correct_tag || props?.feedback?.type === 'rightwrong' || props?.feedback?.question.feedback) ? 
                        <SimpleRow> 
                            <FeedbackIcon>
                                {/* <Icon icon={ "check" } width={20} height={20} fill={ theme.palette.colors.green } />  */}
                            </FeedbackIcon> 
                            {
                                props?.feedback?.type === 'rightwrong' ?
                                <Title> { booleanize(props?.feedback?.question.answer) } </Title>
                                : props?.feedback?.correct_tag?.image ?
                                    <CircleImage source={ props?.feedback?.correct_tag?.image } />
                                :
                                    <Title> { props?.feedback?.correct_tag?.answer } </Title>
                            }
                        </SimpleRow> 
                    : null
                }
                <ContainerAsk mtop>
                    <Text black>
                        { props?.feedback?.question.feedback }
                    </Text>
                </ContainerAsk>

                <ContainerAnswer>
                    <Text>Você {props?.feedback?.answer?.skip ? 'não ' : ''}respondeu</Text>
                    {
                        props?.feedback?.answer?.skip ? 
                            <Title spaced> </Title>
                        : props?.feedback?.answer?.type === 'association' ? 
                            <ContainerAnswerWrapper>
                                <Association feedback={props?.feedback?.answer} />  
                            </ContainerAnswerWrapper>
                        : props?.feedback?.answer?.type === 'memory' ? 
                            <ContainerAnswerWrapper>
                                <Memory feedback={props?.feedback?.answer} />  
                            </ContainerAnswerWrapper>
                        : props?.feedback?.answer?.crosswords ?
                            <>
                                <Crosswords answer={ props?.feedback?.answer?.crosswords } />
                            </>
                        : props?.feedback?.an_answer ?
                            <Title spaced> { props?.feedback?.an_answer } </Title>
                        : props?.feedback?.an_image ?
                            <CircleImage source={ props?.feedback?.an_image } spaced />
                        : 
                            <Title spaced> { booleanize(props?.feedback?.correct) } </Title>
                    } 
                    <AnimationCenter>
                        {
                            props?.feedback?.correct ? 
                            <DescriptionAnimation source={require('assets/lotties/app/check.json')} />
                                :
                            <DescriptionAnimation source={require('assets/lotties/app/error.json')} />
                        }
                    </AnimationCenter>
                </ContainerAnswer>

            </Container>
        </>
    )
}