import React from 'react'

import { 
    Spacer,
    Question, 
    QuestionImage,
    Row 
} from 'ui/styled'

import Button from 'components/Form/CustomButton'   

export default function RightWrong(props) { 

    const options = [
        { answer:'Verdadeiro', response:true }, 
        { answer:'Falso', response:false }
    ]

    const selectAnswer = (answer) => {
        if(props.response){
            props.response({
                question: props.question,
                answer: { correct: !!answer.response === !!props.question.answer }
            })
        }
    }

    return (
        <> 
            { props.question?.question ? <Question>{ props.question.question }</Question> : null }
            { props.question?.image ? <QuestionImage source={ props.question?.image } /> : null }
            
            <Spacer />
            <Row>
                {
                    options.map((mit, mik) => 
                        <Button key={mik} delay={( 600 + ( 300 * mik ) )} onClick={() => selectAnswer(mit)} row >{ mit.answer }</Button>
                    )
                }  
            </Row>
        </>
    )
}