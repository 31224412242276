import React, { useCallback, useContext, useEffect, useState } from "react";  

import {   
    DashboardText, DragContent, DropContent, DropContentText, DropableContent
} from "./styled";

import { Row, Col } from "reactstrap";

import { useDrop } from "react-dnd";
import { CoreContext } from "context/CoreContext";

import CardStudent from "components/Cards/CardStudent"; 
import CustomButton from "components/Form/CustomButton";

import { Title } from "ui/styled";

export default function DraggerStudents({ users, selectedUser, setSelectedUser, navigate }){   
    
    const { dragging, setDragging, setModal } = useContext(CoreContext) 

    const [{ isOver:isOverUser }, dropUser] = useDrop(() => ({
        accept: 'card-student',
        drop: (item) => addUser(item),
        collect: (monitor) => ({
          isOver: monitor.isOver(), 
          canDrop: monitor.canDrop(), 
        })
    }),[selectedUser]);

    const addUser = useCallback(item => {
        setSelectedUser([ ...selectedUser, item ])
        setDragging(false)
    }, [selectedUser])

    const removeUser = useCallback(item => {
        setSelectedUser([ ...selectedUser?.filter(f => f?.id !== item?.id) ])
    }, [selectedUser])

    const filterUsers = f => {
        return (
            (!selectedUser?.length || !selectedUser?.map(m => m?.id)?.includes(f?.id))
        )
    }

    return ( 
        <>   
            {
                !users?.length ? <>
                    <Title></Title>
                    <DashboardText>Crie alunos para adiciona-los à turma</DashboardText>
                    <CustomButton onClick={() => {navigate("dashboard/creator/students");setModal(null)}}>Criar aluno</CustomButton>
                </> : 
                <Row>
                    <Col sm={6}> 
                        <DashboardText>Arraste os alunos para adiciona-las à turma</DashboardText>
                        <DragContent>
                            <Row>
                                    {
                                        users?.filter(filterUsers)?.map((item, key) => 
                                            <CardStudent key={key} item={item} big doubleClick={() => addUser(item)} />  
                                        )
                                    }
                            </Row>
                        </DragContent>
                    </Col>

                    <Col sm={6}> 
                        <DashboardText>Alunos adicionadas ({selectedUser?.length})</DashboardText>
                        {
                            !dragging && selectedUser?.length ? <>
                                <DropableContent>
                                    <Row>
                                            {
                                                selectedUser?.map((item, key) => 
                                                    <CardStudent key={key} item={item} big onRemove={() => removeUser(item)} />  
                                                )
                                            }
                                    </Row>
                                </DropableContent>
                            </> : 
                                <DropContent ref={dropUser} isOver={isOverUser}>
                                    <DropContentText>
                                        { isOverUser ? "Solte o aluno aqui para adiciona-lá" : "Arraste os alunos aqui para adiciona-los à sua turma" }
                                    </DropContentText>
                                </DropContent>
                        }
                    </Col>
                </Row>   
            }
        </>
    );
}