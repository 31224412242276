import React, { useCallback, useContext, useEffect, useState } from "react";  

import {   
    RowStudentContainer,
    RowStudentImage,
    RowStudentContent,
    RowStudentContentTitle,
    RowStudentContentInfos,
    RowStudentContentInfosContent,
    RowStudentContentInfosContentTitle,
    RowStudentContentInfosContentText,
    RemoveIcon,
} from "./styled";

import CustomButton from "components/Form/CustomButton";

import { parseStrapiImage } from "utils";
import { Touch } from "ui/styled";
import { useHistory } from "react-router-dom";
import { mockedQuestions, optionsStates } from "utils/options";

export default function RowTask({ item, remove }){   

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);
 

    return ( 
        <>
            <RowStudentContainer>
                <Touch onClick={() => navigate(`dashboard/creator/game/${ item?.type }/${ item?.id }`)}>
                    <RowStudentImage image={item?.question?.url ? parseStrapiImage(item?.question?.url) : mockedQuestions?.find(f=>f.type === item?.type)?.image} />
                </Touch>
                <RowStudentContent grow>
                    <RowStudentContentTitle>{ mockedQuestions?.find(f=>f.type === item?.type)?.title }</RowStudentContentTitle>
                    <RowStudentContentInfosContentTitle maxed>{ item?.question?.url ? null : item?.question?.substring(0,144) }</RowStudentContentInfosContentTitle>
                    <RowStudentContentInfos>
                        <RowStudentContentInfosContent>
                            <RowStudentContentInfosContentTitle>Tempo</RowStudentContentInfosContentTitle>
                            <RowStudentContentInfosContentText>{ item?.time || 0 }s</RowStudentContentInfosContentText>
                        </RowStudentContentInfosContent>
                        <RowStudentContentInfosContent>
                            <RowStudentContentInfosContentTitle>Seed</RowStudentContentInfosContentTitle>
                            <RowStudentContentInfosContentText>{ item?.seed || "" }</RowStudentContentInfosContentText>
                        </RowStudentContentInfosContent>
                    </RowStudentContentInfos>
                </RowStudentContent>
                <RowStudentContent>
                    <CustomButton small onClick={remove}>
                        <RemoveIcon />
                    </CustomButton>
                </RowStudentContent>
            </RowStudentContainer>       
        </>
    );
}