import { CoreContext } from "context/CoreContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";  

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify"; 
import { ReadByUser } from "services/questions";
import { Create, ReadOne, Update } from "services/students";
import { exposeStrapiError, goSection, normalizeStrapiList, normalizeStrapiRegister } from "utils";

export default function useController(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const params = useParams()

    const { user } = useContext(CoreContext)

    const [loading, setLoading] = useState(false) 
    const [questions, setQuestions] = useState([])    
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const init = async () => {
        setLoading(true)
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)

            const nextQuestions = normalResult
                ?.map( m => {
                    const nimage = normalizeStrapiRegister(m?.image)
                    return ({
                        ...m,
                        question: nimage?.url ? nimage : m?.question
                    })
                })?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() )

            setQuestions(nextQuestions)
        }
        setLoading(false)
    }

    useEffect(() => { init() ;}, [])

    return { 
        loading,  

        formValue,
        changeForm,
  
        questions,
        params,
        navigate,
        form,
        setForm,
        history
    }
}