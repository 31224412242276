import React, { useContext, useEffect, useState } from "react";  

import {  
    CardHome,
    CardHomeDecoration,
    CardHomeTitle,
    CardQuestion,
    CardQuestionButton,
    CardQuestionButtonContainer,
    CardQuestionContent,
    CardQuestionDecoration,
    CardQuestionIcon,
    CardQuestionIconDecoration,
    CardQuestionImage,
    CardQuestionRow,
    CardQuestionSeed,
    CardQuestionTitle,
    CardStudentItem,
    ContentFilter,
    ContentFilterContainer,
    ContentFilterContent,
    ContentFilterSeed,
    ContentFilterTitle,
    DashboardTitle,
    FullLineFilter,
    FullLineFilterInput,
    ImageSpacer,
    RowStudents
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom";
import { Delete, ReadByUser } from "services/classes";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList, normalizeStrapiRegister, parseStrapiImage } from "utils"; 
import Button from "components/Form/Button";
import { BetweenRow, Load, LoadCenter, LoadingCookies, MaxButton } from "ui/styled";
import { toast } from "react-toastify"; 
import { optionsLevel } from "utils/options";
import CustomButton from "components/Form/CustomButton";
import CardClasses from "components/Cards/CardClasses";

export default function DashboardContentClasses(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user } = useContext(CoreContext)

    const [questions, setQuestions] = useState([]) 
    const [loading, setLoading] = useState(false)  
    const [filterSeed, setFilterSeed] = useState(null)
 

    const init = async () => {
        setLoading(true)
        const result = await ReadByUser(user?.id)
        if(result && !exposeStrapiError(result)){
            const normalResult = normalizeStrapiList(result)

            const nextQuestions = normalResult
                ?.map( m => {
                    return ({
                        ...m,
                        lessons: normalizeStrapiList(m?.lessons)?.map(m => ({ ...m, image:!m?.image ? null : normalizeStrapiRegister(m?.image), questions:normalizeStrapiList(m?.questions) })),
                        students: normalizeStrapiList(m?.students)?.map(mm => ({
                            ...mm,
                            avatar: normalizeStrapiRegister(mm?.avatar)
                        })),
                        image: normalizeStrapiRegister(m?.image)
                    })
                })?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() )
            
            // console.log("nextQuestions", nextQuestions)
            
            setQuestions(nextQuestions)
        }
        setLoading(false)
    }

    const filterLessons = f => {
        return (
            ( !filterSeed || f?.subject === filterSeed?.subject ) 
        )
    }

    const remove = async (item) => {
        setLoading(true)
        const result = await Delete(item?.id)
        if(result && !exposeStrapiError(result)){
            toast.success("Removido")
            init()
        }
        setLoading(false)
    } 

    useEffect(() => { init() ;}, [])

    return ( 
        <>
            <ContainerAuthenticated>  

                {
                    loading ? <LoadingCookies /> :  <>
                        <BetweenRow>
                            <DashboardTitle>Minhas turmas</DashboardTitle>
                            <MaxButton>
                                <CustomButton onClick={() => navigate("dashboard/creator/classes")}>Criar Turma</CustomButton>
                            </MaxButton>
                        </BetweenRow>    

                        <Row>
                            <Col sm={12} md={12}>
                                <Row>
                                    {
                                        questions?.filter(filterLessons)?.map((item, key) => 
                                            <CardClasses key={key} item={item} /> 
                                        )
                                    }
                                </Row>
                            </Col> 

                        </Row>
                    </>
                }
    
            </ContainerAuthenticated> 
        </>
    );
}