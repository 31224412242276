import React from "react";  

import { 
    CardQuestionIcon, 
    ContentFilter, 
    ContentFilterContent, 
    ContentFilterTitle, 
} from "./styled";
 

import { modalityColors } from "ui/theme-color";

export default function CardFilterModality({ filter, setFilter, flat }){   
 
    return ( 
        <> 
            <ContentFilter>
                {
                    flat ? null :
                        <ContentFilterTitle>
                            Filtre pela modalidade
                        </ContentFilterTitle>
                }
                <ContentFilterContent>
                    {
                        Object.keys(modalityColors).map((item, key) => 
                            <CardQuestionIcon onClick={() => setFilter(filter === item ? null : item)} active={filter === item} flat key={key} src={`/icons/dark-lens-${ item }.svg`} />
                        )
                    }
                </ContentFilterContent>
            </ContentFilter>
        </>
    );
}