import React, { useContext, useState } from "react";   
 
import DashboardSide from "../Side";
import { 
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    DashboardHeaderActionIconChevron,
    DashboardHeaderActionMenu,
    DashboardHeaderActionMenuItem,
    DashboardHeaderTitle,
    DashboardMenuHeaderUserImageContainer,
    DashboardMenuHeaderUserImageContainerFade,
    DashboardHeaderLogo,
    DashboardHeaderInfo,
    DashboardHeaderInfoText,
    DashboardHeaderInfoImage
} from "./styled"; 
import { CoreContext } from "context/CoreContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DoLogout } from "services/authentication";
import { parseStrapiImage } from "utils";
import { Touch } from "ui/styled";

export default function DashboardHeader(){  
    const history = useHistory(); 
    const navigate = to => history.push(`/${ to }`); 
    
    const [opened, setOpened] = useState(false) 
    const { user } = useContext(CoreContext)


    const options = [
        { title:"Meu Perfil", action: () => navigate('dashboard/me') },
        { title:"Meu Plano", action: () => navigate('dashboard/plan') },
        { title:"Ajuda", action: () => navigate('help') },
        { title:"Sair", action: () => exit() },        
    ]


    const exit = async () => {
        await DoLogout()
        navigate('login')
    }

    return ( 
        <> 
            <DashboardMenuHeaderUserImageContainer>
                <DashboardMenuHeaderUserImageContainerFade>
                    <DashboardHeaderContainer>
                        <Touch onClick={() => navigate("dashboard")}>
                            <DashboardHeaderTitle>
                                <DashboardHeaderLogo rounded />
                                Royal Academy
                            </DashboardHeaderTitle>
                        </Touch>
                        <DashboardHeaderAction onClick={() => navigate("dashboard/me") }>
                            <DashboardHeaderInfoImage image={user?.image?.url ? parseStrapiImage(user?.image?.url) : null } />
                            <DashboardHeaderInfo>
                                <DashboardHeaderInfoText big>
                                    { user?.name }
                                </DashboardHeaderInfoText>
                                <DashboardHeaderInfoText>
                                    { user?.email }
                                </DashboardHeaderInfoText>
                            </DashboardHeaderInfo>
                            <DashboardHeaderActionIconChevron alt="menu-icon" />
                        </DashboardHeaderAction> 


                        {/* <DashboardHeaderActionMenu opened={opened}>
                            {
                                options?.map((item, key) => 
                                    <DashboardHeaderActionMenuItem key={key} onClick={item.action}>
                                        { item?.title }
                                    </DashboardHeaderActionMenuItem>
                                )
                            }
                        </DashboardHeaderActionMenu> */}
                        {/*  */}
                    </DashboardHeaderContainer> 
                </DashboardMenuHeaderUserImageContainerFade>
            </DashboardMenuHeaderUserImageContainer>
        </>
    );
}