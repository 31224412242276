import styled from 'styled-components'  




export const RowStudent = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid ${p=>p.theme.palette.colors.lightshadow};
    padding: 12px 0;
`;
export const RowStudentImage = styled.div.attrs({ 
})`
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: ${p=>p.theme.palette.colors.lightshadow}  url(${ p => p.image }) no-repeat center center / cover;
`;
export const RowStudentContent = styled.div.attrs({ 
})`
    ${
        p=>p.grow?`
            flex:1;
        `:``
    }
`;
export const RowStudentContentTitle = styled.div.attrs({ 
})`
    font-size: 16px;
    margin-bottom: 4px;
`;
export const RowStudentContentInfos = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 18px;
`;
export const RowStudentContentInfosContent = styled.div.attrs({ 
})`
    ${
        p => p.grow ? `
            flex:1;
        ` : ``
    }
`;
export const RowStudentContentInfosContentTitle = styled.div.attrs({ 
})`
    font-size: 12px;
`;
export const RowStudentContentInfosContentText = styled.div.attrs({ 
})`
    font-size: 18px;
`;  

export const ContentStudents = styled.div.attrs({ 
})`
    padding: 18px 0;
`;  

export const RemoveIcon = styled.img.attrs({ 
    src: '/icons/trash-white.svg'
})`
`;  






