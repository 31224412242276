import { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function DailyChart(){

    const monthsOfYear = [
        { title:"Janeiro", acron:"Jan", ref:1 },
        { title:"Fevereiro", acron:"Fev", ref:2 },
        { title:"Março", acron:"Mar", ref:3 },
        { title:"Abril", acron:"Abr", ref:4 },
        { title:"Maio", acron:"Mai", ref:5 },
        { title:"Junho", acron:"Jun", ref:6 },
        { title:"Julho", acron:"Jul", ref:7 },
        { title:"Agosto", acron:"Ago", ref:8 },
        { title:"Setembro", acron:"Set", ref:9 },
        { title:"Outubro", acron:"Out", ref:10 },
        { title:"Novembro", acron:"Nov", ref:11 },
        { title:"Dezembro", acron:"Dez", ref:12 },
    ]

    const generateData = (count, { min, max }) => {
        const data = [];
        for (let i = 0; i < count; i++) {
          data.push(Math.floor(Math.random() * (max - min + 1)) + min);
        }
        return data;
    }

    const [state, setState] = useState({  
        series: monthsOfYear?.map(m => ({
            name: m?.acron,
            data: generateData(30, {
              min: -30,
              max: 55
            })
        })),
        options: {
            chart: { 
                height: 350, 
                type: 'heatmap', 
                zoom: { enabled: false }
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    radius: 0,
                    useFillColorAsStroke: true,
                    colorScale: {
                        ranges: [
                            // { from: -30, to: 5, name: 'Baixa', color: '#027cba'  },
                            // { from: 6, to: 45, name: 'Normal', color: '#287b3e' },
                            // { from: 21, to: 45, name: 'Alta', color: '#fff201' },
                            // { from: 46, to: 55, name: 'Extrema', color: '#ed3237' }
                        ]
                    }
                }
            },
            dataLabels: { enabled: false },
            stroke: { width: 1 },
            title: { text: 'Atividade do aluno' },
        }
    });

    return ( 
        <>
            <ReactApexChart options={state.options} series={state.series} type="heatmap" height={350} />
        </>
    );
  }