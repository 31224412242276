import React from "react";  

import {  
    CardHome,
    CardHomeTitle,
    ContentRight,
    ContentRightItem,
    ContentRightTitle,
    DashboardTitle,
    CardOption, 
    CardOptionResponse, 
    CardOptionTitle, 
    CardOptionsWrap, 
    CardOptionsWrapIcon, 
    DashboardSideMascot, 
    DashboardSideMascotBaloon, 
    SimpleRow,
    CardForm,
    CardFormButton,
    CardOptionDecoration,
    CardOptionDecorationIcon,
    CardFormBetweeRow,
    CardFormResponse,
    CardFormResponseFlex,
    CardFormResponseActions,
    IconRemove
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useController from "./controller";
import Toggle from "components/Form/Toggle";
import { BetweenRow, FormAction, FormActions, FormContainer, GeneratedAction, Load, LoadCenter, ManualAction, MaxButton } from "ui/styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import ImagedInput from "components/Form/ImagedInput";
import CustomButton from "components/Form/CustomButton";

import DashboardCardRightWrong from "screens/Dashboard/Cards/RightWrong";
import DashboardCardMultiChoice from "screens/Dashboard/Cards/MultiChoice";
import CreatorMascotIA from "components/Creator/MascotIA";
import CreatorBaloonIA from "components/Creator/BaloonIA";

export default function DashboardCreatorTasksMultichoice({ embeded }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const {

        saving,
        loading, 
        thinking,

        formValue,
        changeForm,

        saveForm,
        saveMany,

        askAI,
        generated,

        toggleSelected,
        isSelected,
        selected,
        
        updateSuggestion,
        toggleManual,
        manual,
        editing,
        
        options,
        optionAdd,
        optionRemove,

        ctaIA,
        handleCorrect

    } = useController()

    return ( 
        <>
            <ContainerAuthenticated flat={!!embeded}> 

                {
                    loading ? <LoadCenter>
                        <Load colored />
                    </LoadCenter> : <>

                        {
                            manual ? null : <>

                                <Row>
                                    <Col md={1} sm={12}></Col>
                                    <Col md={9} sm={12}>
                                        <CardOptionsWrapIcon loading={thinking} /> 
                                        <DashboardTitle id="form">Multipla . Escolha</DashboardTitle>
                                        
                                        <CreatorBaloonIA generated={generated} />

                                        <CardForm>
                                            <Input id={"input-ai"} placeholder="Qual o tema da questão?" value={formValue("theme")} onChange={e => changeForm(e.target.value, "theme")} onSubmitEditing={askAI} />
                                            <CardFormButton>
                                                <CustomButton loading={thinking} onClick={askAI}>Gerar</CustomButton>
                                            </CardFormButton>
                                        </CardForm> 

                                        <CardOptionsWrap>
                                            {
                                                !generated?.length ? null : generated?.map((item, key) => 
                                                    <DashboardCardMultiChoice 
                                                        key={key} 
                                                        toggleSelected={toggleSelected} 
                                                        isSelected={isSelected} 
                                                        updateSuggestion={updateSuggestion} 
                                                        item={item} 
                                                    />
                                                )
                                            }
                                        </CardOptionsWrap>

                                        {
                                            !selected?.length ? null : <>
                                                <FormActions>
                                                    <FormAction>
                                                        <CustomButton loading={saving} onClick={saveMany}>Salvar Selecionados</CustomButton>
                                                    </FormAction>
                                                </FormActions>
                                            </>
                                        }

                                    </Col>
                                    <Col md={2} sm={12}></Col>
                                </Row>
                            </>
                        }



                        {
                            generated?.length ? null : <>
                                <Row>
                                    <Col md={1} sm={12}></Col>
                                    <Col md={9} sm={12}>
                                            {
                                                !manual ?  <>
                                                    <ManualAction>
                                                        <Button primary link onClick={toggleManual}>Criar manualmente</Button>
                                                    </ManualAction>
                                                </> : <>
                                                    <DashboardTitle id="form">Multipla . Escolha</DashboardTitle>
                                                    <FormContainer>
                                                        <ImagedInput placeholder="Pergunta" value={formValue("question")} onChange={e => changeForm(e.target.value, "question")} />
                                                        
                                                        {
                                                            options.map((item, key) =>
                                                                <CardFormResponse key={key}>
                                                                    <CardFormResponseFlex>
                                                                        <ImagedInput placeholder="Resposta" value={formValue(`answer${key}`)} onChange={e => changeForm(e.target.value, `answer${key}`)} />
                                                                    </CardFormResponseFlex>
                                                                    <CardFormResponseActions>
                                                                            <MaxButton small>
                                                                                <Button outline primary nospace onClick={() => optionRemove(item)} >
                                                                                    <IconRemove />
                                                                                </Button>
                                                                            </MaxButton>
                                                                            <ContentRight> 
                                                                                <ContentRightItem active={!!formValue(`correct${key}`)} onClick={() => handleCorrect(key)}>Verdadeiro</ContentRightItem>
                                                                                <ContentRightItem active={!formValue(`correct${key}`)} onClick={(v) => changeForm(false, `correct${key}`)}>Falso</ContentRightItem>
                                                                            </ContentRight>
                                                                    </CardFormResponseActions>
                                                                </CardFormResponse>
                                                            )
                                                        }

                                                        <FormAction big>
                                                            <CustomButton onClick={optionAdd}>Adicionar opção de resposta</CustomButton> 
                                                        </FormAction>

                                                        <Input type="textarea" placeholder="Feedback" value={formValue("feedback")} onChange={e => changeForm(e.target.value, "feedback")} />        
                                                        
                                                        <CardFormBetweeRow>
                                                            <ContentRight />  
                                                            <FormAction>
                                                                <CustomButton loading={saving} onClick={saveForm}>Salvar</CustomButton> 
                                                            </FormAction>
                                                        </CardFormBetweeRow>
                                                    </FormContainer>
                                                    {
                                                        editing ? null : 
                                                            <ManualAction>
                                                                <Button primary link onClick={toggleManual}>Gerar com IA</Button>
                                                            </ManualAction>
                                                    }
                                                </>
                                            }

                                    </Col>
                                    <Col md={2} sm={12}></Col>
                                </Row>
                            </>
                        }
                    </>
                }
                <CreatorMascotIA editing={editing} generated={generated} onClick={ctaIA} />
            </ContainerAuthenticated> 
        </>
    );
}