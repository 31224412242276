import React, { useContext, useEffect } from "react";  

import {   
    CardQuestion,
    CardQuestionButton,
    CardQuestionButtonContainer,
    CardQuestionContainer,
    CardQuestionContent,
    CardQuestionDecoration,
    CardQuestionIcon, 
    CardQuestionImage, 
    CardQuestionRow, 
    CardQuestionSeed,
    CardQuestionTitle, 
    ImageSpacer
} from "./styled";

import { Col } from "reactstrap";

import { parseStrapiImage } from "utils";
import Button from "components/Form/Button"; 
import { mockedQuestions } from "utils/options";
import { useHistory } from "react-router-dom";
import { useDrag } from 'react-dnd'
import { CoreContext } from "context/CoreContext";

export default function DashboardCardQuestion({ item, editable, onRemove, big, doubleClick }){   

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 

    const {setDragging} = useContext(CoreContext)

    const [{ opacity, isDragging }, dragRef] = useDrag(() => ({
        type: 'card-question', item,
        collect: (monitor) => ({ 
            opacity: monitor.isDragging() ? 0.5 : 1,
            isDragging: monitor.isDragging(),
        })
    }), [item])
 
    const parseTitle = (type) => {
        return mockedQuestions?.find(f => f.type === type)?.title
    }
    
    const remove = (item) => {
        if(onRemove && typeof onRemove === 'function'){ onRemove(item) ;}
    }

    useEffect(() => { setDragging(isDragging) }, [isDragging])
 
    return ( 
        <> 
            <Col sm={12} md={big ? 12 : 6} lg={big ? 6 : 3} style={{ opacity }}> 
                <CardQuestion ref={ !onRemove ? dragRef : null } draggable={!onRemove} noseed={!item?.seed} onDoubleClick={doubleClick || onRemove} onClick={!!onRemove || doubleClick ? null : () => navigate(`dashboard/creator/game/${item?.type}/${item?.id}`)}  >

                    <CardQuestionDecoration>{ parseTitle(item?.type) }</CardQuestionDecoration>
                    <CardQuestionIcon src={`/icons/dark-lens-${ item?.type }.svg`} />

                    <CardQuestionRow>
                         <CardQuestionSeed>{ item?.seed || "☺️" }</CardQuestionSeed>
                    </CardQuestionRow>
                    <CardQuestionContainer >
                        { !item?.question?.url ? null : <CardQuestionImage image={item?.question?.url ? parseStrapiImage(item?.question?.url) : null} /> }
                        <CardQuestionContent>
                            <CardQuestionTitle noseed={!item?.seed} >
                                { item?.question?.url ? null : item?.question }
                            </CardQuestionTitle>
                        </CardQuestionContent>
                    </CardQuestionContainer>
                    {/* {
                        !onRemove && !editable ? null :
                        <CardQuestionButtonContainer className="remove-button">
                            {
                                !editable ? null : 
                                    <CardQuestionButton>
                                        <Button nospace small outline primary onClick={() => navigate(`dashboard/creator/game/${item?.type}/${item?.id}`)}>Editar</Button>
                                    </CardQuestionButton>
                            }
                            {
                                !onRemove ? null : 
                                    <CardQuestionButton>
                                        <Button nospace small outline primary onClick={() => remove(item)}>Remover</Button>
                                    </CardQuestionButton>
                            }
                        </CardQuestionButtonContainer>
                    } */}
                </CardQuestion>
            </Col> 
        </>
    );
}