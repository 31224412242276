import styled from 'styled-components' 
 
 
export const KnowContent = styled.div.attrs({ 
})`          
    display: flex;
    flex-direction: column;
    padding: 0 0 0;
    background: ${ props => props.theme.palette.colors.backgrounddark };
`;

export const MoreTitle = styled.div.attrs({ 
})`          
    font-size: 32px;
    font-weight: bold;
    color: ${ props => props.theme.palette.primary.main };
`;
  
export const MoreText = styled.div.attrs({ 
})` 
    color: ${ props => props.theme.palette.colors.grey };
    font-size: 20px; 
`;
  

export const BannerTitle = styled.div.attrs({ 
})`           
    margin: 25vh 0 30px;
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
    }
`; 

export const BannerSubtitle = styled.div.attrs({ 
})`           
    margin: 5vh 0 30px;
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
    }
`; 

export const BannerText = styled.div.attrs({ 
})`           
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 22px;
    ${ p => p?.big ? `
        font-size: 32px;
    ` : ``}
    font-weight: 700;
`; 

export const BannerButton = styled.div.attrs({ 
})`           
    width: 100%;
    max-width: 320px;
    margin: 7vh auto;
`; 

export const BannerTextContainer = styled.div.attrs({ 
})`           
    width: 100%;
    max-width: 620px;
    margin: 7vh auto;
    display: flex;
    flex-direction: column;
`; 

export const BannerBigSpace = styled.div.attrs({ 
})`           
    width: 100%;
    margin: 70vh auto;
`; 

export const BannerPrice = styled.img.attrs({
    src:'/images/stamp-price.png',
})`
    margin: 40px auto;

    width: 80%;
    max-width: 420px;
`; 
