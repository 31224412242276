import React, { useState } from "react"; 
 
import Banner from "components/Landpage/Banner";
import HowWorks from 'components/Landpage/HowWorks'
import MoreInfos from 'components/Landpage/MoreInfos'
import CommomCases from 'components/Landpage/CommomCases'
import KnowMore from 'components/Landpage/KnowMore'
import LittleMore from 'components/Landpage/LittleMore' 

import ContainerLandpage from "containers/Landpage";
import { LandPrereleaseCover, TigerDecorationImage, Title, Text, InfoContent, InfoContentIn, UniIcon, AnimatedText, AnimatedTextContainer } from "./styled";
import moment from "moment/moment";
import { Container } from "reactstrap";
import KnowMoreFinal from "components/Landpage/KnowMoreFinal";
import CommomCasesFinal from "components/Landpage/CommomCasesFinal";

export default function Landpage(){ 

    const today = moment();
    const releaseDate = moment('2024-08-15'); 

    const [blocked, setBlocked] = useState(false)
    const [counter, setCounter] = useState(0)

    const openEaster = () => {
        if(counter < 6){
            setCounter(counter+1)
            return;
        }
        setBlocked(false)
    }

    return ( 
        <ContainerLandpage land> 
            <>
                <Banner /> 
                <MoreInfos />
                <CommomCases /> 
                <KnowMore /> 
                <LittleMore />  
                {/* <KnowMoreFinal />  */}
                {/* <CommomCasesFinal />  */}
                <HowWorks /> 
            </>
        </ContainerLandpage>
    );
}