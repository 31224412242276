import styled from 'styled-components'  
 
export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 


    font-size: 40px;
    line-height: 46px;
    font-weight: 900;

    flex:1;

    margin: 20px 0 10px;
    ${ p => p.spaced ? `
        margin: 20px 12px 10px;
    ` : `` }
    ${ p => p.centred ? `
        text-align: center;
        font-size: 24px;
        font-weight: 400;
    ` : `` }
`;
export const FormSubtitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 

    font-size: 48px;
    line-height: 54px;
    font-weight: 900;

    padding-top: 10px;
    margin-bottom: 60px;
`;
export const FormSupertext = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 

    font-size: 22px;
    line-height: 26px;
    font-weight: 900;

    padding: 10px 0;
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 18px; 
    color: ${ props => props.theme.palette.colors.black };
    margin: 0px 0 30px;
    b {
        color: ${ props => props.theme.palette.colors.green };
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }

    ${
        p => p.big ? `
            font-size: 28px;
            font-weight: 300;
        ` : ``
    }
`;

export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 40px;
`;
 

export const RowForm = styled.div.attrs({ 
})` 
    display: flex;          
    gap: 20px;
`;
 
export const RegisterCall = styled.div.attrs({ 
})`           
    margin: 30px 0 0px;
    color: ${ props => props.theme.palette.colors.black };
    font-size: 15px;
    font-weight: bold;
`;



 

export const RegisterButton = styled.div.attrs({ 
})`
    margin: 0 0 0 auto;
    ${
        p => p.centred ? `
            margin: 0 auto;
        ` : ``
    }
    ${
        p => !p.huge ? `
                max-width: 380px;
        ` : ``
    }
`;






export const FormSteper = styled.div.attrs({ 
})`
    display: flex;
    padding: 0 0 40px 0;
    gap: 12px;
`;

export const FormSteperDecoration = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${
        p => p.centred ? `
            justify-content: center;
        ` : ``
    }
    ${
        p => p.spaced ? `
            margin-bottom: 80px;
        ` : ``
    } 
`;


export const FormSteperDecorationContent = styled.div.attrs({ 
})`
    padding-bottom: 20px;
`;

export const FormSteperDecorationLine = styled.div.attrs({ 
})`
    height: 6px;
    width: 100%;
    border-radius: 3px;
    background: ${ p => p.theme.palette.colors.red };
`;

export const FormSteperItem = styled.div.attrs({ 
})`
    padding: 2px 32px;
    background: ${ p => p.theme.palette.colors.backgrounddark };
    opacity: ${ p => p.active ? 1 : .3 };
    ${ p => p.done ? `
        background: ${ p.theme.palette.colors.green };
        opacity: 1;
    ` : `
    ` };

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;



export const FormSteperButton = styled.div.attrs({ 
})`
    padding: 18px;
    background: ${ props => props.theme.palette.colors.backgrounddark }; 
    color: ${ props => props.theme.palette.colors.white }; 

    display: flex;
    flex-direction: column;
    align-items: center;
    jsutify-content: center;

    text-align: center;
    font-size: 24px;
    line-height: 28px;
    font-weight: 900;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;

export const FormSteperCheck = styled.img.attrs(p => ({ 
    src: p?.error ? '/icons/error.svg' : p?.big ? '/icons/check-white.svg' : '/icons/check.svg',
    width: p?.big ? 60 : 32
}))`
    ${ p => p?.big ? `
        margin: -10px auto;
    ` : `` }
`;

export const AnimateChilds = styled.div.attrs({ 
})`
    transition: all .3s ease;
`;

export const FormSteperSuccessContainer = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FormSteperSuccess = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    aspect-ratio: 1 / 1 ;
    background: ${ props => props.theme.palette.colors.green };
    padding: 20px;
    margin: 0 auto 80px;
    ${
        p => p.error ? `
            background: ${ p.theme.palette.colors.red };
        ` : ``
    }
`;