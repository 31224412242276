import styled from 'styled-components'

 
export const FeedbackContainer = styled.div.attrs( props => ({
    animation: props.correct ? 'zoomInDown' : 'zoomInUp'
}))` 
    position: absolute;
    bottom: 0;
    width: 100%; 
    padding: 15px 25px 30px;
    background-color: ${ p => p.theme.palette.colors.white }; 

    border-top-width:.5px;
    border-top-color: ${ p => p.theme.palette.colors.shadow };

    z-index:1;
`; 


export const FeedbackTitle = styled.div.attrs({})`
    font-size: 20px;
    color: ${ props => props.correct ? props.theme.palette.colors.green : props.theme.palette.colors.red };
`;

export const FeedbackText = styled.div.attrs({})`
    font-size: 18px;
    color: ${ p => p.theme.palette.colors.black };
    margin: 10px 0 25px;
`;

export const FeedbackRow = styled.div.attrs({})`
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
`;
