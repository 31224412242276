import styled from 'styled-components'  
import { WindowScreen } from 'ui/styled';

export const Content = styled.div.attrs({
})`            
    padding: 12px 24px;
    min-height: 70vh; 
    display: flex;
    flex-direction: column;
`;    

export const GameContainer = styled.div.attrs({
})`            
    max-width: ${WindowScreen.width}px;
    margin: 0 auto;
    position: relative;
`;    

