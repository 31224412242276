import React, { useCallback, useContext, useEffect, useState } from "react";  

import {   
    RowStudentContainer,
    RowStudentImage,
    RowStudentContent,
    RowStudentContentTitle,
    RowStudentContentInfos,
    RowStudentContentInfosContent,
    RowStudentContentInfosContentTitle,
    RowStudentContentInfosContentText,
    RemoveIcon,
} from "./styled";

import CustomButton from "components/Form/CustomButton";

import { parseStrapiImage } from "utils";
import { useHistory } from "react-router-dom";
import { Touch } from "ui/styled";

export default function RowStudent({ item, remove }){   

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <>
            <RowStudentContainer>
            <Touch onClick={() => navigate(`dashboard/creator/students/${ item?.id }`)}>
                    <RowStudentImage image={item?.avatar?.url ? parseStrapiImage(item?.avatar?.url) : "/images/student.png"} />
                </Touch>
                <RowStudentContent grow>
                    <RowStudentContentTitle>{ item?.name }</RowStudentContentTitle>
                    <RowStudentContentInfos>
                        <RowStudentContentInfosContent>
                            <RowStudentContentInfosContentTitle>Média</RowStudentContentInfosContentTitle>
                            <RowStudentContentInfosContentText>{ item?.media || 0 }</RowStudentContentInfosContentText>
                        </RowStudentContentInfosContent>
                        <RowStudentContentInfosContent>
                            <RowStudentContentInfosContentTitle>Performance</RowStudentContentInfosContentTitle>
                            <RowStudentContentInfosContentText>{ item?.classification || "F" }</RowStudentContentInfosContentText>
                        </RowStudentContentInfosContent>
                    </RowStudentContentInfos>
                </RowStudentContent>
                <RowStudentContent>
                    <CustomButton small onClick={remove}>
                        <RemoveIcon />
                    </CustomButton>
                </RowStudentContent>
            </RowStudentContainer>       
        </>
    );
}