import React, { useState, createContext, useEffect } from 'react'
import { ReadObject, SaveObject } from '../services/storage'
import { ReadMe } from 'services/me'
 
export const CoreContext = createContext({})

export const CoreState = ({ children }) => {
      
	const [ dragging, setDragging ] = useState(false)  
	const [ modal, setModal ] = useState(null)  
	const [ superModal, setSuperModal ] = useState(null)  
	const [ covers, setCovers ] = useState( ReadObject('covers') ? ReadObject('covers') : [])  
	const [ user, setUser ] = useState( ReadObject('user') ? ReadObject('user') : null)  

	const reloadMe = async () => {
		const result = await ReadMe()
		if(result && result?.id){
			setUser(result)
			return result;
		}
	}

	const contextValue = {  
		user, setUser,
		dragging, setDragging,
		modal, setModal,
		superModal, setSuperModal,
		covers, setCovers,
		reloadMe
	}

	// to persist state when app reload  
    useEffect(() => { SaveObject('user', user) ;}, [user]) 
    useEffect(() => { SaveObject('covers', covers) ;}, [covers]) 

	return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}