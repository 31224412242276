import styled from 'styled-components';

import { WindowScreen } from 'ui/styled';

export const TableOut = styled.div`
  border-color: ${p => p.theme.palette.colors.lightshadow };
  border-width: 1px;
  margin: 7px auto;
`;

export const TableRow = styled.div`
  display: flex;
`;

export const TableCol = styled.div`
  width: ${({ columns }) => (WindowScreen.width - 75) / columns}px;
  height: ${({ columns }) => (WindowScreen.width - 75) / columns}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-color: ${p => p.theme.palette.colors.lightshadow};
  border-width: 0.5px;
`;

export const CrosswordLetter = styled.div`
  font-size: ${WindowScreen.width * 0.035}px;
  font-weight: 500;
  color: ${props => !!props.checked ? props.theme.palette.colors.white : props.theme.palette.colors.text };
  text-transform: uppercase;
`;








export const CheckedLetter = styled.div.attrs({

})`
    display: flex;
    ${ p => p.checked ? `
        background-color: ${ p.theme.palette.colors.primary };
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;
    ` : `` }
    ${ p => p.first ? `
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    ` : `` }
    ${ p => p.last ? ` 
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    ` : `` }
    ${ p => p.firstY ? `
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    ` : `` }
    ${ p => p.lastY ? ` 
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    ` : `` }

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;

