import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { ThemedComponent } from 'ui/theme'

import ModalSample from '../Sample'
import ModalQrCode from '../QrCode'
import ModalResponse from '../Response'
import ModalResponseDetails from '../ResponseDetails'
import ModalGame from '../Game'
import ModalAddStudents from '../AddStudents'
import ModalAddLessons from '../AddLessons'
import ModalSelectImage from '../SelectImage'
import ModalConfirm from '../Confirm'
import ModalAddTasks from '../AddTasks'

export default function ModalCore() {

  const { modal, superModal } = useContext(CoreContext)

  

  return (
    <>
      <ThemedComponent>
        {modal?.type === 'sample' ? <ModalSample /> : null}
        {modal?.type === 'qr-code' ? <ModalQrCode /> : null}
        {modal?.type === 'response' ? <ModalResponse /> : null}
        {modal?.type === 'game' ? <ModalGame /> : null}
        {superModal?.type === 'response-details' ? <ModalResponseDetails /> : null}
        {modal?.type === 'add-students' ? <ModalAddStudents /> : null}
        {modal?.type === 'add-lessons' ? <ModalAddLessons /> : null}
        {modal?.type === 'add-tasks' ? <ModalAddTasks /> : null}
        {modal?.type === 'select-image' ? <ModalSelectImage /> : null}
        {modal?.type === 'confirm' ? <ModalConfirm /> : null}
      </ThemedComponent>
    </>
  )
}

