import React, { useCallback, useContext, useEffect, useState } from "react";  

import {   
    RowStudent,
    RowStudentImage,
    RowStudentContent,
    RowStudentContentTitle,
    RowStudentContentInfos,
    RowStudentContentInfosContent,
    RowStudentContentInfosContentTitle,
    RowStudentContentInfosContentText,
    RemoveIcon,
} from "./styled";

import { CoinImage, Touch } from "ui/styled";
import CustomButton from "components/Form/CustomButton";

import { parseStrapiImage } from "utils";
import { optionsLevel } from "utils/options";
import { useHistory } from "react-router-dom";
import { parseCurrencyMoney } from "utils/parsers";

export default function RowLesson({ item, remove }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    return ( 
        <> 
            <RowStudent>
                <Touch onClick={() => navigate(`dashboard/creator/lessons/${ item?.id }`)}>
                    <RowStudentImage image={item?.image?.url ? parseStrapiImage(item?.image?.url) : ""} />
                </Touch>
                <RowStudentContent grow>
                    <RowStudentContentTitle>{ item?.title }</RowStudentContentTitle>
                    <RowStudentContentInfos>
                        <RowStudentContentInfosContent>
                            <RowStudentContentInfosContentTitle>Tarefas</RowStudentContentInfosContentTitle>
                            <RowStudentContentInfosContentText>{ item?.questions?.length || 0 }</RowStudentContentInfosContentText>
                        </RowStudentContentInfosContent>
                        <RowStudentContentInfosContent grow>
                            <RowStudentContentInfosContentTitle>Nível</RowStudentContentInfosContentTitle>
                            <RowStudentContentInfosContentText>{ optionsLevel?.find(f => f.id === item?.level)?.title || "" }</RowStudentContentInfosContentText>
                        </RowStudentContentInfosContent>
                        <RowStudentContentInfosContent>
                            <RowStudentContentInfosContentTitle>Recompensa</RowStudentContentInfosContentTitle>
                            <RowStudentContentInfosContentText>{ parseCurrencyMoney(item?.coins || 0) } <CoinImage /> </RowStudentContentInfosContentText>
                        </RowStudentContentInfosContent>
                    </RowStudentContentInfos>
                </RowStudentContent>
                <RowStudentContent>
                    <CustomButton small onClick={remove}>
                        <RemoveIcon />
                    </CustomButton>
                </RowStudentContent>
            </RowStudent>      
        </>
    );
}