import styledCmp from 'styled-components'  

import { styled } from '@mui/material/styles'; 
// import Input from '@mui/material/Input'; // standard (material)
import Input from '@mui/material/FilledInput'; 
// import Input from '@mui/material/OutlinedInput'; 

// import TextField from '@mui/material/TextField';


export const MaterialInput = styled(Input)(({ theme, small, type }) => ({
    fontSize: small ? 12 : 24,
    ...(type === 'textarea') ? {
        minHeight: 180,
        verticalAlign:"top",
        display: "block"
    } : {} 
}));

export const InputIcon = styledCmp.img.attrs({ 
})`
`;