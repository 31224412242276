import { useEffect } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { mockedQuestions } from "utils/options";

export default function RadarChart({ questions, allQuestions }){
    
    const [visible, setVisible] = useState(false)

    // const questionTypes = (allQuestions||[])?.reduce((p, c) => c?.type && p?.map(m=>m?.type).includes(c?.type) ? p : [ ...p, c] ,[])
    const questionTypes = (questions||[])?.reduce((p, c) => c?.type && p?.map(m=>m?.type).includes(c?.type) ? p : [ ...p, c] ,[])

    var options = {
        legend: { position: 'bottom' }, 

        series: [
            // {
            //     name: 'Questões',
            //     data: questionTypes?.map(m => (allQuestions||[])?.filter(f => f?.type === m?.type)?.length || 0 ),
            // }, 
            {
                name: 'Questões Adicionadas',
                data: questionTypes?.map(m => (questions||[])?.filter(f => f?.type === m?.type)?.length || 0 ),
            }
        ],
        chart: { 
            height: 350, 
            type: 'radar', 
            dropShadow: {
                enabled: true,
                blur: 1,
                left: 1,
                top: 1
            }
        },
        title: { text: 'Tipos de questões' },
        yaxis: { stepSize: 20 },
        xaxis: {
            categories: questionTypes?.map(m => mockedQuestions?.find(f => f.type === m.type)?.title || ""),
        }
    }; 




    useEffect(() => {
        const suspense = setTimeout(() => { setVisible(true) ;}, 400)
        return () => { clearTimeout(suspense) ; }
    })

    console.log("questionTypes", questionTypes?.map(m => m.type || ""),  questionTypes?.map(m => (questions||[])?.filter(f => f?.type === m?.type)?.length || 0 ) )
 
    return !visible ? null : ( 
        <>
            <ReactApexChart options={options} series={options.series} type="radar" height={350} />
        </>
    );
  }