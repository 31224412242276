import { POST, DELETE } from './api'

const table = "stripe"

export const CreateSubscribe = async (params) => {
    return await POST(`/${ table }/subscribe`, params, true);
}

export const CreatePaymentLink = async (params) => {
    return await POST(`/${ table }/payment-link`, params, true);
}

export const CancelSubscription = async () => {
    return await DELETE(`/${ table }/subscription/cancel`, true);
}