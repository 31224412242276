import React, { useCallback, useContext, useEffect, useState } from "react";  

import {  
    DragContent,
    DropContent,
    DropContentText,
    DropableContent,
    DashboardText
} from "./styled";

import { Row, Col } from "reactstrap";

import DashboardCardQuestion from "components/Cards/CardQuestion";
import { DasboardSpacer, Title } from "ui/styled";
import { useDrop } from "react-dnd";
import { CoreContext } from "context/CoreContext";
import CardFilterModality from "components/Cards/CardFilterModality";

import CustomButton from "components/Form/CustomButton";

export default function DraggerTasks({ questions, selectedQuestion, setSelectedQuestion, navigate }){  

    const { dragging, setDragging, setModal } = useContext(CoreContext)
     
    const [filterModality, setFilterModality] = useState(null) 

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'card-question',
        drop: (item) => addQuestion(item),
        collect: (monitor) => ({
          isOver: monitor.isOver(), 
          canDrop: monitor.canDrop(), 
        })
    }),[selectedQuestion]);

    const addQuestion = useCallback(item => {
        setSelectedQuestion([ ...selectedQuestion, item ])
        setDragging(false)
    }, [selectedQuestion])

    const removeQuestion = useCallback(item => {
        setSelectedQuestion([ ...selectedQuestion?.filter(f => f?.id !== item?.id) ])
    }, [selectedQuestion])

    const filterQuestions = f => {
        return (
            ( !filterModality || f?.type === filterModality ) &&
            (!selectedQuestion?.length || !selectedQuestion?.map(m => m?.id)?.includes(f?.id))
        )
    }
 
    return ( 
        <>
            {
                !questions?.length ? <>
                    <Title></Title>
                    <DashboardText>Crie questões para adiciona-las ao exercício</DashboardText>
                    <CustomButton onClick={() => {navigate("dashboard/creator/tasks");setModal(null)}}>Criar questão</CustomButton>
                </> :
                <Row>
                    <Col sm={6}> 
                        <DashboardText>Arraste as questões para adiciona-las à aula</DashboardText>
                        <CardFilterModality flat filter={filterModality} setFilter={setFilterModality} />
                        <DasboardSpacer />
                        <DragContent>
                            <Row>
                                    {
                                        questions?.filter(filterQuestions)?.map((item, key) => 
                                            <DashboardCardQuestion key={key} item={item} big doubleClick={() => addQuestion(item)}/>  
                                        )
                                    }
                            </Row>
                        </DragContent>
                    </Col>

                    <Col sm={6}> 
                        <DashboardText>Questões adicionadas ({selectedQuestion?.length})</DashboardText>
                        {
                            !dragging && selectedQuestion?.length ? <>
                                <DropableContent>
                                    <Row>
                                            {
                                                selectedQuestion?.map((item, key) => 
                                                    <DashboardCardQuestion key={key} item={item} big onRemove={() => removeQuestion(item)} />  
                                                )
                                            }
                                    </Row>
                                </DropableContent>
                            </> : 
                                <DropContent ref={drop} isOver={isOver}>
                                    <DropContentText>
                                        { isOver ? "Solte a questão aqui para adiciona-lá" : "Arraste as questões aqui para compor sua aula" }
                                    </DropContentText>
                                </DropContent>
                        }
                    </Col>
                </Row> 
            }        
        </>
    );
}