import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'
import { FormText, Title } from 'ui/styled'
import { CompanyButtonContainer } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';
import CustomButton from 'components/Form/CustomButton';

export default function ModalConfirm() {

  const { modal, setModal } = useContext(CoreContext)

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    if(modal?.onConfirm && typeof modal?.onConfirm === 'function'){
      modal?.onConfirm()
    }
    close()
  }

  return (
    <>
      <Wrapper>
        <Title upper nomargin>{ modal?.title }</Title>
        <FormText>{ modal?.text }</FormText>
        <CompanyButtonContainer>
          <CustomButton onClick={close}>{ modal?.cancel }</CustomButton>
          <CustomButton outline color="backgroundblack" onClick={handleSave} between>{ modal?.confirm }</CustomButton>
        </CompanyButtonContainer>
      </Wrapper>
    </>
  )
}
