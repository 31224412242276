import React from "react"; 
 
import ContainerLandpage from "containers/Landpage";
import { BannerStudent, BannerStudentDecoration, BannerText, BannerTitle, ContentStudentClasses } from "./styled";
import { Container } from "reactstrap";
import { useState } from "react";
import { ReadTeacherLessons } from "services/lessons";
import { ReadTeacherClasses, ReadTeacherRanking, ReadTeacherStudent } from "services/classes";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import CardClass from "components/Cards/CardClass";
import { LoadingCookies } from "ui/styled";
import { Row, Col } from "reactstrap";
import CardStudentID from "components/Cards/CardStudentID";
import MoreInfos from "components/Landpage/MoreInfos";

export default function StudentLessons(){ 

    const params = useParams()

    const [loading, setLoading] = useState(null)
    const [student, setStudent] = useState(false)

    const init = async () => {
        if(params?.code){
            setLoading(true)
            const lessons = (await ReadTeacherLessons({ code: params?.code }))
            const classes = await ReadTeacherClasses({ code: params?.code })
            const ranking = await ReadTeacherRanking({ code: params?.code })
            const student = await ReadTeacherStudent({ code: params?.code })
    
            // console.log("FOUNDED student profile", { lessons, classes, ranking, student })
            
            setStudent({ classes, lessons, ranking, student: student?.user, teacher: student?.teacher })
            setLoading(false)
        }
    }

    useEffect(() => { init() ;}, [params])
 
    return ( 
        <ContainerLandpage > 
            <BannerStudent>
                <BannerStudentDecoration>

                </BannerStudentDecoration>
            </BannerStudent>
            <Container>

                <BannerTitle>Exercícios</BannerTitle>
                {
                    loading ? <LoadingCookies /> : <>
                        {
                            !student?.classes?.length ? <>
                                <BannerText>Nenhuma exercício encontrado, verifique seu código ou aguarde o professor adicionar aulas à sua turma</BannerText>
                            </> : <>
               
                                <ContentStudentClasses>
                                    {
                                        student?.classes?.map((m, k)=> 
                                            <CardClass studyMode key={k} item={m} student={student} />
                                        )
                                    } 
                                </ContentStudentClasses>
                            </>
                        }
                    </>
                }
                <BannerTitle />
            </Container>
        </ContainerLandpage>
    );
}