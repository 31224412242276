import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'
import { FormText, Title } from 'ui/styled'
import { CompanyButtonContainer } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';
import DraggerStudents from 'components/Draggers/Students';
import CustomButton from 'components/Form/CustomButton';
import { useEffect } from 'react';
import { useState } from 'react';

export default function ModalAddStudents() {

  const { modal, setModal } = useContext(CoreContext)

  const [selectedUser, setSelectedUser] = useState([])

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    if(modal?.save && typeof modal?.save === 'function'){
      modal?.save(selectedUser)
    }
    close()
  }

  useEffect(() => { setSelectedUser(modal?.selectedStudents||[]) ;}, [modal])

  return (
    <>
      <Wrapper big>

        <Title nomargin>Adicionar alunos</Title>
        <DraggerStudents users={modal?.students} selectedUser={selectedUser} setSelectedUser={setSelectedUser} navigate={modal?.navigate} />
        {
          !modal?.students?.length ? null : 
          <CompanyButtonContainer>
            <CustomButton onClick={handleSave} between>Salvar</CustomButton>
          </CompanyButtonContainer>
        }
      </Wrapper>
    </>
  )
}
