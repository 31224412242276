import { CoreContext } from 'context/CoreContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react'  



let timer ;
let gametimer ;
let responsetimer ;

export default function useController(params){     

    const { setModal } = useContext(CoreContext)
    const [current, setCurrent] = useState(0)

    const [lesson, setLesson] = useState({})
    const [question, setQuestion] = useState({})
    const [answers, setAnswers] = useState([])
    
    const [feedback, setFeedback] = useState(null)
    
    const [time, setTime] = useState(null)
    const [skipped, setSkipped] = useState(null)
    const [gametime, setGametime] = useState(0)
    
    const [freezing, setFreezing] = useState(false)

    const [gNext, setGNext] = useState(null)

    
    const game = useMemo(() => {
        if(params?.lesson){
            return params?.lesson?.game
        }
        return null
    }, [params])


    const preloadGame = async () => {  
        if(game && !freezing){
            setFreezing(true)
            const freezetime = 1500
            setTimeout(() => {
                startGame(game)
            }, freezetime)
        }
    }

    const startGame = (mLesson) => { 
        if(!lesson?.coins){
            setLesson(mLesson) 
            if(mLesson?.questions?.[current]){
                setQuestion(mLesson?.questions?.[current])
            }
        }
    }

    const restartGame = () => {
        clearAndNext(0) 
    }

    const response = value => {
        clearTimeout(timer) 
        if(!feedback){
            setFeedback(value)
            clearTimeout(responsetimer) 
            responsetimer = setTimeout(() => {
                setAnswers([...answers, value])
            }, 100)
        }
    }
 
 
    const clearAndNext = (number) => {
        setQuestion({})
        setGNext(number)
    }

    const releaseNext = () => { 
        setCurrent( gNext )
        setQuestion(lesson?.questions?.[gNext]) 
        setGNext(null)
    }


    const next = () => {
        setFeedback(null)
        const nextCurrent = ( current + 1 ) 
        if( nextCurrent < lesson.questions.length ){
            clearAndNext(nextCurrent) 
        }else{
            //end
            // restartGame()
            setModal({ type:"response", currentLesson:{ response:{ game:lesson, answers, time:gametime, gametime, gameLesson: params?.lesson, forfun: params?.forfun } }, student:params?.student })
        }
    }
    const startQuestion = () => {
        startTimer( question.reverse ? 1 : question.time )
        // soundJump();
    }

    const startTimer = (tm) => {
        if(tm){
            setTime(tm)
            timer = setTimeout(clockUpdate, 1000)
        }else{
            setTime(null)
        }
    }

    const clockUpdate = (tm) => {
        if(question.reverse){
            reserveClockUpdate(tm, question.time);
        }else{
            doClockUpdate(tm);
        }
        gametimer = setTimeout(() => { setGametime(gametime+1) ;}, 1000)
    }

    const doClockUpdate = (tm) => {
        const nextSecond = ( tm - 1 );
        if( nextSecond >= 0){
            // if(nextSecond === 10){ soundEnding() ;}
            timer = setTimeout(() => { setTime(nextSecond) ;}, 1000)
        }else{  
            // setAnswers([ ...answers, {
            //     question:{...question},
            //     answer:{ correct:false, type:question.type, skip:true }
            // }])
            // setSkipped(true)
        }
    } 
    
    const reserveClockUpdate = (tm, end) => {
        const nextSecond = ( tm + 1 );
        if( nextSecond === end ){
            // soundEnding();
        }
        timer = setTimeout(() => { setTime(nextSecond) ;}, 1000)
    }
 
    useEffect(() => { if( gNext !== null ){ releaseNext() ;}}, [gNext]) 
    // useEffect(() => { if( skipped ){ setSkipped(null); next() ;}}, [skipped]) 
    useEffect(() => { if(question.type){ startQuestion() ;}}, [question]) 
    useEffect(() => { preloadGame(); }, [  ]) 
    useEffect(() => { 
        if( time || time === 0 ){ clockUpdate(time); } 
        return () => {
            clearInterval(timer);
            clearInterval(gametimer);
        } 
    }, [time]) 

    return {
        lesson,
        question,
        time,
        current,
        response,
        next,
        feedback,
         
    }
}