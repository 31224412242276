import { normalizeStrapiList, parseStrapiImage } from "utils"
import { optionsLevel } from "./options"

export const parseCurrency = (value) => {
    let values = `${(parseFloat(value)||0).toFixed(2)}`.split('.')
    return `R$ ${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`
}  

export const parseCurrencyMoney = (value) => {
    let values = `${(parseFloat(value)||0).toFixed(2)}`.split('.')
    return `${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)},${values[1]}`.split(',')[0]
}

export const parseCurrencyCents = (value) => {
    let values = `${(parseFloat(value)||0).toFixed(2)}`.split('.')
    return values[1]
}
 
export const parseDatestringBRUS = datestring => {
    const ds = `${ datestring }`?.split('/')
    return `${ ds[2] }-${ ds[1] }-${ ds[0] }`
}

export const parseDatestringUSBR = datestring => {
    const ds = `${ datestring }`?.split('T')?.[0]?.split('-')
    return `${ ds[2] }/${ ds[1] }/${ ds[0] }`
}


export const coinize = (value) => {
    // let values = `${(parseFloat(value)).toFixed(2)}`.split('.')
    // return `${`${values[0]}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1.`)}`
    return `${(parseFloat(value)).toFixed(1)}`
}


export const parseGame = item => ({
    title: item?.title,
    description: item?.description,
    image: item?.image?.data?.attributes?.url ? { uri: parseStrapiImage(item?.image?.data?.attributes?.url) } : null,
    game:{
        ...item,
        level: optionsLevel?.find(f => f.id === item?.level)?.title,
        questions: normalizeStrapiList(item?.questions).map( m => ({ 
            ...m, 
            image: m?.image?.data?.attributes?.url ? { uri: parseStrapiImage(m?.image?.data?.attributes?.url) } : null,
            options: m?.options?.map( mm => ({ 
                ...mm, 
                image: mm?.image?.data?.attributes?.url ? { uri: parseStrapiImage(mm?.image?.data?.attributes?.url) } : null,
            }))
        }))
    }
})

export const parseGameHome = item => ({
    title: item?.title,
    response: item?.response,
    description: item?.description,
    image: item?.image?.url ? { uri: parseStrapiImage(item?.image?.url) } : null,
    game:{
        ...item,
        level: optionsLevel?.find(f => f.id === item?.level)?.title,
        questions: (item?.questions).map( m => ({ 
            ...m, 
            image: m?.image?.url ? { uri: parseStrapiImage(m?.image?.url) } : null,
            options: m?.options?.map( mm => ({ 
                ...mm, 
                image: mm?.image?.url ? { uri: parseStrapiImage(mm?.image?.url) } : null,
            }))
        }))
    }
})