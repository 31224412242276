import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'
import { FormText, Title } from 'ui/styled'
import { CompanyButtonContainer, ImageCoverImage, WrapperCovers } from './styled'
import Wrapper from '../Wrapper';

import { useState } from 'react';
import { parseStrapiImage } from 'utils';
import CustomButton from 'components/Form/CustomButton';

export default function ModalSelectImage() {

  const { covers, modal, setModal } = useContext(CoreContext)

  const [selectImage, setSelectedImage] = useState(modal?.selected || null)

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    if(modal?.save && typeof modal?.save === "function"){
      modal?.save( covers?.find(f => f?.image?.id === selectImage)?.image )
    }
    close()
  }


  return (
    <>
      <Wrapper>
        <Title upper nomargin>Selecione a capa</Title>
        <FormText>Escolha entra nossas capas selecionadas</FormText>

        <WrapperCovers>
            {
              covers?.filter(f => f.type === modal?.mode)?.map((m, k) => 
                <ImageCoverImage key={k} onClick={() => setSelectedImage(m?.image?.id)} active={m?.image?.id === selectImage} image={m?.image?.url ? parseStrapiImage(m?.image?.url) : null} />
              )
            }
        </WrapperCovers>

        <CompanyButtonContainer>
          <CustomButton color="primary" onClick={handleSave} between>Salvar</CustomButton>
        </CompanyButtonContainer>
      </Wrapper>
    </>
  )
}
