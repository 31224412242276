import styled from 'styled-components'

import { Animation, WindowScreen } from 'ui/styled';

export const Container = styled.div.attrs()` 
    padding: 12px 24px;
`; 

export const Title = styled.div.attrs()`
    font-size: ${ props => props.big? `16px` : `18px` }; 
    color: ${ p => p.theme.palette.colors.black };  
    ${ props => props.big ? `
        margin-top: 1.5px;
        margin-right: 7px;
    ` : `` }; 
    ${
        props => props.spaced ? `
            margin-bottom: 10px;
        ` : ``
    }
    ${
        props => props.centred ? `
            text-align: center;
            flex:1;
        ` : ``
    }
`; 

export const LabelText = styled.div.attrs()`
    padding: 1px 8px;
    border-radius: 10px;
    border-width: 1px;
    margin-bottom: 12px;
`; 

export const Text = styled.div.attrs()`
    font-size: 14px;
    ${ props => (props.bold || props.black) ? 
        `color: ${ props.theme.black };  ` : `color: ${ props.theme.text };  ` 
    }
    ${
        p => p.nobottom ? `` : `
            margin-bottom: 10px;
        `
    }
`; 

export const RankingItem = styled.div.attrs()` 
    box-shadow: 0px 0px 0px ${ p => p.theme.palette.colors.shadow };
    background-color: ${ p => p.theme.palette.colors.white };
    padding: 10px 14px;
    flex-direction: row; 
    margin-bottom: 12px
    border-radius: 5px;
    

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`; 

export const RowVCenter = styled.div.attrs()` 
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const RankingItemInfo = styled.div.attrs()` 
    padding-bottom: 12px;
    flex:1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`; 


export const CircleImage = styled.div.attrs({
})` 
    background: url(${ p => p.source?.uri }) no-repeat center center / contain;
    width: 42px;
    height: 42px;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 8px;
    ${
        props => props.spaced ? `
            margin-vertical: 10px;
        ` : ``
    }
    ${
        props => props.big ? `
            width: 220px;
            height: 220px;
            margin: 0 auto;
        ` : ``
    }
`; 

export const QrButton = styled.div.attrs()`   
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: ${ p => p.theme.palette.colors.shadow };

    align-items: center;
    justify-content: center;
`; 

export const ContainerAsk = styled.div.attrs()`   
    ${ props => props.mtop ? `
        padding: 24px 12px 24px;
    ` : `
        padding: 6px 12px 24px;
    ` }    
`; 

export const ContainerAnswer = styled.div.attrs()`   
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 12px 24px; 
    gap: 24px;
    max-width: ${ WindowScreen.width }px;
    margin: 0 auto;
`; 

export const ContainerAnswerWrapper = styled.div.attrs()`   
    flex: 1;
    width: 100%; 
    margin-bottom: 30px;
`; 
 

export const ContainetGameProfile = styled.div.attrs()`   
    width: 120px;
    height: 120px;
    border-radius: 16px;
    background-color: ${ p => p.theme.palette.colors.shadow }; 
`; 


export const RowInfo = styled.div.attrs()`   
    min-width: 240px;
    max-width: 240px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
`; 

export const ContentInfo = styled.div.attrs()`   
    padding: 40px 30px 30px;
    align-items: center;
`; 

export const FeedbackIcon = styled.div.attrs()`   
    margin-horizontal: 10px;
    margin-top: 2px;
`; 

export const Rate = styled.div.attrs()`
    font-size: 48px; 
    margin-top: 40px;
    margin-bottom: 5px;
    color: ${ p => p.theme.palette.colors.black };  
`; 

export const RowInfoText = styled.div.attrs()`
    ${ props => props.bold ? `
        font-size: 14px;
        color: ${ p => p.theme.palette.colors.black };  
    ` : `
        font-size: 14px;
        color: ${ p => p.theme.palette.colors.text };  
    ` };
`; 


export const TextBottom = styled.div.attrs()`
    font-size: 12px;
    color: ${ p => p.theme.palette.colors.text };  
    margin: 24px auto 36px;
    max-width:220px;
    text-align: center;
`; 




export const DescriptionAnimation = styled(Animation).attrs(p => ({
    height: 120,
    width: "100%",
    animationData: p.source
}))`            
    width: 120px;
    height: 120px;
`;   