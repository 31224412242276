import styled from 'styled-components' 

export const HowWorksContainer = styled.div.attrs({ 
})`          
    position: relative;
    margin: 25vh 0 0;

    background: ${ props => props.theme.palette.colors.backgrounddark };
    display flex;
    flex-direction: column;
`;
   
export const HowWorksDecoration = styled.img.attrs({ 
    src:`/icons/circle-1.svg`
})`           
    width: 539px;
    position: absolute;
    top: 60px;
    left: -308px;
`;

export const HowWorksTitleContent = styled.div.attrs({ 
})`            
    padding: 60px 0;
`;
   

export const HowWorksTitle = styled.div.attrs({ 
})`            
    font-size: 32px;
    color: ${ props => props.theme.palette.secondary.main };
    font-weight: bold;
`;

export const HowWorksText = styled.div.attrs({ 
})`            
    font-size: 20px;
    color: ${ props => props.theme.palette.colors.grey };
`;
   



export const BannerTitle = styled.div.attrs({ 
})`           
    margin: 15vh 0 30px;
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 72px;
    line-height: 84px;
    font-weight: 900;
    @media(max-width: 600px){
        font-size: 32px;
        line-height: 44px;
    }
`; 


export const BannerText = styled.div.attrs({ 
})`           
    color: ${props => props.theme.palette.colors.white }; 
    text-align: center;
    font-size: 32px;
    font-weight: 700;
`; 


export const BannerButton = styled.div.attrs({ 
})`           
    width: 100%;
    max-width: 320px;
    margin: 7vh auto;
`; 