import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 32px;
    font-weight: bold;
    
    margin-bottom: 24px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const CardHome = styled.div.attrs({ 
})`
    min-height: 110px;
    padding: 24px 12px 12px;
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin: 0 0 24px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const CardHomeTitle = styled.div.attrs({ 
})`
    font-size: 22px;
`;

export const DragContent = styled.div.attrs({ 
})`
    height: 100%;
    max-height: 44lvh;
    width: 100%;
    overflow: auto;
    margin-bottom: 28px;
`;

export const DropableContent = styled.div.attrs({ 
})`
    height: 100%;
    max-height: 44lvh;
    width: 100%;
    overflow: auto;
    border: 4px solid transparent;
    border-left: 4px solid ${ p => p.theme.palette.colors.backgrounddark };
    border-style: dashed;
    margin-left: -12px;
    padding-left: 12px;
    margin-bottom: 28px;
`;

export const DropContent = styled.div.attrs({ 
})`
    height: 100%;
    max-height: 44lvh;
    width: 100%;
    border: 4px solid ${ p => p.isOver ? p.theme.palette.colors.backgrounddark : p.theme.palette.colors.lightshadow };
    border-style: dashed;
    overflow: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
`;


export const DropContentText = styled.div.attrs({ 
})`
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    max-width: 320px;
`;

export const UploadedImage = styled.img.attrs({ 
})`
    width: 180px;
    border-radius: 90px;
    aspect-ratio: 4 / 4;
    object-fit: cover;
    margin: 0 auto 48px;
`;


















export const StudentImage = styled.img.attrs({ 
    width: "100%"
})`
    max-width: 180px;
    aspect-ratio: 1 / 1;
    border-radius: 90px;
    object-fit: cover;
    margin-bottom: 18px;
`;

export const StudentRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;
`;

export const StudentStats = styled.div.attrs({ 
})`
    font-size: 18px;
`;

export const StudentRate = styled.div.attrs({ 
})`
    font-size: 78px;
    font-weight: 700;
`;

export const DashboardOptionsRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 0 0 32px;
`;





























export const RowStudent = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid ${p=>p.theme.palette.colors.lightshadow};
    padding: 12px 0;
`;
export const RowStudentImage = styled.div.attrs({ 
})`
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: ${p=>p.theme.palette.colors.lightshadow}  url(${ p => p.image }) no-repeat center center / cover;
`;
export const RowStudentContent = styled.div.attrs({ 
})`
    ${
        p=>p.grow?`
            flex:1;
        `:``
    }
`;
export const RowStudentContentTitle = styled.div.attrs({ 
})`
    font-size: 16px;
    margin-bottom: 4px;
`;
export const RowStudentContentInfos = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    gap: 18px;
`;
export const RowStudentContentInfosContent = styled.div.attrs({ 
})`
    ${
        p => p.grow ? `
            flex:1;
        ` : ``
    }
`;
export const RowStudentContentInfosContentTitle = styled.div.attrs({ 
})`
    font-size: 12px;
`;
export const RowStudentContentInfosContentText = styled.div.attrs({ 
})`
    font-size: 18px;
`;  

export const ContentStudents = styled.div.attrs({ 
})`
    padding: 18px 0;
    max-height: 70vh;
    overflow: auto;
`;  

export const RemoveIcon = styled.img.attrs({ 
    src: '/icons/trash-white.svg'
})`
`;  









export const RowStudents = styled.div.attrs({ 
})` 
    display: flex;
    align-items: center;
    padding: 12px 0;
    gap: 6px;
`;

export const CardStudentItem = styled.div.attrs({ 
})` 
    width: 36px;
    min-width: 36px;
    height: 36px;
    border-radius: 18px;
    margin-left: -18px;
    background: ${p => p.theme.palette.colors.shadow } url(${p=>p.image}) no-repeat center center / cover;
    &:first-child{
        margin-left: 0;
    }
`;

export const CardQuestionTitle = styled.div.attrs({ 
})`
    font-size: 18px;
    ${
        p => p.small ? `
            font-size: 12px;
        ` : `
        `
    } 
    overflow: hidden;
    width: 100%;
    background: url(${ p => p.image }) no-repeat center center / cover ;
`;