import React, { useMemo, useState } from 'react'

import { 
    Spacer,
    Question,  
    QuestionImage,
} from 'ui/styled'

import Button from 'components/Form/CustomButton'  

export default function Content(props){ 

    const [typed, setTyped] = useState(true)

    const selectAnswer = (answer) => {
        if(props.response){
            props.response({
                question: props.question,
                answer
            })
        }
    }

    const releaseAnswer = () => {
        setTyped(true)
    }

    return (
        <> 
            { props.question?.image ? <QuestionImage source={ props.question?.image } /> : null }
            { props.question.question ? <Question>{ props.question.question }</Question> : null }
            <Spacer />
            {
                !typed && !props.question?.image ? null : 
                <div>
                    <Button delay={( 600 )} onClick={() => selectAnswer({ answer:'Assistiu Conteúdo', correct: true })} >
                        Continuar
                    </Button> 
                </div>
            }
        </>
    )
}