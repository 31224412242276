import styled from 'styled-components'


export const Container = styled.div.attrs()` 
    margin: 24px 0;
    padding: 12px 24px;
    border: 1px dashed ${ p => p.theme.palette?.colors?.black };  
`; 

export const Title = styled.div.attrs()`

    font-size: ${ props => props.big? `16px` : `18px` }; 
    color: ${ p => p.theme.palette?.colors?.black };  
    ${ props => props.big ? `

        margin-bottom: 24px;
    ` : `
        margin-bottom: 5px;
    
    ` }; 
`; 

export const Text = styled.div.attrs({
    numberOfLines:1
})`

    font-size: 14px;
    color: ${ p => p.theme.palette?.colors?.black };  
    margin-bottom: 3px;
`; 

export const RankingItem = styled.div.attrs()` 
    box-shadow: 0px 0px 0px ${ p => p.theme.palette?.colors?.shadow };
    background-color: ${ p => p.theme.palette?.colors?.white };
    padding: 10px 14px;
    flex-direction: row; 
    margin-bottom: 12px
    border-radius: 5px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`; 

export const RowVCenter = styled.div.attrs()` 
    flex-direction: row;
    align-items: center;
    flex:1;
`

export const RankingItemInfo = styled.div.attrs()` 
    padding-left: 12px;
    flex:1;
`;  
export const QrButton = styled.div.attrs()`   
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: ${ p => p.theme.palette?.colors?.lightshadow };

    align-items: center;
    justify-content: center;
`; 


export const ContainetGameProfile = styled.div.attrs()`   
    width: 120px;
    height: 120px;
    border-radius: 16px;
    background-color: ${ p => p.theme.palette?.colors?.lightshadow }; 
`; 


export const CircleImage = styled.div.attrs()` 
    width: 35px;
    height: 35px;
    border-radius: 8px;
    background-color: ${ p => p.theme.palette?.colors?.black };
    overflow: hidden;
    margin-left: 16px;
    background: url(${ p => p.source?.uri }) no-repeat center center / cover;
`; 


export const RowInfo = styled.div.attrs()`   
    min-width: 240px;
    max-width: 240px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
`; 

export const ContentInfo = styled.div.attrs()`   
    padding: 40px 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`; 

export const FeedbackIcon = styled.div.attrs()`   
    margin-left: 4px;
    margin-top: -4px;
`; 

export const Rate = styled.div.attrs()`

    font-size: 48px; 
    margin-top: 40px;
    margin-bottom: 5px;
    color: ${ p => p.theme.palette?.colors?.black };  
`; 

export const RowInfoText = styled.div.attrs()`
    ${ props => props.bold ? `

        font- 14px;
        color: ${ props.theme.black };  
    ` : `

        font-size: 14px;
        color: ${ props.theme.black };  
    ` };
`; 


export const TextBottom = styled.div.attrs()`

    font-size: 12px;
    color: ${ p => p.theme.palette?.colors?.black };  
    margin: 24px auto 36px;
    max-width:220px;
    text-align: center;
`; 
