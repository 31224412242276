import styled from 'styled-components'

import { WindowScreen } from 'ui/styled'

export const CardAnswer = styled.div.attrs({})`
    font-size: 14px;
    color: ${ props => props.theme.palette.colors.black };
    text-align: center;
    padding: 5px;
`; 

export const ContainerGamer = styled.div.attrs({})`
    position:relative;
`; 

export const CardImage = styled.div.attrs({})`
    background: url(${ p => p.source?.uri }) no-repeat center center / cover;
    width: ${ WindowScreen.width * .23 }px;
    height: ${ WindowScreen.width * .23 }px;
    border-radius: 8px;
    overflow: hidden;
`;


export const CardAssoc = styled.div.attrs({})`
    width: ${ WindowScreen.width * .25 }px;
    height: ${ WindowScreen.width * .25 }px;
    border-radius: 8px;
    margin-top: ${ WindowScreen.width * .052 }px;
    
    border-color: ${ 
        props => 
        props.preresponse ? props.theme.palette.colors.yellow : 
        props.response === 'correct' ? props.theme.palette.colors.green : 
        props.response === 'wrong' ? props.theme.palette.colors.red : 
    
        props.complete ? props.theme.palette.colors.grey : props.theme.palette.colors.black                    
    };
    border-width: 1px;
    border-style: dashed;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.05);
    }
`;
 
export const CardLine = styled.div.attrs({})`
    position: absolute;
    border-color: ${ props => props.response === 'correct' ? props.theme.palette.colors.green : props.response === 'wrong' ? props.theme.palette.colors.red : props.theme.palette.colors.yellow };
    border-width: 1px;
    border-style: dashed;
    ${
        props => 
        props.up ? 
            props.up === 1 ? `
                width: ${ WindowScreen.width * .4 }px;
                bottom: ${ WindowScreen.width * .27 }px;
                left: ${ WindowScreen.width * ( WindowScreen.landscape ? .21 : .245) }px;
                transform: rotate(-40deg);
            ` : 
            props.up === 2 ? `
                width: ${ WindowScreen.width * .65 }px;
                bottom: ${ WindowScreen.width * .42 }px;
                left: ${ WindowScreen.width * (WindowScreen.landscape ? .08 : .12 ) }px;
                transform: rotate(-61deg);
            ` : 
            props.up === 3 ? `
                width: ${ WindowScreen.width * .92 }px;
                bottom: ${ WindowScreen.width * .57 }px;
                left: -${ WindowScreen.width * (WindowScreen.landscape ? .01 : .09) }px;
                transform: rotate(-70deg);
            ` : `
                width: ${ WindowScreen.width * .30 }px;
                bottom: ${ WindowScreen.width * .125 }px;
                left: ${ WindowScreen.width * (WindowScreen.landscape ? .26 : .305) }px;
            `
        : 
        props.down ? 
            props.down === 1 ? `
                width: ${ WindowScreen.width * .4 }px;
                top: ${ WindowScreen.width * .32 }px;
                left: ${ WindowScreen.width * (WindowScreen.landscape ? .21 : .27) }px;
                transform: rotate(40deg);
            ` : 
            props.down === 2 ? `
                width: ${ WindowScreen.width * .65 }px;
                top: ${ WindowScreen.width * .47 }px;
                left: ${ WindowScreen.width * (WindowScreen.landscape ? .08 : .13) }px;
                transform: rotate(61deg);
            ` : 
            props.down === 3 ? `
                width: ${ WindowScreen.width * .92 }px;
                top: ${ WindowScreen.width * .62 }px;
                left: -${ WindowScreen.width * (WindowScreen.landscape ? .01 : .09) }px;
                transform: rotate(70deg);
            ` : `
                width: ${ WindowScreen.width * .30 }px;
                bottom: ${ WindowScreen.width * .125 }px;
                left: ${ WindowScreen.width * (WindowScreen.landscape ? .26 : .32) }px;` 
        :  `
            width: ${ WindowScreen.width * .30 }px;
            bottom: ${ WindowScreen.width * .125 }px;
            left: ${ WindowScreen.width * (WindowScreen.landscape ? .26 : .305) }px;
        `
    }
`;


 
