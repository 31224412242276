import React, { useEffect, useState } from 'react'

import { 
    Question,   
    WrapContainer,
    MemoryImageFeedback
} from 'ui/styled'

import { 
    shuffle
} from 'utils'

import MemoryImage from 'components/Game/Modes/Memory/Image'  

// import { soundSuccess, soundError } from '@utils/audio';

export default props => { 

    const [options, setOptions] = useState([])
    const [finded, setFinded] = useState([])
    const [errors, setErrors] = useState([])
    const [close, setClose] = useState([])
    const [preResponse, setPreResponse] = useState(null)
    const [gameable, setGameable] = useState(true)

    const selectAnswer = (answer, key) => {
        if(gameable)
        if(preResponse){
            if(preResponse.answer?.id === answer.id){ 
                // soundSuccess()
                verifyEnd([ key, preResponse.key ])
            }else{ 
                // soundError()
                setClose([ key, preResponse.key ])
                setErrors([ ...errors, answer.id ])
            }
            setPreResponse(null)
        }else{
            setPreResponse({ answer, key })
        }
    }
 
    const verifyEnd = (correct) => {
        const _finds = [...finded, ...correct]
        if(_finds.length === options.length){
            finalizeMemoryGame()
        }
        setFinded(_finds)
    }

    const finalizeMemoryGame = (answer) => {
        if(props.response){ 

            // tratar mensagem pelo numero de erros?

            props.response(props.atTime ? {
                question: { ...props.question, feedback: 'Parabéns' },
                answer:{ correct:true, type:'memory', options, finded, small:props.question.small }
            } : {
                question: { ...props.question, feedback: `Você deveria ter concluído em no maximo ${ props.question.time }s` },
                answer:{ correct:false, type:'memory', options, finded, small:props.question.small }
            })
        }
    }

    useEffect(() => {
        if(props.question?.options){
            let shuffledOptions = shuffle([
                ...props.question.options, 
                ...props.question.options
            ])
            setOptions(shuffledOptions) 
        }
    }, [props.question?.options])


    useEffect(() => {
        if(props.feedback?.options){ 
            setOptions(props.feedback?.options) 
            setFinded(props.feedback?.finded)
            setGameable(false) 
        }
    }, [props.feedback?.options])

    useEffect(() => {if(close.length > 0){ setClose([]) ;}}, [close])

    return (
        <> 
            { props.question?.question ? <Question>{ props.question.question }</Question> : null }
            {/* <Spacer /> */}
            <WrapContainer>
                {
                    options?.map((mit, mik) => <div key={mik}>
                            {   mit.image ? 
                                    props.feedback ?
                                        <MemoryImageFeedback small={props.feedback?.small} source={mit.image} />
                                    :
                                        <MemoryImage small={props.question?.small} wrong={close.includes(mik)} delay={( 600 + ( (props.question?.small ? 50 : 100) * mik ) )} source={mit.image} onClick={() => selectAnswer(mit, mik)} /> 
                                : null 
                            }
                        </div>
                    )
                }  
            </WrapContainer>
        </>
    )
}