import { royalThemes } from "ui/theme-color"

export const optionsStates = [
    { id:1, title:'AC', name:'Acre' },
    { id:2, title:'AL', name:'Alagoas' },
    { id:3, title:'AP', name:'Amapa' },
    { id:4, title:'AM', name:'Amazonas' },

    { id:5, title:'BA', name:'Bahia' },
    { id:6, title:'CE', name:'Ceara' },
    { id:7, title:'DF', name:'Distrito Federal' },
    { id:8, title:'ES', name:'Espirito Santo' },
    { id:9, title:'GO', name:'Goias' },
    { id:10, title:'MA', name:'Maranhão' },

    { id:11, title:'MT', name:'Mato Grosso' },
    { id:12, title:'MS', name:'Mato Grosso do Sul' },
    { id:13, title:'MG', name:'Minas Gerias' },
    { id:14, title:'PA', name:'Pará' },
    { id:15, title:'PB', name:'Paraíba' },
    
    { id:16, title:'PR', name:'Paraná' },
    { id:17, title:'PE', name:'Pernambuco' },
    { id:18, title:'PI', name:'Piauí' },
    { id:19, title:'RJ', name:'Rio de Janeiro' },
    
    { id:20, title:'RN', name:'Rio Grande do Norte' },
    { id:21, title:'RS', name:'Rio Grande do Sul'  },
    { id:22, title:'RO', name:'Rondônia'  },
    { id:23, title:'RR', name:'Roraima' },
    
    { id:24, title:'SC', name:'Santa Catarina' },
    { id:25, title:'SP', name:'São Paulo' },
    { id:26, title:'SE', name:'Sergipe' },
    { id:27, title:'TO', name:'Tocântins' },
]


export const mockedQuestions = [ 
    { type:"rightwrong", title:"Certo . Errado", image:"/images/games/g2.jpg" },
    { type:"multichoice", title:"Multipla . Escolha", image:"/images/games/g3.jpg" },
    { type:"association", title:"Associação", image:"/images/games/g4.jpg" },
    { type:"memory", title:"Jogo da memória", image:"/images/games/g5.jpg" },
    { type:"gibbet", title:"Jogo da forca", image:"/images/games/g7.jpg" },
    { type:"crossword", title:"Palavras cruzadas", image:"/images/games/g7.jpg" },
    { type:"content", title:"Conteúdo", image:"/images/games/g8.jpg" },
]


export const optionsLevel = [ 
    { id:"veryeasy", title:"Muito Fácil" },
    { id:"easy", title:"Fácil" },
    { id:"regular", title:"Normal" },
    { id:"hard", title:"Difícil" },
    { id:"veryhard", title:"Muito Difícil" },
]

export const optionsTheme = [ 
    { id:"purple", title:"Grape Lollipop", theme: royalThemes?.[4] },
    { id:"pink", title:"Pink Fizz", theme: royalThemes?.[5] },
    { id:"green", title:"Lime Twist", theme: royalThemes?.[2] },
    { id:"orange", title:"Tangerine Twist", theme: royalThemes?.[8] },
    { id:"red", title:"Cherry Bomb", theme: royalThemes?.[3] },
    { id:"blue", title:"Blueberry Pop", theme: royalThemes?.[1] },
    { id:"pinkness", title:"Raspberry Rush", theme: royalThemes?.[6] },
    { id:"greeness", title:"Lime Drops", theme: royalThemes?.[7] },
    { id:"black", title:"Charcoal Chew", theme: royalThemes?.[0] },
    { id:null, title:"Aleatório", theme: { background: `url(/icons/shuffle.svg) no-repeat center center / 50%; border: 1px solid rgba(0,0,0,.1)` } },
]


export const optionsStatusPay = [ 
    { id:"pending", title:"Pendente" },
    { id:"paid", title:"Pago" },
    { id:"refused", title:"Recusado" },
    { id:"refounded", title:"Reembolsado" },
]