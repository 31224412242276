import React, { useState, useEffect } from 'react'

import { 
    Spacer,
    Question, 
    QuestionImage,
    Row,
    RowBetween
} from 'ui/styled'

import { 
    CardAssoc,
    CardLine,
    CardImage,
    CardAnswer,
    ContainerGamer
} from './styled'

import Button from 'components/Form/CustomButton'   

import { 
    shuffle
} from 'utils'

export default function Association(props) { 

    
    const [options, setOptions] = useState([])
    const [assoc, setAssoc] = useState({})
    const [preresponse, setPreresponse] = useState(null)
    const [confirmed, setConfirmed] = useState(false)
    const [gameable, setGameable] = useState(true)

    const confirmResponse = () => {
        if(props.response){
            setConfirmed(true)
            const correct = ( Object.keys(assoc).filter((fit) => {
                return verifyResponse(assoc[fit].pre) === 'correct'
            }).length === Object.keys(assoc).length )
            props.response({
                question: { ...props.question, feedback: correct ? 'Parabéns, você acertou!' : 'Você errou, não desanime!' },
                answer:{ correct, type:'association', assoc, options }
            })
        }
    }

    const addPreresponse = ({ mit, mik }) => { 
        if(gameable && !confirmed){
            setPreresponse({ mit, mik })
        }
    }

    const completeResponse = ({ mit, mik }) => {
        if(!confirmed && preresponse){
            let _assoc = mik == preresponse.mik ? {} : 
                    preresponse.mik < mik ? {
                        down:( mik - preresponse.mik )
                    } :
                    preresponse.mik > mik ? {
                        up:( preresponse.mik - mik )
                    } : {} 
            setAssoc({ ...assoc, [preresponse.mik]:{ ..._assoc, pre:preresponse.mik, complete:mik, preresponse:preresponse.mit, response:mit } })
            setPreresponse(null)
        }
    }

    const verifyComplete = mik => {
        let completed = false
        Object.keys(assoc).map( ak => { if(assoc[ak].complete === mik){ completed = `${ ak }` ;} } )
        return completed
    }

    const releaseVerify = mik => {
        if( assoc[mik].preresponse.key.key == assoc[mik].response.associate.associate ){
            return 'correct'
        }
        return 'wrong'
    }

    const verifyResponse = mik => {
        if( assoc[mik] ){  
            return releaseVerify(mik)
        }
        return ''
    }

    const verifyResponseComplete = mik => {
        if(confirmed){ 
            let ak = verifyComplete(mik)
            if( ak && assoc[ak] ){  
                return releaseVerify(ak)
            }
        }
        return ''
    }

    useEffect(() => {
        if(props.question?.options){
            const shuffledKeys = shuffle([
                ...props.question.options.filter( fit => fit.key )
            ])
            const shuffledAssociate = shuffle([
                ...props.question.options.filter( fit => fit.associate )
            ])
            const shuffledOptions = shuffle([
                ...shuffledKeys.map((mit, mik) => ( { key: mit, associate: shuffledAssociate[mik] } ))
            ])
            setOptions(shuffledOptions) 
        }
    }, [props.question?.options])

    useEffect(() => {
        if(props.feedback?.options){ 
            setOptions(props.feedback?.options) 
            setAssoc(props.feedback?.assoc)
            setGameable(false)
            setConfirmed(true)
        }
    }, [props.feedback?.options])

    return (
        <> 
            { props.question?.question ? <Question>{ props.question.question }</Question> : null }
            { props.question?.image ? <QuestionImage source={ props.question?.image } /> : null }
        
            <ContainerGamer>
                {
                    options.map(( mit, mik ) => {
                        const itemKey = mit.key
                        const itemAssoc = mit.associate
                        const responsed = (confirmed && verifyResponse(mik))
                        return (
                            <RowBetween key={mik}>
                                <CardAssoc onClick={() => addPreresponse({ mit, mik })} preresponse={preresponse?.mik === mik} complete={assoc[mik]} response={responsed}>
                                    { itemKey.image ? <CardImage source={ itemKey.image } /> : null }
                                    { itemKey.answer ?  <CardAnswer>{ itemKey.answer }</CardAnswer> : null }
                                </CardAssoc>
                                    { assoc[mik] ? <CardLine { ...assoc[mik] } response={responsed} />  : null }
                                <CardAssoc onClick={() => completeResponse({ mit, mik })} complete={ verifyComplete(mik) } response={verifyResponseComplete(mik)}>
                                    { itemAssoc.image ? <CardImage source={ itemAssoc.image } /> : null }
                                    { itemAssoc.answer ?  <CardAnswer>{ itemAssoc.answer }</CardAnswer> : null }
                                </CardAssoc>
                            </RowBetween>
                        )
                    })
                }
            </ContainerGamer>
            
            <Spacer />
            {
                Object.keys(assoc).length === options.length && gameable && !confirmed ? 
                    <Row>
                        <Button row onClick={confirmResponse}>Confirmar</Button>
                    </Row>
                : null
            }
        </>
    )
}