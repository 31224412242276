import styled from 'styled-components'

import { WindowScreen } from 'ui/styled'

export const ImageIn = styled.div.attrs({
    animation:'flipInY'
})`  
`; 

export const CoverIn = styled.div.attrs({
    animation:'bounceInUp'
})`  
    width: 100%;
    height: 100%;

    animation: fadeIn 1s forwards; 

    @keyframes fadeIn {
        0% {
            opacity: 0; 
        }
        100% {
            opacity: 1; 
        }
    }
`; 
export const CoverOut = styled.div.attrs({
    animation:'bounceOut'
})`   
    width: 100%;
    height: 100%;

    animation: fadeOut 1s forwards; 

    @keyframes fadeOut {
        0% {
            opacity: 1; 
        }
        100% {
            opacity: 0; 
        }
    }
`; 




export const MemoryImage = styled.div.attrs({
    resizeMode: 'cover'
})`
    background: url(${ p => p.source?.uri }) no-repeat center center / cover;
    margin-top: 20px;
    width: ${ props => WindowScreen.width * ( props.small ? .15 : .2 ) }px;
    height: ${ props => WindowScreen.width * ( props.small ? .15 : .2 ) }px;
    border-radius: 5px;
    overflow: hidden;  
    justify-content:center;

    position: relative;
    display: flex;
    flex-direction: column;
`;


export const MemoryCover = styled.div.attrs({
    resizeMode: 'cover'
})`
    background: url(${ p => p.source?.uri }) no-repeat center center / cover;
    width: 100%;
    height: 100%;
    background-color: ${ p => p.theme.palette.colors.white };

    position: relative;
    display: flex;
    flex-direction: column;

    align-items:center;
    justify-content:center;
    border-width: .5px;
    border-radius: 5px;
    border-color: ${ p => p.theme.palette.colors.black };

    elevation: 1;
    box-shadow: 0px 0px 2px ${ p => p.theme.palette.colors.shadow };
`;
