import React, { useMemo } from 'react'

import { 
    Spacer,
    Question,  
    QuestionImage,
    WrapContainer,
} from 'ui/styled'

import Button from 'components/Form/CustomButton'  
import ButtonImage from 'components/Game/ButtonImage'  

import { shuffle } from 'utils/index'

export default function MultiChoice(props){ 

    const selectAnswer = (answer) => {
        console.log("WA", answer, props)
        if(props.response){
            props.response({
                question: props.question,
                answer
            })
        }
    }

    const options = useMemo(() => {
        return shuffle(props?.question?.options || [])
    }, [props?.question?.options])

    return (
        <> 
            { props.question.question ? <Question>{ props.question.question }</Question> : null }
            { props.question?.image ? <QuestionImage source={ props.question?.image } /> : null }
            <Spacer />
            {
                options?.filter( fit => fit.image).length > 0 ? 
                    <WrapContainer>
                        {
                            options?.map((mit, mik) => <div key={mik}>
                                    { mit.answer ? <Button delay={( 600 + ( 300 * mik ) )} onClick={() => selectAnswer(mit)} >{mit.answer}</Button> : null }
                                    { mit.image ? <ButtonImage delay={( 600 + ( 300 * mik ) )} source={mit.image} onClick={() => selectAnswer(mit)} /> : null }
                                </div>
                            )
                        }  
                    </WrapContainer>
                : 
                    <div>
                        {
                            options?.map((mit, mik) => <div key={mik}>
                                    { mit.answer ? <Button delay={( 600 + ( 300 * mik ) )} onClick={() => selectAnswer(mit)} >{ mit.answer }</Button> : null }
                                    { mit.image ? <ButtonImage delay={( 600 + ( 300 * mik ) )} source={mit.image} onClick={() => selectAnswer(mit)} /> : null }
                                </div>
                            )
                        }  
                    </div>
            }
        </>
    )
}