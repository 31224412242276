import React, { useCallback, useContext, useEffect, useState } from "react";  

import {   
    DashboardText, DragContent, DropContent, DropContentText, DropableContent
} from "./styled";

import { Row, Col } from "reactstrap";

import { useDrop } from "react-dnd";
import { CoreContext } from "context/CoreContext";

import CustomButton from "components/Form/CustomButton";
import CardLesson from "components/Cards/CardLesson";

import { Title } from "ui/styled";

export default function DraggerLessons({ questions, selectedQuestion, setSelectedQuestion, navigate }){  

    const { setDragging, dragging, setModal } = useContext(CoreContext)

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'card-lesson',
        drop: (item) => addQuestion(item),
        collect: (monitor) => ({
          isOver: monitor.isOver(), 
          canDrop: monitor.canDrop(), 
        })
    }),[selectedQuestion]);

    const addQuestion = useCallback(item => {
        setSelectedQuestion([ ...selectedQuestion, item ])
        setDragging(false)
    }, [selectedQuestion])

    const removeQuestion = useCallback(item => {
        setSelectedQuestion([ ...selectedQuestion?.filter(f => f?.id !== item?.id) ])
    }, [selectedQuestion])

    const filterQuestions = f => {
        return (
            (!selectedQuestion?.length || !selectedQuestion?.map(m => m?.id)?.includes(f?.id))
        )
    } 

    return ( 
        <> 
            {
                !questions?.length ? <>
                    <Title></Title>
                    <DashboardText>Crie exercícios para adiciona-los à turma</DashboardText>
                    <CustomButton onClick={() => {navigate("dashboard/creator/lessons"); setModal(null)}}>Criar exercício</CustomButton>
                </> : <Row>
                    <Col sm={6}> 
                        <DashboardText>Arraste os Exercícios para adiciona-los à turma</DashboardText>
                        <DragContent>
                            <Row>
                                    {
                                        questions?.filter(filterQuestions)?.map((item, key) => 
                                            <CardLesson key={key} item={item} big  doubleClick={() => addQuestion(item)} />  
                                        )
                                    }
                            </Row>
                        </DragContent>
                    </Col>

                    <Col sm={6}> 
                        <DashboardText>Exercícios adicionados ({selectedQuestion?.length})</DashboardText>
                        {
                            !dragging && selectedQuestion?.length ? <>
                                <DropableContent>
                                    <Row>
                                            {
                                                selectedQuestion?.map((item, key) => 
                                                    <CardLesson key={key} item={item} big onRemove={() => removeQuestion(item)} />  
                                                )
                                            }
                                    </Row>
                                </DropableContent>
                            </> : 
                                <DropContent ref={drop} isOver={isOver}>
                                    <DropContentText>
                                        { isOver ? "Solte o exercício aqui para adiciona-lo" : "Arraste o exercício aqui para adicionar à sua turma" }
                                    </DropContentText>
                                </DropContent>
                        }
                    </Col>
                </Row>  
            }
 
 
        </>
    );
}