import { GET, POST, PUT, DELETE } from './api'

const table = "classes"

export const Create = async (params) => {
    return await POST(`/${ table }`, params, true);
}

export const Read = async () => {
    return await GET(`/${ table }`, true);
}

export const ReadTeacherClasses = async (params) => {
    return await POST(`/pub-students/classes`, params, true);
}

export const ReadTeacherRanking = async (params) => {
    return await POST(`/pub-students/ranking`, params, true);
}

export const ReadTeacherStudent = async (params) => {
    return await POST(`/pub-students/login`, params, true);
}

export const ReadOne = async (id) => {
    return await GET(`/${ table }/${id}?populate[0]=image.url&populate[1]=lessons.image&populate[2]=students.avatar&populate[3]=lessons.questions.image`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/${ table }/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/${ table }/${ id }`, true);
} 
 
export const ReadByUser = async (id) => {
    return await GET(`/${ table }?populate[0]=image.url&populate[1]=lessons.image&populate[2]=students.avatar&populate[3]=lessons.questions.image&filters[owner]=${ id }&pagination[pageSize]=10000`, true);
}
