import { useEffect, useMemo } from "react";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ReadChartTeacher } from "services/core";
import { MobileHide } from "ui/styled";
import { mockedQuestions } from "utils/options";

export default function SlopeChart({ questions, allQuestions }){
    
    const [visible, setVisible] = useState(false)
    const [series, setSeries] = useState([])

    const options = useMemo(() => {
      return {
        series: series?.length ? series : [
          {
            name: 'Media',
            data: [
              {
                x: 'Turmas',
                y: 503,
              },
              {
                x: 'Exercicios',
                y: 580,
              },
              {
                x: 'Alunos',
                y: 135,
              },
            ],
          },
          {
            name: 'Performance',
            data: [
              {
                x: 'Turmas',
                y: 733,
              },
              {
                x: 'Exercicios',
                y: 385,
              },
              {
                x: 'Alunos',
                y: 715,
              },
            ],
          },
          {
            name: 'Recompensas',
            data: [
              {
                x: 'Turmas',
                y: 255,
              },
              {
                x: 'Exercicios',
                y: 211,
              },
              {
                x: 'Alunos',
                y: 559,
              },
            ],
          },
          {
            name: 'Tempo',
            data: [
              {
                x: 'Turmas',
                y: 428,
              },
              {
                x: 'Exercicios',
                y: 749,
              },
              {
                x: 'Alunos',
                y: 441,
              },
            ],
          },
        ],
          chart: {
          height: 350,
          width: "100%",
          type: 'line',
        },
        plotOptions: {
          line: {
            isSlopeChart: true,
          },
        },
        tooltip: {
          followCursor: true,
          intersect: false,
          shared: true,
        },
        dataLabels: {
          background: {
            enabled: true,
          },
          formatter(val, opts) {
            const seriesName = opts.w.config.series[opts.seriesIndex].name
            return val !== null ? seriesName : ''
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        xaxis: {
          position: 'bottom',
          show: false,
          labels: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'left',
        },
        stroke: {
          width: [2, 3, 4, 2],
          dashArray: [0, 0, 5, 2],
          curve: 'smooth',
        }
        }
    }, [series])
    
    const init = async () => {
      const result = await ReadChartTeacher()
      console.log("Founded Charts", result)
      setSeries(result)
    }

    useEffect(() => {
        // init()
        const suspense = setTimeout(() => { setVisible(true) ;}, 400)
        return () => { clearTimeout(suspense) ; }
    }, [])

    // console.log("questionTypes", questionTypes?.map(m => m.type || ""),  questionTypes?.map(m => (questions||[])?.filter(f => f?.type === m?.type)?.length || 0 ) )
 
    return !visible ? null : ( 
        <>
          <MobileHide>
            <ReactApexChart options={options} series={options.series} type="line" height={350} />
          </MobileHide>
        </>
    );
  }