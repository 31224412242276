import styled from "styled-components";

export const CompanyButtonContainer = styled.div.attrs({
})`
    width: 100%;
    display: flex;
    gap: 16px;
    border-top: 1px solid ${props => props.theme.palette.colors.border};
    padding-top: 24px;
`;

export const CloseContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const LogoContainer = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
`;

export const AppLogo = styled.img.attrs({ 
    src:`/logo1024.png`,
    alt:"logo-icon",
    height:120
})`          
    margin: 0 auto;  
`;