import React, { useEffect, useState } from "react"; 

import { Row, Col, Container } from 'reactstrap'; 

import {
    MoreContainer,
    MoreRow,
    
    BannerTitle,
    BannerText,
    MoreRegister,
    RoyalVideo,
    RoyalVideoContainer,
    RoyalVideoMobile,
    BannerGameImage
} from './styled'  
import Register from "screens/Authentication/Register";
import CardCommom from "../Card/Commom";
import DecorationEnd from "../DecorationEnd";
import CardLesson from "components/Cards/CardLesson";
import CardClassLesson from "components/Cards/CardClassLesson";
import { ReadRoyalGames } from "services/core";

export default function LittleMore(){ 
    
 
    const cardCommom = {
        image: "/images/print/12.png",
        title:"Interação Simplificada",
        text:"Na Royal Academy seus alunos não precisam digitar nada! Com apenas alguns toques na tela, os alunos podem acessar conteúdos, responder a atividades e participar de jogos educacionais.",
        supertitle:"Esta interface intuitiva",
        subtitle:" e amigável garante",
        subtext:"que mesmo os mais jovens possam aproveitar ao máximo o aprendizado digital sem frustrações.",
    }

    const secondCardCommom = {
        image: "/images/print/9.png",
        title:"Economia de Tempo",
        text:"A Royal Academy automatiza a correção de exercícios e permite o reaproveitamento de conteúdos",
        supertitle:"Mais tempo para você",
        subtitle:" .",
        subtext:" se concentrar no que realmente importa. Ensinar e inspirar seus alunos. ",
    }
 
    const thirdyCardCommom = {
        image: "/images/print/13.png",
        title:"Tecnologia Inovadora",
        text:"Utilize os recursos mais avançados para criar experiências de aprendizado dinâmicas e interativas",
        supertitle:"Garantindo um ensino mais eficaz",
        subtitle:" e envolvente",
        subtext:"para os alunos do ensino básico inicial ao avançado.",
    }

    const forthCardCommom = {
        image: "/images/print/11.png",
        title:"Evolução",
        text:"Monitore o desempenho dos seus alunos com relatórios e gráficos intuitivos",
        supertitle:"Identifique rapidamente",
        subtitle:" as áreas que precisam de mais atenção",
        subtext:"",
    }

    const [lessons, setLessons] = useState([])

    const init = async () => {
        const result = await ReadRoyalGames()
        console.log("FGAMEs", result)
        setLessons(result?.slice(0, 5))
    }

    useEffect(() => {
        init()
    }, [])


    return ( 
        <> 
            <MoreContainer>
                <MoreRow>
                    <Container>
                        <Row id="section-teacher">
                            <Col md={1}></Col>
                            <Col md={10}>
                                <MoreRegister>
                                    <Register flat />
                                </MoreRegister>
                            </Col>
                            <Col md={1}></Col>
                        </Row>


                        <Row  id="section-economy">
                            <Col>

                                <BannerTitle>Experimente alguns jogos</BannerTitle>
                                <BannerText> Assinando Royal Academy você poderá criar jogos com o conteúdo que você ensina, suas aulas vão ficar como os jogos abaixo! </BannerText>

                                <CardClassLesson item={{ lessons }} home />

                            </Col>
                        </Row>

                        <Row>
                            <Col>

                                <BannerTitle>Transforme a forma como se conecta com os alunos</BannerTitle>
                                {/* <BannerGameImage /> */}

                                <CardCommom square blacked card={cardCommom} />
                                <CardCommom square blacked reverse card={secondCardCommom} />
                                <CardCommom square blacked card={thirdyCardCommom} />
                                <CardCommom square blacked reverse card={forthCardCommom} />

                                <BannerTitle>A Royal Academy está pronta para revolucionar o seu ensino.</BannerTitle>
                                <BannerText>Explore as funcionalidades do nosso aplicativo e veja como é fácil transformar sua sala de aula em um ambiente de aprendizado inovador</BannerText>  
                            </Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                                <Col md={10}>
                                    <RoyalVideoContainer>
                                        <RoyalVideo />
                                        <RoyalVideoMobile />
                                    </RoyalVideoContainer>
                                </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Container>
                </MoreRow> 
                <DecorationEnd reverse white />
            </MoreContainer>
        </>
    );
}