import { CoreContext } from "context/CoreContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";  

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify"; 
import { ReadTeacherClasses, ReadTeacherRanking } from "services/classes";
import { ReadTeacherLessons } from "services/lessons";
import { StudentAccesss } from "services/me";
import { Create, Delete, ReadOne, Update } from "services/students";
import { exposeStrapiError, goSection, normalizeStrapiRegister } from "utils";
import { parseGameHome } from "utils/parsers";

export default function useController(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const params = useParams()

    const { user, setModal } = useContext(CoreContext)

    const [getting, setGetting] = useState(false)
    const [student, setStudent] = useState(null)

    const [showing, setShowing] = useState(false)
    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState(false) 
    
    const [manual, setManual] = useState(false)
    const [editing, setEditing] = useState(false)  
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 

    const saveForm = async () => {
        await save(form)
    }

    const save = async (ff, many) => {
        if(!ff?.name){ return ;}
        if(!many){ setSaving(true) ;}
        const payload = {
            owner: user?.id,
            name: ff?.name,
            description: ff?.description,
        }

        const result = editing ? await Update({ data: payload }, params?.id) : await Create({ data: payload })

        if(result && !exposeStrapiError(result)){
            toast.success("Aluno salvo com sucesso")
            if(!many){ 
                navigate(`dashboard/creator/students/${ result?.data?.id }`); 
                if(showing){
                    setShowing(false); 
                    init() ;
                }
            }else{
                return true;
            }
        }
        if(!many){ setSaving(false) ;}
    }

    const init = async () => {
        setLoading(true)
            const result = await ReadOne(params?.id)
            const normalResult = normalizeStrapiRegister(result)
            
            const nextForm = {
                ...normalResult
            }

            console.log("nextForm", nextForm)

            setForm(nextForm)
            setManual(true)
            setEditing(nextForm)

            if(nextForm?.accessCode){
                const lessons = (await ReadTeacherLessons({ code: nextForm?.accessCode }))
                const classes = await ReadTeacherClasses({ code: nextForm?.accessCode })
                const ranking = await ReadTeacherRanking({ code: nextForm?.accessCode })
                console.log("FOUNDED student profile", { lessons, classes, ranking })

                setStudent({ classes, lessons })
            }

        setLoading(false)
    }

    const studentAccess = async () => {
        setGetting(true)
        const result = await StudentAccesss({ student: params?.id })
        console.log("result access", result)
        if(result && !exposeStrapiError(result)){
            setModal({ type:"qr-code", code: result?.accessCode, student: editing })
            if(!editing?.accessCode){ init() ;}
        }
        setGetting(false)
    }

    const openModalLessonResponse = (currentLesson) => {
        console.log("currentLesson", currentLesson) 
        if(currentLesson?.response){
            setModal({
                type:"response",
                currentLesson
            })
        } else {
            setModal({ type:"game", currentLesson: parseGameHome(currentLesson), forfun:true })
        }
    }


    const handleRemove = () => {
        setModal({ 
            type:"confirm",
            title:"Remover aluno",
            text:"Deseja realmente remover o aluno? essa ação não pode ser desfeita",
            cancel:"Não remover",
            confirm:"Sim, remover aluno",
            onConfirm: () => remove()
        })
    }

    const remove = async () => {
        setLoading(true)
        const result = await Delete(editing?.id)
        if(result && !exposeStrapiError(result)){
            toast.info("Removido")
            navigate("dashboard/contentor/students")
        }
        setLoading(false)
    } 

    useEffect(() => {
        if(params?.id){ init() ;}
    }, [params])

    return { 
        loading,  
        saving,

        formValue,
        changeForm,
        
        save, 
        saveForm, 
  
        editing, 
        showing, setShowing,

        getting, studentAccess,
        student, openModalLessonResponse,

        navigate,
        handleRemove
    }
}