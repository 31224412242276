import { CoreContext } from "context/CoreContext";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";  

import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GenerateQuestions } from "services/ai";
import { Create, ReadOne, Update } from "services/questions";
import { exposeStrapiError, goSection, normalizeStrapiRegister } from "utils";

export default function useController(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const params = useParams()

    const { user } = useContext(CoreContext)

    const [saving, setSaving] = useState(false)
    const [loading, setLoading] = useState(false)
    const [thinking, setThinking] = useState(false)
    
    const [manual, setManual] = useState(false)
    const [editing, setEditing] = useState(false)
    const [generated, setGenerated] = useState([])
    const [selected, setSelected] = useState([])
    
    const [options, setOptions] = useState([])
    const [seeds, setSeeds] = useState([])
    
    const [ form, setForm ] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : '' ;}
    const changeForm = ( value, ref ) => { setForm({ ...form, [ref]: value }) ;} 


    const optionAdd = () => {
        setOptions([
            ...options,
            { id:`${ new Date().getTime() }`, answer:"", correct:false }
        ])
    }

    const optionRemove = (item) => {
        setOptions([ ...options.filter(f => f.id !== item?.id ) ])
    }

    const handleCorrect = key => {
        const payload = {}
        options?.forEach((f , k) => {
            payload[`correct${k}`] = false
        })
        payload[`correct${key}`] = true
        setForm({
            ...form,
            ...payload
        })
    }

    const haveCorrect = (opts, ff) => {
        return opts?.some((f , k) => f.correct )
    }

    const fillOptions = (opts, ff) => {
        return opts?.map((m, k) => ({
            answer: (ff?.[`answer${k}`]?.url ? null: ff?.[`answer${k}`])  || m?.answer,
            image: (ff?.[`answer${k}`]?.url ? ff?.[`answer${k}`]?.id : null) || m?.image,
            correct: ff?.[`correct${k}`] || m?.correct
        }))
    }

    const fillAIOptions = (opt, ff) => {
        return opt?.map((m, k) => ({
            answer: (ff?.[`answer${k}`]?.url ? null: ff?.[`answer${k}`])  || m?.answer,
            image: (ff?.[`answer${k}`]?.url ? ff?.[`answer${k}`]?.id : null) || m?.image,
            correct: ff?.[`correct${k}`] || m?.correct
        }))
    }

    const ctaIA = () => {
        if(manual){
            toggleManual()
            return;
        }
        document.getElementById("input-ai").focus()
    }

    const askAI = async () => {
        if(!thinking){
            setThinking(true)

            const payload = {
                "theme": form?.theme,
                "type": "multichoice",
                "quantity": 4
            }

            setSeeds([ ...seeds, form?.theme ])
    
            let result = await GenerateQuestions(payload)
    
            if(!result?.length){ result = await GenerateQuestions(payload) ;}
            if(!result?.length){ result = await GenerateQuestions(payload) ;}
            if(!result?.length){ result = await GenerateQuestions(payload) ;}
    
            if(!result?.length){
                setThinking(false)
                toast.error("Nossa IA encontrou dificuldades para gerar suas questões, tente novamente")
            }

            const momentH = moment().format("HHmm")
            if(typeof result?.map === 'function'){
                setGenerated([
                    // ...generated,
                    ...result?.map( (m, k) => ({ ...m, id: `${momentH}${k}` }))
                ])
            }
            setThinking(false)
        }
    }

    const acceptAISuggestion = item => {
        setForm({
            ...form, 
            ...item
        })
        toast.info("Revise a questão selecionada")
        goSection("form")
    }

    const isSelected = item => {
        return selected?.map(m => m.id)?.includes(item?.id)
    }

    const toggleSelected = item => {
        setSelected(
            isSelected(item) ? [
                ...selected?.filter(f => f.id !== item.id )
            ] : [ ...selected, { ...item } ]
        )
    }

    const updateSuggestion = item => {
        const opt = fillAIOptions(item?.options, item) 
        setGenerated([
            ...generated?.map(f => f.id !== item.id ? f : { ...f, ...item, options:opt })
        ])
    }

    const toggleManual = () => {
        setManual(!manual)
    }

    const saveForm = async () => {
        await save(form)
    }

    const saveMany = async () => {
        setSaving(true)

        // console.log("MANY", selected)

        const promises = selected?.map(m => save(m, true))
        const res = await Promise.all(promises)
        if( res?.filter(f => !!f)?.length ){
            toast.success("Conteúdo salvo com sucesso")
            navigate("dashboard/contentor/tasks")
        } 
        setSaving(false)
    }

    const save = async (ff, many) => {
        if(!ff?.question){ return ;}
        

        const opts = fillOptions(many ? ff?.options : options , ff)
        
        // console.log("OPTS", opts, ff?.options)

        if( !haveCorrect(opts, ff) ){
            toast.error("Informe a resposta correta para salvar!")
            return;
        }

        
        if(!many){ setSaving(true) ;}

        const payload = {
            type:"multichoice",
            time: 60,
            image: ff?.question?.url ? ff?.question?.id : null,
            question: ff?.question?.url ? null : ff?.question,
            feedback: ff?.feedback,
            options:[ ...opts ],
            order: 1,
            owner: user?.id,
            seed: form?.theme
        }

        const result = editing ? await Update({ data: payload }, params?.id) : await Create({ data: payload })

        if(result && !exposeStrapiError(result)){
            toast.success("Questão salva com sucesso")
            if(!many){ navigate("dashboard/contentor/tasks") ;}else{
                return true;
            }
        }
        if(!many){ setSaving(false) ;}
    }

    const init = async () => {
        setLoading(true)
            const result = await ReadOne(params?.id)
            const normalResult = normalizeStrapiRegister(result)

            const payload = {}

            normalResult?.options?.forEach((f,k) => {
                const normalImage = normalizeStrapiRegister(f?.image)
                payload[`answer${k}`] = normalImage?.url ? normalImage : f?.answer
                payload[`correct${k}`] = f?.correct
            })

            const nextForm = {
                ...normalResult,
                ...payload,
                question: normalResult?.image?.url ?  normalizeStrapiRegister(normalResult?.image) : normalResult?.question
            }

            setOptions(normalResult?.options)

            setForm(nextForm)
            setManual(true)
            setEditing(true)
        setLoading(false)
    }

    useEffect(() => {
        if(params?.id){ init() ;}
    }, [ params ])

    return {
        saving,
        loading, 
        thinking,

        formValue,
        changeForm,
        
        save,
        saveMany,
        saveForm,

        askAI,
        generated,
        acceptAISuggestion,
        
        toggleSelected,
        isSelected,
        selected,

        updateSuggestion,
        
        toggleManual,
        manual,
        editing,

        options,
        optionAdd,
        optionRemove,

        ctaIA,
        handleCorrect
    }
}