import React from 'react' 

import { 
    ButtonImageContent,
    ImageAnimation
} from './styled'

import { 
    Touch
} from 'ui/styled'
 

export default function ButtonImage(props){   
    return (
        <>     
            <Touch onClick={props.onClick||props.onPress}>
                <ImageAnimation delay={props.delay}>
                    <ButtonImageContent source={ props.source } />
                </ImageAnimation>
            </Touch> 
        </>
    )
}