import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'

import Wrapper from '../Wrapper';
import CustomButton from 'components/Form/CustomButton';

import GameCore from 'components/Game/Core';
import { Spacer } from 'ui/styled';
import { ContentGame } from './styled';

export default function ModalGame() {

  const { modal, setModal } = useContext(CoreContext)

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    // do something
    close()
  }

  return (
    <>
      <Wrapper mobile>
        <ContentGame>
          { modal?.currentLesson ? <GameCore lesson={modal?.currentLesson} forfun={modal?.forfun} student={modal?.student} /> : null }
        </ContentGame>
        {/* <CustomButton onClick={handleSave} between>Fechar</CustomButton> */}
        <Spacer />
      </Wrapper>
    </>
  )
}
