import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'
import { FormText, Title } from 'ui/styled'
import { CompanyButtonContainer } from './styled'
import Wrapper from '../Wrapper';
import Button from 'components/Form/Button';
import DraggerStudents from 'components/Draggers/Students';
import CustomButton from 'components/Form/CustomButton';
import { useEffect } from 'react';
import { useState } from 'react';
import DraggerLessons from 'components/Draggers/Lessons';

export default function ModalAddLessons() {

  const { modal, setModal } = useContext(CoreContext)

  const [selectedQuestion, setSelectedQuestion] = useState([])

  const close = () => {
    setModal(null)
  }

  const handleSave = () => {
    if(modal?.save && typeof modal?.save === 'function'){
      modal?.save(selectedQuestion)
    }
    close()
  }

  useEffect(() => { setSelectedQuestion(modal?.selectedQuestion||[]) ;}, [modal])

  return (
    <>
      <Wrapper big>

        <Title nomargin>Adicionar exercícios</Title>
        <DraggerLessons questions={modal?.questions} selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion} navigate={modal?.navigate} />
        {
          !modal?.questions?.length ? null : 
          <CompanyButtonContainer>
            <CustomButton onClick={handleSave} between>Salvar</CustomButton>
          </CompanyButtonContainer>
        }
      </Wrapper>
    </>
  )
}
