import React, { useContext, useEffect, useMemo, useRef, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    CardHome,
    CardHomeTitle,
    StudentRow,
    StudentImage,
    StudentStats,
    StudentRate,
    DashboardOptionsRow,
    PlayIcon,
    PaintIcon,
    ContentRowInfos,
    ContentRowInfosItem,
    ContentStudentClasses,
    WrapperThemes,
    ThemeColor,
    RowTheme
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import { ChartBar } from "components/Dashboard/Charts/Bar";
import { ChartCalendar } from "components/Dashboard/Charts/Calendar";
import { ChartLine } from "components/Dashboard/Charts/Line";
import { ChartPie } from "components/Dashboard/Charts/Pie";
import { ChartTimeline } from "components/Dashboard/Charts/Timeline";
import { ChartWordtree } from "components/Dashboard/Charts/Wordtree";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { exposeStrapiError, parseStrapiImage } from "utils";
import { optionsLevel, optionsTheme } from "utils/options";
import CustomButton from "components/Form/CustomButton";
import { CoinImage } from "ui/styled";
import { CoreContext } from "context/CoreContext";
import CardClass from "components/Cards/CardClass";
import { UpdateMe } from "services/me";
import { toast } from "react-toastify/dist";
import SlopeChart from "components/Dashboard/Charts/Slope";
import { parseCurrency, parseCurrencyMoney } from "utils/parsers";

export default function DashboardHome(){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`);

    const { user, setModal, reloadMe } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [showing, setShowing] = useState(false)

    const [cover, setCover] = useState(null)
    
    const triggerRef = useRef()
    const [trigger, setTrigger] = useState(0)

    const goodTry = () => {
        if(triggerRef.current) clearTimeout(triggerRef.current);

        if( trigger >= 21 ){
            changeCover(true)
            setTrigger(0)
            return;
        }
        setTrigger(trigger+1)
        triggerRef.current = setTimeout(() => { setTrigger(0) ;}, 1000)
    }


    const changeCover = (secret = false) => {
        setModal({ type:"select-image", selected: cover?.id, mode: secret ? "secret" : "teacher", save: async v => {
            await superSave({ image: v?.id })
            init()
        } })
    }

    const pickTheme = async (t) => {
        setLoading(true)
        await superSave({ theme: t?.id })
        await init()
        setShowing(false)
        setLoading(false)
        window.location.reload()
    }

    const superSave = async (payload) => {
        setLoading(true)
        const result =  await UpdateMe({ ...payload })
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            toast.success("Salvo")
        }
    }

    const init = async () => {
        await reloadMe()
    }

    const userTheme = useMemo(() => {
        return optionsTheme?.find(f => f.id === user?.theme)
    }, [user, optionsTheme])

    useEffect(() => { init() ;},[])

    return ( 
        <>
            <ContainerAuthenticated> 


            <Row>
                <Col sm={12} md={7}> 
                    <DashboardTitle big>Bem vindo { user?.name }</DashboardTitle>

                    

                    <StudentRow>
                        { user?.image?.url ? <StudentImage onClick={goodTry} src={parseStrapiImage(user?.image?.url)} /> : null } 
                        {/* <StudentStats>
                            Média
                            <StudentRate> 9.8 </StudentRate>
                        </StudentStats>
                        <StudentStats>
                            Performance
                            <StudentRate> A+ </StudentRate>
                        </StudentStats> */}
                        <StudentStats>
                            Recompensas disponíveis para os alunos
                            <StudentRate> { parseCurrencyMoney(user?.rewards||0) }  <CoinImage huge /> </StudentRate>
                        </StudentStats>
                    </StudentRow>
                    


                    <RowTheme><ThemeColor color={userTheme?.theme?.background} small /> { userTheme?.title || "Aleatório" }</RowTheme>
                    <DashboardTitle big>#{ user?.matter }</DashboardTitle>
                
                    <DashboardOptionsRow>
                        <CustomButton onClick={() => navigate("dashboard/me")}>Editar</CustomButton>
                        <CustomButton loading={loading} onClick={() => changeCover(false)}> Imagem de Perfil </CustomButton>
                        { showing ? null : <CustomButton onClick={() => setShowing(true)}> <PaintIcon /> </CustomButton> }
                    </DashboardOptionsRow>



                    {
                        !showing ? <>
                            <SlopeChart />
                        </> : <>

                            <WrapperThemes>
                                {
                                    optionsTheme?.map((m, k) => 
                                        <ThemeColor key={k} color={m?.theme?.background} onClick={() => pickTheme(m)} />
                                    )
                                }
                            </WrapperThemes>
                        </>
                    }

                </Col> 

                <Col md={5}>
                
                    
                                
                    {
                        !user || !user?.classes?.length ? <>
                            <CustomButton outline onClick={() => navigate("dashboard/contentor/classes")} >Adicione uma turma!</CustomButton> 
                        </> :  <>
                            <ContentRowInfos>
                                <ContentRowInfosItem>Turmas ({user?.classes?.length})</ContentRowInfosItem> 
                            </ContentRowInfos>
                            <ContentStudentClasses>
                                {
                                    user?.classes?.map((m, k)=> 
                                        <CardClass key={k} item={m} />
                                    )
                                } 
                            </ContentStudentClasses>
                        </>
                    }
                
                
                </Col>



            </Row>

               
               






            </ContainerAuthenticated> 
        </>
    );
}