import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'


export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold; 
    margin-bottom: 24px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
    ${
        props => props.big ? `
            font-size: 32px;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;


export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;


export const CheckedImage = styled.img.attrs({
    alt:"done",
    src:"/icons/check.svg",
    width: 24
})` 
`;

export const CardHome = styled.div.attrs({ 
})`
    min-height: 110px;
    padding: 24px 12px 12px;
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin: 0 0 24px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const CardHomeTitle = styled.div.attrs({ 
})`
    font-size: 22px;
`;

export const CardFormActions = styled.div.attrs({ 
})`
    display: flex;
    padding: 12px;
    justify-content: flex-end;
`;

export const CardFormButton = styled.div.attrs({ 
})`
`;



export const StudentImage = styled.img.attrs({ 
    width: "100%"
})`
    max-width: 180px;
    border-radius: 90px;
    object-fit: cover;
    margin-bottom: 18px;
`;

export const QrIcon = styled.img.attrs({ 
    width: 80,
    src: '/icons/qr-code.svg',
    alt: "Gerar QR Code"
})`
`;

export const ButtonQr = styled.div.attrs({ 
    children: <QrIcon />,
})`
    padding: 8px;
    border-radius: 8px;
    border: 4px solid ${ p => p.theme.palette.colors.lightshadow };

    cursor: pointer;
    transition: all .3s ease;

    &:hover{
        transform: scale(1.05);
    }
`;





export const CardTextRight = styled.div.attrs({ 
})`
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 12px;
`;

export const ContentQr = styled.div.attrs({ 
})`
    max-width: 240px;
    margin: 48px 0 48px auto;
    @media(max-width:767px){
        display: none;
    }
`;


export const ContentStudentClass = styled.div.attrs({ 
})`
    padding: 12px;
    border-radius: 12px;
    border: 1px solid ${ p => p.theme.palette.colors.lightshadow };
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: 100%;

    ${ p => p.card ? `
        padding: 0;
        flex-direction: column;
        max-width: 200px;
    ` : `
    ` }
    ${ p => !!p.onClick ? `
        cursor: pointer;
         transition: all .3s ease;
         &:hover{
            transform: scale(1.05);
         }
    ` : `` }
    ${ p => p.home ? `
        max-width: 27%;
        @media(max-width:991px){
            width: 100%;
            max-width: 90%;
        }
    ` : `
    ` }


`;

export const ContentStudentClassContent = styled.div.attrs({ 
})`
    
    ${ p => p.card ? `
        padding: 12px;
    ` : `` }
`;

export const ContentStudentClassImage = styled.div.attrs({ 
})`
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background: ${ p => p.theme.palette.colors.lightshadow } url(${p=>p.image}) no-repeat center center / cover;
    ${
        p => p.full ? `
            width: 100%;
            height: 180px;
            border-radius: 12px;
        ` : ``
    }
    ${
        p => p.home ? `
            aspect-ratio: 1 / 1 ;
        ` : ``
    }
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
`;

export const ContentStudentClassText = styled.div.attrs({ 
})`
    font-size: 16px;
    b{
        font-size: 20px;
    }
    ${
        p=>p.small ? `
            font-size: 12px;
        ` : ``
    }
    ${
        p=>p.label ? `
            background: ${ p.theme.palette.colors.white};
            padding: 4px 12px;
            border-bottom-left-radius: 12px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        ` : ``
    }
`;

export const ContentRowInfos = styled.div.attrs({ 
})`
    display: flex;
    padding: 18px 0;
`;

export const ContentRowInfosItem = styled.div.attrs({ 
})`
    flex:1;
    font-size: 24px;
    font-weight: 700;
`;



export const ContentStudentClasses = styled.div.attrs({ 
})`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    ${
        p => p.home ? `
            margin: 10vh 0;
            gap: 24px;
        ` : ``
    }
`;

export const StudentRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;
`;

export const StudentStats = styled.div.attrs({ 
})`
    font-size: 18px;
`;

export const StudentRate = styled.div.attrs({ 
})`
    font-size: 78px;
    font-weight: 700;
`;

export const DashboardOptionsRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    padding: 0 0 32px;
`;