import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 24px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const CardHome = styled.div.attrs({ 
})`
    min-height: 110px;
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin: 0 0 24px;

    box-shadow: 1px 3px 6px ${ props => props.theme.palette.colors.lightshadow };

    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;

    overflow: hidden;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale(1.02);
    }
`;

export const CardHomeTitle = styled.div.attrs({ 
})`
    font-size: 18px;
    font-weight: 600;
    padding: 12px 12px;

    background:  ${ props => props.theme.palette.colors.backgroundgrey };
    color:  ${ props => props.theme.palette.colors.black };
`;


export const CardHomeDecoration = styled.div.attrs({ 
})`
    height: 140px;
    width: 100%;
    background: url(${ p => p.image }) no-repeat bottom+25% center / cover;
    position:relative;
    z-index: 2;
    // filter: blur(1px);
    
    ${
        p => p?.reverse ? `
            border-bottom-right-radius: 100%;
            height: 20px;
        ` : `
           
        `
    }
`;






export const CardQuestion = styled.div.attrs({ 
})`
    display: flex;
    flex-direction: column;
    box-shadow: 1px 3px 6px ${ props => props.theme.palette.colors.lightshadow };
    border: .5px solid  ${ props => props.theme.palette.colors.backgrounddark };
    margin-bottom: 32px;


    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale( 1.00 );
        
    }

    & .remove-button{
        display:none;
    }

    &:hover .remove-button{
        display:flex;
    }

    &:hover .card-content-text{
        min-height: 35px;
        max-height: 35px;

        ${
            p => p.noseed ? `
                min-height: 62px;
                max-height: 62px;
            ` : ``
        }
    }
`;

export const CardQuestionDecoration = styled.div.attrs({ 
})`
    background:  ${ props => props.theme.palette.colors.backgrounddark };
    padding: 6px 12px;
    font-size: 16px;
    font-weight: 400;
    color: ${ props => props.theme.palette.colors.white };
    margin: -4px 0 0 auto;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
`;

export const ImageSpacer = styled.div.attrs({ 
})`
    height: 40px;
`;

export const CardQuestionTitle = styled.div.attrs({ 
    className:"card-content-text"
})`
    font-size: 14px;
    padding: 12px 12px;

    min-height: 79px;
    max-height: 79px;

    ${
        p => p.noseed ? `
            min-height: 106px;
            max-height: 106px;
        ` : ``
    }
    margin: 0px auto 12px;

    overflow: hidden;
    width: 100%;
    background: url(${ p => p.image }) no-repeat bottom+20% center / cover ;
`;

export const CardQuestionIcon = styled.img.attrs({ 
    width: 32
})`
    ${
        p => p.flat ? `
            cursor: pointer;
            transition: all .3s ease;
            &:hover{
                transform: scale(1.4);
            }
        ` : `
            margin: -24px auto 0 8px ; 
        `
    }
    ${
        p => p.active ? `
            border: 1px solid ${ p.theme.palette.colors.backgrounddark };
            transform: scale(1.4);
            border-radius: 16px;
        ` : `
        `
    }
`;

export const CardQuestionIconDecoration = styled.div.attrs({ 
})`
`;

export const CardQuestionButtonContainer = styled.div.attrs({ 
})`
    display: flex; 
    gap: 12px;
    padding: 0 12px 12px;
    justify-content: flex-end;
`;

export const CardQuestionButton = styled.div.attrs({ 
})` 
    width: 80px;
`;




export const ContentFilterContainer = styled.div.attrs({ 
})`
    display: flex;
    gap: 24px;
    padding-bottom: 36px;
    flex-direction: column;
    height: 100%;
`;

export const ContentFilter = styled.div.attrs({ 
})`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    ${
        p => p.seeded ? `

        ` : ``
    }
`;

export const ContentFilterTitle = styled.div.attrs({ 
})`
    font-size: 18px;
    color: ${ props => props.theme.palette.colors.black };
    min-width: 240px;
`;

export const ContentFilterContent = styled.div.attrs({ 
})`
    display: flex; 
    gap: 12px;
    padding: 12px;
    border: .5px solid ${ props => props.theme.palette.colors.backgrounddark };
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 88px;
    max-height: 58lvh;
    overflow-y: auto;
    border-radius: 16px 16px 0 16px;
    ${
        p => p?.seeded ? `
            padding: 32px 12px 12px;
        ` : ``
    }
`;


export const ContentFilterSeed = styled.div.attrs({ 
})`
    font-size: 16px;
    padding: 8px 12px;
    border: .5px solid ${ props => props.theme.palette.colors.backgrounddark };
    border-radius: 16px;

    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        transform: scale( 1.05 );
    }
    ${
        p => p.active ? `
            background: ${ p.theme.palette.colors.backgrounddark };
            color: ${ p.theme.palette.colors.white };
            font-weight: 600;
        ` : ``
    }
`;



export const FullLineFilter = styled.div.attrs({ 
})`
    width: 100%;
    display: flex;
    justify-content: space-between;
    algin-items: center;
    flex-wrap: wrap;
`;

export const FullLineFilterInput = styled.div.attrs({ 
})` 
    width: 100%; 
    margin-top: 10px;
    margin-bottom: -40px;
    margin-left: 1uto;
    z-index: 3;
    border-radius: 16px  16px  16px  0px;
    overflow: hidden; 
    display: flex;
    justify-content: flex-end;
`;

export const CardQuestionSeed = styled.div.attrs({ 
})` 
    padding: 2px 6px;
    border-radius: 16px;
    font-size: 14px;
    border: .5px solid ${p => p.theme.palette.colors.backgrounddark };
    color: ${p => p.theme.palette.colors.backgrounddark };
    margin: 6px auto -6px 8px ;
`;
