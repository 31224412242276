import { GET, POST, PUT, DELETE } from './api'

const table = "lessons"

export const Create = async (params) => {
    return await POST(`/${ table }`, params, true);
}

export const Read = async () => {
    return await GET(`/${ table }`, true);
}


export const ReadTeacherLessons = async (params) => {
    return await POST(`/pub-students/lessons`, params, true);
}

export const ReadOne = async (id) => { 
    return await GET(`/${ table }/${id}?populate[0]=image&populate[1]=questions.image&populate[2]=questions.options.image`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/${ table }/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/${ table }/${ id }`, true);
} 
 
export const ReadByUser = async (id) => {
    return await GET(`/${ table }?populate[0]=image.url&populate[1]=questions&filters[owner]=${ id }&pagination[pageSize]=10000`, true);
}
