import React from 'react'  
import moment from 'moment/moment';

import {  
    Content, 
    GameContainer
} from './styled'   

  
import useController from './controller';  

import GameContent from 'components/Game/Modes/Content';
import MultiChoice from 'components/Game/Modes/MultiChoice';
import RightWrong from 'components/Game/Modes/RightWrong';
import Memory from 'components/Game/Modes/Memory';
import Association from 'components/Game/Modes/Association';
import Feedback from 'components/Game/Feedback';
import Crosswords from 'components/Game/Modes/Crosswords';

export default function GameCore(props){   

    const {
        lesson,
        question,
        time,
        current,
        response,
        next,
        feedback,
         
    } = useController(props)

    return (
        <GameContainer>   
            <Content> 
                
                { question.type === 'content' ? <GameContent response={response} question={question} /> : null }
                { question.type === 'multichoice' ? <MultiChoice response={response} question={question} /> : null }
                { question.type === 'rightwrong' ? <RightWrong response={response} question={question} /> : null }
                { question.type === 'memory' ? <Memory response={response} question={question} atTime={ time <= question?.time } /> : null }
                { question.type === 'association' ? <Association response={response} question={question} />  : null }
                { question.type === 'crossword' ? <Crosswords response={response} question={question} /> : null }

            </Content> 
            { feedback ? <Feedback feedback={feedback} next={next}/> : null } 
        </GameContainer>
    )
}