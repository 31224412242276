import React from "react"; 

import { Row, Col, Container } from 'reactstrap'; 
 
import CardSelect from 'components/Landpage/Card/Select'

import { 
    BannerImage,
    BannerOut,
    BannerContent,
    BannerTitle,
    BannerText,
    BannerButton,
    RowSignNow,
    ButtonSignNow, 
} from "./styled"; 
import Button from "components/Form/Button";
import Input from "components/Form/Input";
import { goSection } from "utils";

export default function Banner(){
    return ( 
        <> 
            <BannerImage>
                <BannerOut />
                <BannerContent>
                    <Container>
                        <Row>
                            <Col md={{ size: 12 }}> 
                                {/* <BannerTitle>Transforme seu Ensino com Tecnologia Inovadora</BannerTitle>
                                <BannerText>Experiências Gamificadas</BannerText> */}
                                <BannerTitle>Estamos em BETA!</BannerTitle>
                                <BannerText>O open-beta da Royal Academy chegou! agora você já pode realizar a sua assinatura e fazer parte do futuro</BannerText>
                                <BannerButton>
                                    <Button outline white onClick={() => goSection("sign-now")}> Assine Já </Button>
                                </BannerButton>
                            </Col>
                        </Row>
                    </Container>
                </BannerContent>
            </BannerImage>
        </>
    );
}