import React, { useEffect, useState } from 'react'


import { 
    Container,
    Title,
    Text,
    RankingItem,
    RankingItemInfo,
    CircleImage,
    RowVCenter,
    FeedbackIcon,
    ContentInfo,
    Rate,
    RowInfo,
    RowInfoText,
    TextBottom
} from './styled'


import { Icon, SimpleRow, Row } from 'ui/styled'

import Button from 'components/Form/CustomButton'

import {
    coinize,
    typenize,
    numerize
} from 'utils' 

import { useTheme } from 'styled-components';
import CustomButton from 'components/Form/CustomButton'
import { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'
import { Create } from 'services/responses'

export default function BodyFeedback(props){ 


    const { setSuperModal } = useContext(CoreContext)

    const theme = useTheme()

    const questions = props?.answers?.map(mit => ({
        ...mit,
        name: typenize(mit.question?.type),
        type: mit.question?.type,
        correct: mit?.answer?.correct,
        an_image: mit?.answer?.image,
        an_answer: mit?.answer?.answer,
        game_image: mit.question?.image,
        game_tag: mit.question?.question,
        correct_tag: mit.question?.options?.filter(fit => fit.correct )[0],
    })) 


    const saveGame = async () => {
        const payload = {
            lesson: props?.game?.id,
            game: props?.game,
            answers: props?.answers,
            time: props?.gametime,
            student: props?.student?.student?.id,
            owner: props?.student?.teacher?.id,
            media: parseFloat(parseFloat((10 * props?.answers?.filter(fit => fit?.answer?.correct).length)/props?.answers?.length).toFixed(1)) || 0
        }
        await Create({ data:payload })
    }

    useEffect(() => {
        if(props?.gameLesson){
            if(props?.student?.teacher?.id && !props?.response?.id && !props?.forfun){
                saveGame()
            }
        }
    }, [props]) 

    const action = mit => {
        // navigate('FeedbackDetails', { feedback:mit })
        setSuperModal({ type:"response-details", feedback:mit })
    }

    const lessionRate = () => {
         return `${ parseFloat((10 * questions.filter(fit => fit.correct).length)/questions.length).toFixed(1) }`.replace('.',',')
    }
    return (
        <> 
            <Container>      
                {
                    questions.map( (mit, mik) => 
                        <RankingItem key={mik} onClick={() => action(mit)}>
                            <RowVCenter>
                                <Title big>{mik+1}</Title>  
                                <RankingItemInfo>
                                    <SimpleRow>
                                        <Title>
                                            { mit.name }
                                        </Title>
                                        <FeedbackIcon>
                                            {
                                                mit.correct ?
                                                        <Icon icon={ "check" } width={20} height={20} fill={ theme.Green } /> 
                                                        :
                                                        <Icon icon={ "error" } width={14} height={14} fill={ theme.Red } /> 
                                            }
                                        </FeedbackIcon>
                                    </SimpleRow>
                                    { mit.game_tag ? <Text> { mit.game_tag } </Text> : null }
                                    { mit.game_image ? <CircleImage source={mit.game_image} /> : null }
                                </RankingItemInfo>  
                                <Icon icon={"chevron"} width={6} height={9} fill={theme.Black} /> 
                            </RowVCenter>
                        </RankingItem>
                    )
                }
                <ContentInfo>
                    <RowInfo>
                        <RowInfoText bold>Acertos</RowInfoText>
                        <RowInfoText>{ numerize( questions.filter(fit => fit.correct).length )}</RowInfoText>
                    </RowInfo>
                    <RowInfo>
                        <RowInfoText bold>Erros</RowInfoText>
                        <RowInfoText>{ numerize( questions.filter(fit => !fit.correct).length )}</RowInfoText>
                    </RowInfo>
                    <RowInfo>
                        <RowInfoText bold>Tempo de avaliação</RowInfoText>
                        <RowInfoText>{numerize(props.time||"0")}s</RowInfoText>
                    </RowInfo>
                    <RowInfo>
                        <RowInfoText bold>Coins recebidos</RowInfoText>
                        <SimpleRow>
                            <Icon icon={ "coin" } width={12} height={12} /> 
                            <RowInfoText bold>{ coinize(props.game?.coins) }</RowInfoText>
                        </SimpleRow>
                    </RowInfo>
                    <Rate>{ lessionRate() }</Rate>
                    <Text>Nota</Text>
                </ContentInfo>

            </Container>
        </>
    )
}