import React, { useMemo } from "react";  

import {  
    CardQuestion,
    CardQuestionAvatar,
    CardQuestionContent,
    CardQuestionImage,
    CardQuestionRow,
    CardQuestionTitle,
    CardStudentItem,
    RowStudents
} from "./styled";


import { Row, Col } from "reactstrap";

import { useHistory } from "react-router-dom";

import { parseStrapiImage, performanize } from "utils"; 

export default function CardClasses({ item }){  

    const history = useHistory();
    const navigate = to => history.push(`/${ to }`); 


    const classMedia = useMemo(() => {
        return parseFloat(parseFloat((item?.students?.reduce( (p,c) => p+(c?.media||0), 0) / item?.students?.length)||0)?.toFixed(1))
    }, [item])


    return ( 
        <>
            <Col sm={12} md={6} lg={3}> 
                <CardQuestion onClick={() => navigate(`dashboard/creator/classes/${item?.id}`)}>
                    <CardQuestionRow>
                        <CardQuestionAvatar>
                            <CardQuestionTitle> { performanize(classMedia) } </CardQuestionTitle>
                            <CardQuestionTitle negative small>Media: <b>{ classMedia }</b></CardQuestionTitle>
                        </CardQuestionAvatar>
                        <CardQuestionImage image={item?.image?.url ? parseStrapiImage(item?.image?.url) : null}  />
                        <CardQuestionContent flexgrow>
                            <CardQuestionTitle> { item?.title } </CardQuestionTitle>
                            <CardQuestionTitle small>{ item?.lessons?.length } Exercícios</CardQuestionTitle>
                            <CardQuestionTitle small>{ item?.students?.length } Alunos</CardQuestionTitle>
                            <RowStudents>
                                {
                                    item?.students?.slice(0,3)?.map((m, key) => 
                                        <CardStudentItem key={key} image={m?.avatar?.url ? parseStrapiImage(m?.avatar?.url) : "/images/student.png"} />
                                    )
                                }
                                { item?.students?.length > 3 ? <CardQuestionTitle> +{item?.students?.length - 3} </CardQuestionTitle> : null }
                            </RowStudents>
                        </CardQuestionContent> 
                    </CardQuestionRow>
                </CardQuestion>
            </Col>
        </>
    );
}